import React from 'react'
import styles from './Elements.module.scss';
import bridgesImg from '../../../../assets/bridges-ex.png';
function ShapesFonts(props) {
    return (
        <div className={styles.wrapper}>
            <h2>Shapes and fonts must all be connected</h2>
            <p>
                In the example below, anything in red will be lost because they are not connected to the outer shape. 
                Make sure that there are enough “bridges” to connect all inner shapes to the outer shape:
            </p>
            <img src={bridgesImg} alt="shapes as fonts example"/>
        </div>
    )
}
 
export default ShapesFonts;
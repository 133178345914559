import React, { useState } from 'react'
import { Table, Input, Button, Label } from 'semantic-ui-react';
import axios from 'axios';
import QtySaveRateTbl from './QtySaveRateTbl';
import { store } from 'react-notifications-component';
import { isFormattedDecimal } from '../../../../../service/helpers';
import { FAIL_CONFIG } from '../../../../../service/notifications';
import { BACKEND } from '../../../../../service/clientConstants';
import s from './QuantityChoiceRow.module.scss';
import { setMetalsState } from '../../../../../redux/app-state/appActions';
import { connect } from 'react-redux';
import uuid from 'react-uuid'

function QuantityChoiceRow({ metal, updateParent }) {
    const INITIAL_STATE = {quantity: "", rate: ""};
    const [newChoice, setNewChoice] = useState(INITIAL_STATE);
    const [qtyChoices, setQtyChoices] = useState(metal.quantityChoices ? metal.quantityChoices : {});
    const [qtyInputErr, setQtyInputErr] = useState("");
    const [rateInputErr, setRateInputErr]   = useState("");
    const re  = /^[0-9]+$/;

    function handleClick() {
        let { quantity, rate } = newChoice;
        if (quantity && rate) {
            axios
            .patch((BACKEND + '/admin/metals'), {
                metalName: metal.name,
                entry: { [quantity]: rate }
            })
            .then(res => {
                if (res.data.success) {
                    setNewChoice(INITIAL_STATE);
                    updateParent(res.data.metal);
                    setQtyChoices(res.data.metal.quantityChoices);
                } else {
                    FAIL_CONFIG.message = res.data.msg;
                    store.addNotification(FAIL_CONFIG);
                }
            })
            .catch(e => console.log(e));
        }
    }

    function handleChange(e, { name, value }) {
        if (name === "quantity") {
            if (re.test(value)) {
                setNewChoice({quantity: value, rate: newChoice.rate});
                setQtyInputErr("");
            } else if (value === "") {
                setQtyInputErr("");
                setNewChoice({quantity: "", rate: newChoice.rate});
            } else {
                setNewChoice({quantity: value, rate: newChoice.rate});
                setQtyInputErr("must be a whole number");
            }
        } else if (name === "rate") {
            if (isFormattedDecimal(value)) {
                setNewChoice({quantity: newChoice.quantity, rate: value});
                if (value === "0.00") {
                    setRateInputErr("cannot be zero");
                } else {
                    setRateInputErr("");
                }
            } else if (value === "") {
                setNewChoice({quantity: newChoice.quantity, rate: value});
                setRateInputErr("");
            } else {
                setNewChoice({quantity: newChoice.quantity, rate: value});
                setRateInputErr("must be '0.xx' format");
            }
            
        }
    }

    return (
        <Table.Row>
            <Table.Cell style={{fontWeight: 'bold', fontSize: '22px'}}>{metal.name}</Table.Cell>
            <Table.Cell>
                {
                    metal && qtyChoices ? Object.keys(qtyChoices).map((entry, idx) => (
                        <QtySaveRateTbl 
                            setQtyChoices={setQtyChoices}
                            updateGrandparent={updateParent}
                            entry={{ qty: entry, rate: qtyChoices[entry]}} 
                            metalName={metal.name} 
                            key={uuid()}
                        />
                    )) : (null)
                }

                <table>
                    <tbody>
                        <Table.Row>
                            <Table.Cell>Add Choice</Table.Cell>
                            <Table.Cell className={s.cell}>
                                <Input 
                                    placeholder="enter quantity"
                                    label="Quantity"
                                    labelPosition="left"
                                    error={qtyInputErr.length > 0}
                                    onChange={handleChange}
                                    value={newChoice.quantity}
                                    name="quantity"
                                />
                                {
                                    qtyInputErr ? <Label pointing="above" color="red">{qtyInputErr}</Label> : null
                                }
                            </Table.Cell>
                            <Table.Cell className={s.cell}>
                                <Input 
                                    placeholder="enter save rate"
                                    label="Save Rate"
                                    labelPosition="left"
                                    onChange={handleChange}
                                    value={newChoice.rate}
                                    error={rateInputErr.length > 0}
                                    name="rate"
                                />
                                {
                                    rateInputErr ? <Label pointing="above" color="red">{rateInputErr}</Label> : null
                                }
                            </Table.Cell>
                                
                            <Table.Cell>
                                <Button 
                                    style={{marginLeft: '1rem'}}
                                    onClick={handleClick}
                                    color="yellow"
                                    disabled={rateInputErr || qtyInputErr || (!newChoice.quantity || !newChoice.rate) ? true : false}
                                >Publish</Button>
                            </Table.Cell> 
                        </Table.Row>
                    </tbody>
                </table>
            </Table.Cell>
        </Table.Row>
    )
}


function mapDispatchToProps(dispatch) {
    return {
        setMetalsState: (metals) => dispatch(setMetalsState(metals))
    }
}
 
export default connect(null, mapDispatchToProps)(QuantityChoiceRow);
import { combineReducers } from 'redux';
import resultsModalReducer from './results-modal/resultsModalReducer';
import quoteWorkflowReducer from './quote-workflow/quoteWorkflowReducer';
import customQuoteReducer from './custom-quote-modal/customQuoteReducer';
import appReducer from './app-state/appReducer';
import orderReducer from './order-state/orderReducer';
import adminReducer from './admin-state/adminReducer';
/*
    Redux stores all the state of the app in the rootReducer object.
    Each normal reducer (not root reducer) receives an object from an action,
    and each action returns an object. using the 'reselect' npm package, we 
    can access the rootReducer object and retrieve properties from anywhere in
    the application. However this requires each react component to be wrapped in 
    the connect function (higher order component). See ValidationModal.jsx comments
    for how to access app state in each component.

    Before continuing, read this: https://redux.js.org/introduction/core-concepts,
    and read the Three Principles section.

    The main thing to understand is that redux never changes the state. it simply creates a new
    state object every time you change the state through an action with the updated state. This
    is 'pure' functionality. That is why there are so many functions returning functions etc.
*/

export const rootReducer = combineReducers({
    // this is the object that will hold all of our state
    // rootReducer will be an object with the keys below
    quoteWorkflowState: quoteWorkflowReducer,
    orderState: orderReducer,
    resultsModalState: resultsModalReducer,
    appState: appReducer,
    customQuoteModalState: customQuoteReducer,
    adminState: adminReducer,
});
import React, { useState } from 'react'
import { Table, Button, Input, Label } from 'semantic-ui-react';
import { isFormattedDecimal } from '../../../../../service/helpers';
import { BACKEND } from '../../../../../service/clientConstants';
import axios from 'axios';
import { FAIL_CONFIG, SUCCESS_CONFIG } from '../../../../../service/notifications';
import { store } from 'react-notifications-component';
import s from './QtySaveRateTbl.module.scss';
import { setMetalsState } from '../../../../../redux/app-state/appActions';
import { connect } from 'react-redux';


// this component is functionally a table, but displayed as a row
function QtySaveRateTbl({ entry, metalName, setQtyChoices, updateGrandparent }) {
    
    const [update, setUpdate]               = useState("");
    const [rateInputErr, setRateInputErr]   = useState("");

    function handleClick() {
        if (update) {
            axios
            .patch((BACKEND + '/admin/metals'), {
                metalName: metalName,
                rateUpdate: { [entry.qty]: update }
            })
            .then(res => {
                if (res.data.success) {
                    updateGrandparent(res.data.metal);
                    setQtyChoices(res.data.metal.quantityChoices);
                    SUCCESS_CONFIG.message = res.data.msg;
                    store.addNotification(SUCCESS_CONFIG);
                } else {
                    FAIL_CONFIG.message = res.data.msg + '\nContact Administrator'
                    store.addNotification(FAIL_CONFIG);
                }
            })
            .catch(e => console.log(e));
        }
    }

    function handleChange(e, { value }) {
        if (isFormattedDecimal(value)) {
            setUpdate(value);
            if (value === "0.00") {
                setRateInputErr("cannot be zero")
            } else {
                setRateInputErr("");
            }
        } else if (value === "") {
            setUpdate(value);
            setRateInputErr("");
        } else {
            setUpdate(value);
            setRateInputErr("must be '0.xx' format");
        }
    }

    function handleRemoveRow() {
        axios
        .patch((BACKEND + '/admin/metals'), {
            metalName: metalName,
            removeKey: entry.qty
        })
        .then(res => {
            if (res.data.success) {
                updateGrandparent(res.data.metal);
                setQtyChoices(res.data.metal.quantityChoices);
            } else {
                FAIL_CONFIG.message = res.data.msg;
                store.addNotification(FAIL_CONFIG);
            }
        })
        .catch(e => console.log(e));
    }
    
    return (
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell width={1}>Qty Choice</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Save Rate</Table.HeaderCell>
                    <Table.HeaderCell width={5}>Update Save Rate</Table.HeaderCell>
                    <Table.HeaderCell width={1}>Remove Row</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                <Table.Row>
                    <Table.Cell>{entry.qty}</Table.Cell>
                    <Table.Cell>{entry.rate}</Table.Cell>
                    <Table.Cell>
                        <Input 
                            size="mini" 
                            style={{height: '36.2px', marginRight: '1rem'}}
                            error={rateInputErr.length > 0}
                            value={update}
                            onChange={handleChange}
                        />
                        {
                            rateInputErr ? <Label pointing="left" color="red">{rateInputErr}</Label> : null
                        }
                        <Button 
                            color="red"
                            disabled={rateInputErr.length > 0 || !update}
                            onClick={handleClick}
                        >Update</Button>
                    </Table.Cell>
                    <Table.Cell>
                        <span className={s.xBtn} onClick={handleRemoveRow}>&#10005;</span>
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        setMetalsState: (metals) => dispatch(setMetalsState(metals))
    }
}
 
export default connect(null, mapDispatchToProps)(QtySaveRateTbl);
import React, { useState } from 'react'
import { Button, Input, LabelGroup, Label, Checkbox } from 'semantic-ui-react'
import DollarPaddingTable from './sub-components/DollarPaddingTable';
import axios from 'axios'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectPricingVars } from '../../../../redux/admin-state/adminSelectors';
import { setPricingVars } from '../../../../redux/admin-state/adminActions';
import { store } from 'react-notifications-component';
import { BACKEND } from '../../../../service/clientConstants'
import { FAIL_CONFIG, SUCCESS_CONFIG } from '../../../../service/notifications';
import { setVat } from '../../../../redux/quote-workflow/quoteWorkflowActions';
import s from './ManagePricing.module.scss';
import { setShowDollarsOnly } from '../../../../redux/app-state/appActions';


function ManagePricing({ pricingVars, setPricingVars, setVat, setShowDollarsOnly }) {

    const INITIAL_STATE = {
        laserMinutePrice: "",
        vat: "",
        marketRate: "",
        lollarsMultiplier: "",
        showDollarsOnly: true
    }
    const vatRe                                     = /^(0)+(\.\d{1,2})?$/;
    const otherRe                                   = /^\d+(\.\d{1,9})?$/;
    const [pricingData, setPricingData]             = useState(INITIAL_STATE);
    const [inputEmpty, setInputEmpty]               = useState(true);
    const [laserPriceErr, setLaserPriceErr]         = useState(false);
    const [vatErr, setVatErr]                       = useState(false);
    const [marketRateErr, setMarketRateErr]         = useState(false);
    const [lollErr, setLollErr]                     = useState(false);


    function handleEnterTable(e) {
        let { name, value } = e.target;
        if (name === "vat") {
            if (!vatRe.test(value) && value) {
                setVatErr(true);
            } else {
                setVatErr(false);
            }
        } else if (name === "laserMinutePrice") {
            if (!otherRe.test(value) && value) {
                setLaserPriceErr(true);
            } else {
                setLaserPriceErr(false);
            }
        } else if (name === "marketRate") {
            if (!otherRe.test(value) && value) {
                setMarketRateErr(true);
            } else {
                setMarketRateErr(false);
            }
        } else if (name === "lollarsMultiplier") {
            if (!otherRe.test(value) && value) {
                setLollErr(true);
            } else {
                setLollErr(false);
            }
        }
        setPricingData(prevData => {
            let obj = { ...prevData };
            obj[name] = value;
            setInputEmpty(!Object.values(obj).some(x => (x !== null && x !== ''))); // checks if object is empty
            return obj;
        });
    }

    function handleDollarToggle(e, data) {
        const { name, checked } = data;
        // make api hit
        axios
        .post((BACKEND + '/admin/pricing/togglecurrency'), { checked })
        .then(res => {
            if (res.data.success) {
                SUCCESS_CONFIG.message = res.data.msg;
                store.addNotification(SUCCESS_CONFIG);
                setPricingVars(res.data.currentVars);
                setShowDollarsOnly(res.data.currentVars.showDollarsOnly)
            } else {
                FAIL_CONFIG.message = res.data.msg;
                store.addNotification(FAIL_CONFIG);
            }
        }).catch(e => console.log(e));
    }

    function handleClick(e) {
        axios
        .patch((BACKEND + '/admin/pricing'), pricingData)
        .then(res => {
            if (res.data.success) {
                SUCCESS_CONFIG.message = res.data.msg;
                store.addNotification(SUCCESS_CONFIG);
                setPricingVars(res.data.currentVars);
                setVat(res.data.currentVars.vat);
            } else {
                FAIL_CONFIG.message = res.data.msg + '\nIf the problem continues, please contact the administrator';
                store.addNotification(FAIL_CONFIG);
            }
        }).catch(e => {
            console.log(e);
        });
    }

    return (
        <div className={s.wrapper}>
            <h3>Edit Pricing Variables</h3>
            <div className={s.inner}>
                
                <LabelGroup>
                    <Input 
                        label="Laser minute price" 
                        type="text" 
                        name="laserMinutePrice"
                        placeholder="e.g., 3"
                        onChange={handleEnterTable}
                        value={pricingData.laserMinutePrice}
                    />
                    <Label color="blue" size="large">Current Laser Price/min: {pricingVars.laserMinutePrice}</Label>
                </LabelGroup>

                <LabelGroup>
                    <Input 
                        label="VAT percentage    " 
                        type="text" 
                        name="vat"
                        placeholder="e.g., 0.12"
                        onChange={handleEnterTable}
                        value={pricingData.vat}
                    />
                    <Label color="blue" size="large">Current VAT: {pricingVars.vat}</Label>
                </LabelGroup>

                <LabelGroup>
                    <Input 
                        label="LBP Market Rate   " 
                        type="text" 
                        name="marketRate"
                        placeholder="e.g., 8400"
                        onChange={handleEnterTable}
                        value={pricingData.marketRate}
                    />
                    <Label color="blue" size="large">Current Market Rate: {pricingVars.marketRate}</Label>
                </LabelGroup>

                <LabelGroup>
                    <Input 
                        label="Lollars multiplier" 
                        type="text" 
                        name="lollarsMultiplier"
                        placeholder="e.g., 2"
                        onChange={handleEnterTable}
                        value={pricingData.lollarsMultiplier}
                    />
                    <Label color="blue" size="large">Current Lollars Multiplier: {pricingVars.lollarsMultiplier}</Label>
                </LabelGroup>

                <div>
                    <Checkbox
                        onChange={handleDollarToggle}
                        checked={pricingVars.showDollarsOnly}
                        name="showDollarsOnly"
                        label="Show Only USD"
                        toggle
                    />
                </div>
                <div>
                    <Button onClick={handleClick} disabled={inputEmpty || vatErr || lollErr || marketRateErr || laserPriceErr}>Submit</Button>
                </div>
            </div>
            <DollarPaddingTable/>
        </div>
    )
}


function mapDispatchToProps(dispatch) {
    return {
        setPricingVars: (obj) => dispatch(setPricingVars(obj)),
        setVat: (vat) => dispatch(setVat(vat)),
        setShowDollarsOnly: (bool) => dispatch(setShowDollarsOnly(bool))
    }
}
const mapStateToProps = createStructuredSelector({
    pricingVars: selectPricingVars
})
 
export default connect(mapStateToProps, mapDispatchToProps)(ManagePricing);
import React from 'react'
import FileFormat from './guideline_elements/FileFormat';
import FileRequirements from './guideline_elements/FileRequirements'
import CutPaths from './guideline_elements/CutPaths';
import Multiples from './guideline_elements/Multiples';
import ShapesOutlines from './guideline_elements/ShapesOutlines';
import ShapesFonts from './guideline_elements/ShapesFonts';
import Overlap from './guideline_elements/Overlap';
import SignDesign from './guideline_elements/SignDesign';
import BasicMotionDiv from '../../reusable/BasicMotionDiv'
import s from './OldGuidelines.module.scss'; 

function OldGuidelines(props) {
    return (
        <BasicMotionDiv className={s.wrapper}>
            <div className={s.innerWrapper}>
                <h1 className={s.mainTitle}>Design Guidelines</h1>
                <p>To laser cut your job fast, kindly verify your files meet our guideline requirements.</p>
                <hr/>
                <FileFormat/>
                <hr/>
                <FileRequirements/>
                <hr/>
                <CutPaths/>
                <hr/>
                <Multiples/>
                <hr/>
                <ShapesOutlines/>
                <hr/>
                <ShapesFonts/>
                <hr/>
                <Overlap/>
                <hr/>
                <SignDesign/>
            </div>
        </BasicMotionDiv>
    )
}
 
export default OldGuidelines;
import { createSelector } from 'reselect';
const appState = rootState => rootState.appState;
// const INITIAL_STATE = {
//     adminLoggedIn: false,
//     userLoggedIn: false,
//     isOnline: true,
//     metals: [],
//     updatedMetals: [], //  used for updating metal cards and pages
//     staticSiteText: {},
//     updatedSiteText: {},
//     stateLoaded: false,
//     smIcons: {
//         facebook: fbIcon, 
//         instagram: igIcon, 
//         twitter: twIcon, 
//         youtube: ytIcon, 
//         pinterest: pinIcon, 
//         linkedin: liIcon, 
//         tiktok: ttIcon
//     },
//     fromButtonClick: false,
//     showDollarsOnly: false
// }

// _____________________fromButtonClick_____________________//
export const selectFromButtonClick = createSelector(
    [appState],
    appState => appState.fromButtonClick
);




// _____________________updatedSiteText_____________________//
export const selectUpdatedSiteText = createSelector(
    [appState],
    appState => appState.updatedSiteText
);



// _____________________updatedMetals_____________________//
export const selectUpdatedMetals = createSelector(
    [appState],
    appState => appState.updatedMetals
);



// _____________________stateLoaded_____________________//
export const selectStateLoaded = createSelector(
    [appState],
    appState => appState.stateLoaded
);



// _____________________footerShow_____________________//
export const selectFooterShow = createSelector(
    [appState],
    appState => appState.selectFooterShow
);




// _____________________footerLinks_____________________//
export const selectFooterLinks = createSelector(
    [appState],
    appState => appState.footerLinks
);



// _____________________smIcons_____________________//
export const selectSmIcons = createSelector(
    [appState],
    appState => appState.smIcons
);


// _____________________isOnline_____________________//
export const selectIsOnline = createSelector(
    [appState],
    appState => appState.isOnline
);


// _____________________adminLoggedIn_____________________//
export const selectAdminLoggedIn = createSelector(
    [appState],
    appState => appState.adminLoggedIn
);


// _____________________userLoggedIn_____________________//
export const selectUserLoggedIn = createSelector(
    [appState],
    appState => appState.userLoggedIn
);


// _____________________metals_____________________//
export const selectMetalsState = createSelector(
    [appState],
    appState => appState.metals
);


// _____________________staticSiteText_____________________//
export const selectStaticSiteText = createSelector(
    [appState],
    appState => appState.staticSiteText
);



// _____________________staticSiteText_____________________//
export const selectShowDollarsOnly = createSelector(
    [appState],
    appState => appState.showDollarsOnly
);
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useReducer, useRef } from 'react'
import { Table, Button, Input } from 'semantic-ui-react';
import axios from 'axios';
import { store } from 'react-notifications-component';
import { FAIL_CONFIG, SUCCESS_CONFIG } from '../../../../../service/notifications';
import { BACKEND } from '../../../../../service/clientConstants'
import { connect } from 'react-redux'
import UploadFileComponent from '../../../../reusable/UploadFileComponent';
import MetalTableRow from './MetalTableRow';
import s from './MetalTable.module.scss';
import { setMetalsState } from '../../../../../redux/app-state/appActions';
import { createStructuredSelector } from 'reselect';
import { selectMetalsState } from '../../../../../redux/app-state/appSelectors';

const ACTIONS = {
    SET_PATCHED: "SET_PATCHED",
    SET_LOCAL_METALS: "SET_LOCAL_METALS",
    SET_ADDED_THICKNESS: "SET_ADDED_THICKNESS"
}
const INITIAL_STATE = {
    patched: false,
    addedThickness: false,
    localMetals: [], 
}
function reducer(state, action) {
    switch (action.type) {
        case ACTIONS.SET_PATCHED:
            return {
                ...state,
                patched: action.payload
            }
        case ACTIONS.SET_LOCAL_METALS:
            return {
                ...state,
                localMetals: action.payload
            }
        case ACTIONS.SET_ADDED_THICKNESS:
            return {
                ...state,
                addedThickness: action.payload
            }
      default:
        return { ...state }
    }
}

// metal is a string, table is an array of [ [length, speed], ... ]
function MetalTable({ metal, setMetalsState, metals }) {
    const [newThickness, setNewThickness]               = useState("");
    const [thicknessInputValid, setThicknessInputValid] = useState(true);

    const [newSpeed, setNewSpeed]                       = useState("");
    const [speedInputValid, setSpeedInputValid]         = useState(true);

    const [newMaxLength, setNewMaxLength]               = useState("");
    const [lengthInputValid, setLengthInputValid]       = useState(true);

    const [newMaxWidth, setNewMaxWidth]                 = useState("");
    const [widthInputValid, setWidthInputValid]         = useState(true);

    const [newDensity, setNewDensity]                   = useState("");
    const [densityInputValid, setDensityInputValid]     = useState(true);

    const [newPrice, setNewPrice]                       = useState("");
    const [priceInputValid, setPriceInputValid]         = useState(true);
    const [patchInputValid, setPatchInputValid]         = useState(true);
    const [localMetal, setLocalMetal]                   = useState(metal);

    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const stateRef = useRef();
    stateRef.current = state;

    const re                                            = /^[0-9]+([.][0-9]+)?$/g;
    const decimalRe = /^\d+(\.\d{1,9})?$/;

    const numRegex                                      = /^\d+$/;
    const [inputReady, setInputReady]                   = useState(false);



    // for some reason, set local state based off of prop is causing issues.
    // deletedMetal didn't cause an update of the whole component, but addedMetal did
    // btw addedMetal should be changed to addedThickness
    // update rdx state when unmounting


    useEffect(() => {
        if (metals) {
            dispatch({ type: ACTIONS.SET_LOCAL_METALS, payload: metals});
        }
        return () => {
            let { patched, addedThickness, localMetals } = stateRef.current;
            if (patched || addedThickness) {
                setMetalsState(localMetals);
            }
        }
    }, [])



    useEffect(() => {
        if (newThickness !== "" && newSpeed !== "" && newMaxLength !== "" && newMaxWidth !== "") {
            if (speedInputValid && lengthInputValid && widthInputValid && thicknessInputValid) {
                setInputReady(true);
            } else {
                setInputReady(false);
            }
        } else {
            setInputReady(false);
        }
    }, [newSpeed, newThickness, newMaxWidth, newMaxLength]);

    useEffect(() => {
        if (densityInputValid && priceInputValid && (newDensity || newPrice)) {
            setPatchInputValid(true);
        } else {
            setPatchInputValid(false);
        }
    }, [densityInputValid, priceInputValid, newDensity, newPrice]);

    function updater(newMetal) {
        setLocalMetal(newMetal);
        dispatch({ type: ACTIONS.SET_PATCHED, payload: true});
        dispatch({
            type: ACTIONS.SET_LOCAL_METALS,
            payload: stateRef.current.localMetals.map(m => m.name === newMetal.name ? newMetal : m)
        });
    }

    function sortTable(table) {
        return table.sort(function(a, b) {                    
            return parseFloat(a[0]) > parseFloat(b[0]) ? 1 : -1;
        });
    }

    function handleChange(e) {
        const { name, value } = e.target;
        if (name === "newThickness") {
            setNewThickness(value);
            if (!decimalRe.test(value) && value) {
                setThicknessInputValid(false);
            } else {
                setThicknessInputValid(true);
            }
        } else if (name === "newSpeed") {
            setNewSpeed(value);
            if (!value) return setSpeedInputValid(true);
            if (!re.test(value)) {
                return setSpeedInputValid(false);
            } else {
                setSpeedInputValid(true); 
            }
        } else if (name === "newMaxLength") {
            setNewMaxLength(value);
            if (!value) return setLengthInputValid(true);
            if (!numRegex.test(value)) {
                return setLengthInputValid(false);
            } else {
                setLengthInputValid(true);
            }
        } else if (name === "newMaxWidth") {
            setNewMaxWidth(value);
            if (!value) return setWidthInputValid(true);
            if (!numRegex.test(value)) {
                return setWidthInputValid(false);
            } else {
                setWidthInputValid(true);
            }
        } else if (name === "density") {
            setNewDensity(value);
            if (!value) return setDensityInputValid(true);
            if (!re.test(value)) {
                return setDensityInputValid(false);
            } else {
                setDensityInputValid(true); 
            }
        } else if (name === "price") {
            setNewPrice(value);
            if (!value) return setPriceInputValid(true);
            if (!re.test(value)) {
                return setPriceInputValid(false);
            } else {
                setPriceInputValid(true); 
            }
        }
    }

    function handleAddThickness() {
        //check if both strings are filled out, AND if there's only 1 char in each
        if (inputReady) {
            axios
            .post((BACKEND + '/admin/tables'), {
                metalName: localMetal.name,
                thickness: newThickness + "mm",
                speed: newSpeed,
                maxLength: newMaxLength,
                maxWidth: newMaxWidth
                //evapSpeed: evapSpeed,
            })
            .then(res => {
                if (res.data.success) {
                    setNewSpeed("");
                    setNewThickness("");
                    setNewMaxLength("");
                    setNewMaxWidth("");
                    let { foundMetal } = res.data;
                    setLocalMetal(foundMetal);
                    dispatch({
                        type: ACTIONS.SET_LOCAL_METALS,
                        payload: stateRef.current.localMetals.map(m => m.name === foundMetal.name ? foundMetal : m)
                    });
                    dispatch({ type: ACTIONS.SET_ADDED_THICKNESS, payload: true });
                } else {
                    FAIL_CONFIG.message = res.data.msg;
                    store.addNotification(FAIL_CONFIG);
                }
            })
            .catch(e => console.log(e)); 
        } else {
            FAIL_CONFIG.message = "Please make sure table input is valid."
            store.addNotification(FAIL_CONFIG);
        }   
    }

    function handleDeleteTable() {
        let confirmed = window.confirm('Are you sure you want to delete this whole table? This action cannot be undone.');
        if (confirmed) {
            axios
            .delete((BACKEND + '/admin/metals'), { 
                data: {
                    metalName: localMetal.name
                }  
            })
            .then(res => {
                if (res.data.success) {
                    setMetalsState(res.data.metals);
                } else {
                    FAIL_CONFIG.message = res.data.msg + '\nPlease contact the administrator';
                    store.addNotification(FAIL_CONFIG);
                }
            })
            .catch(e => console.log(e));
        }
    }

    function handlePatchMetal() {
        axios
        .patch((BACKEND + '/admin/metals'), { 
            metalName: metal.name,
            density: parseFloat(newDensity),
            pricePerKg: parseFloat(newPrice)
        })
        .then(res => {
            if (res.data.success) {
                SUCCESS_CONFIG.message = res.data.msg;
                store.addNotification(SUCCESS_CONFIG);
                dispatch({ type: ACTIONS.SET_PATCHED, payload: true});
                dispatch({ 
                    type: ACTIONS.SET_LOCAL_METALS, 
                    payload: res.data.metals
                });
                setLocalMetal(res.data.metal);
                setNewPrice("");
                setNewDensity("");
            } else {
                FAIL_CONFIG.message = res.data.msg + '\nPlease contact the administrator';
                store.addNotification(FAIL_CONFIG);
            }
        })
        .catch(e => console.log(e));
    }

    return (
        <div className={s.wrapper}>
            <div className={s.inner}>
                <div className={s.top}>
                    <div className={s.titleArea}>
                        <h3>{`${localMetal.name} table (density: ${localMetal.density}, price: ${localMetal.pricePerKg})`}</h3>
                        <Button color="red" onClick={handleDeleteTable}>Delete Table</Button>
                    </div>
                    <div>
                        <Input 
                            label='Density' 
                            onChange={handleChange} 
                            name="density" 
                            value={newDensity}
                        /> 
                        <Input 
                            label='Price' 
                            onChange={handleChange} 
                            name="price" 
                            value={newPrice}
                        /> 
                        <Button color="yellow" onClick={handlePatchMetal} disabled={!patchInputValid}>Update</Button>
                    </div>

                    <div style={{marginTop: '2rem'}}>Thumbnail</div>
                    <UploadFileComponent 
                        destination='thumbnails' 
                        url={localMetal.thumbnailUrl} 
                        metalName={localMetal.name}
                        setter={updater}
                    />
                    

                </div>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan={1}>Thickness (mm)</Table.HeaderCell>
                            <Table.HeaderCell colSpan={1}>Speed (m/min)</Table.HeaderCell>
                            <Table.HeaderCell colSpan={1}>Max Length (mm)</Table.HeaderCell>
                            <Table.HeaderCell colSpan={1}>Max Width (mm)</Table.HeaderCell>
                            <Table.HeaderCell colSpan={1}>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                    {
                        localMetal.table.length > 0 ? (
                            sortTable(localMetal.table)
                            .map((pair, idx) => (
                                <MetalTableRow 
                                    updateParentComponent={updater}
                                    key={idx} 
                                    idx={idx} 
                                    pair={pair} 
                                    metal={localMetal.name}
                                />
                            ))
                        ) : (null)
                    }
                    </Table.Body>
    
                    <Table.Footer>
                        <Table.Row>
                            <Table.Cell>
                                <Input error={!thicknessInputValid} value={newThickness} name="newThickness" placeholder="thickness" type="text" onChange={handleChange} fluid/>
                            </Table.Cell>
                            <Table.Cell>
                                <Input error={!speedInputValid} value={newSpeed} name="newSpeed" placeholder="enter rate in m/min" type="text" onChange={handleChange} fluid/>
                            </Table.Cell>
                            <Table.Cell>
                                <Input error={!lengthInputValid} value={newMaxLength} name="newMaxLength" placeholder="enter length in mm" type="text" onChange={handleChange} fluid/>
                            </Table.Cell>
                            <Table.Cell>
                                <Input error={!widthInputValid} value={newMaxWidth} name="newMaxWidth" placeholder="enter width in mm" type="text" onChange={handleChange} fluid/>
                            </Table.Cell>
                            
                            <Table.Cell>
                                <Button 
                                    fluid 
                                    onClick={handleAddThickness} 
                                    disabled={!inputReady}
                                >
                                Publish
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    metals: selectMetalsState
})

function mapDispatchToProps(dispatch) {
    return {
        setMetalsState: (array) => dispatch(setMetalsState(array))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MetalTable);

 // useEffect(() => {
    //     if (SS_ARR.includes(metal)) {
    //         if (evapValid && speedInputValid && thicknessInputValid && (evapSpeed || newSpeed) && newThickness) {
    //             setInputReady(true);
    //         } else {
    //             setInputReady(false);
    //         }
    //     } else {
    //         if (speedInputValid && thicknessInputValid && newSpeed && newThickness) {
    //             setInputReady(true);
    //         } else {
    //             setInputReady(false);
    //         }
    //     }
    // }, [evapSpeed, newSpeed, newThickness])

    /* {
                                SS_ARR.includes(metal) ? (
                                    <Table.Cell>
                                        <Input error={!evapValid} name="newEvapSpeed" placeholder="enter rate in m/min" type="text" onChange={handleChange} fluid/>
                                    </Table.Cell>
                                ) : (null)
                            } */

                            /* {
                                SS_ARR.includes(metal) ? (
                                    <Table.HeaderCell colSpan={1}>Evaporating Speed (m/min)</Table.HeaderCell>
                                ) : (null)
                            } */

// else if (name === "newEvapSpeed") {
//     setNewEvapSpeed(value);
//     if (!value) return setEvapValid(true);

//     if (!re.test(value)) {
//         return setEvapValid(false);
//     } else {
//         setEvapValid(true); 
//     }
// }
import React, { useEffect, useReducer, useRef } from 'react'
import { Table} from 'semantic-ui-react';
import QuantityChoiceRow from './QuantityChoiceRow';
import { selectMetalsState } from '../../../../../redux/app-state/appSelectors'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux';
import s from './QuantityChoicesTable.module.scss';
import { setMetalsState } from '../../../../../redux/app-state/appActions';

const ACTIONS = {
    SET_PATCHED: "SET_PATCHED",
    SET_LOCAL_METALS: "SET_LOCAL_METALS",
}
const INITIAL_STATE = {
    patched: false,
    localMetals: [], 
}
function reducer(state, action) {
    switch (action.type) {
        case ACTIONS.SET_PATCHED:
            return {
                ...state,
                patched: action.payload
            }
        case ACTIONS.SET_LOCAL_METALS:
            return {
                ...state,
                localMetals: action.payload
            }
      default:
        return { ...state }
    }
}

function QuantityChoicesTable({ metals, setMetalsState }) {
    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
    const stateRef          = useRef();
    stateRef.current        = state;

    useEffect(() => {
        if (metals) {
            dispatch({ type: ACTIONS.SET_LOCAL_METALS, payload: metals });
        }
        return () => {
            let { patched, localMetals } = stateRef.current;
            if (patched) {
                setMetalsState(localMetals);
            }
        }
    }, [])

    function updater(newMetal) {
        dispatch({ type: ACTIONS.SET_PATCHED, payload: true});
        dispatch({
            type: ACTIONS.SET_LOCAL_METALS,
            payload: stateRef.current.localMetals.map(m => m.name === newMetal.name ? newMetal : m)
        });
    }
 
    return (
        <div className={s.wrapper}>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={1}>Metal</Table.HeaderCell>
                        <Table.HeaderCell width={10}>Current quantity choices</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {
                        metals
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((metal, idx) => <QuantityChoiceRow key={idx} metal={metal} updateParent={updater}/>)                            
                    }
                </Table.Body>
            </Table>
        </div>
    )
}
const mapStateToProps = createStructuredSelector(
    {
        metals: selectMetalsState
    }
)

function mapDispatchToProps(dispatch) {
    return {
        setMetalsState: (metals) => dispatch(setMetalsState(metals))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(QuantityChoicesTable);
import React, { useState } from 'react'
import { Button, Form } from 'semantic-ui-react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { setAdminLogin } from '../../../redux/app-state/appActions';
import { store } from 'react-notifications-component';
import { FAIL_CONFIG, SUCCESS_CONFIG } from '../../../service/notifications';
import { BACKEND, BASIC_TRANSITION } from '../../../service/clientConstants'
import { motion } from 'framer-motion'
import s from './AdminLogin.module.scss';

function AdminLogin({ history, setAdminLogin }) {
    const [username, setUsername]         = useState('');
    const [password, setPassword]         = useState('');
    const [loginLoading, setLoginLoading] = useState(false);

    function handleOnChange(event) {
        const { name, value } = event.target;
        switch (name) {
            case "username":
                setUsername(value);
                return
            case "password":
                setPassword(value);
                return
            default:
                return
        }
    }

    function handleSubmit(event) {
        event.preventDefault();
        setLoginLoading(true);
        axios
        .post((BACKEND + '/admin/login'), {username, password})
        .then(res => {
            if (res.data.success) {
                SUCCESS_CONFIG.message = res.data.msg;
                store.addNotification(SUCCESS_CONFIG);
                setAdminLogin(true);
                setLoginLoading(false);
                history.push('/admin');
            } else {
                FAIL_CONFIG.message = res.data.msg;
                store.addNotification(FAIL_CONFIG);
                setLoginLoading(false);
            }
        })
        .catch(e => console.log(e));
    }

    return (
        <motion.div exit="exit" animate="visible" initial="hidden" variants={BASIC_TRANSITION} className={s.wrapper}>
            <h1 className={s.title}>Naggiar Login</h1>
            <Form onSubmit={handleSubmit}>
                <Form.Field>
                    <label>Username</label>
                    <input type='text' name='username' placeholder='username' onChange={handleOnChange} required/>
                </Form.Field>
                <Form.Field>
                    <label>Password</label>
                    <input type="password" name='password' placeholder='password' onChange={handleOnChange} required/>
                </Form.Field>
                <Button type='submit' loading={loginLoading} fluid>Submit</Button>
            </Form>
        </motion.div>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        setAdminLogin: (bool) => dispatch(setAdminLogin(bool))
    }
}
export default withRouter(connect(null, mapDispatchToProps)(AdminLogin));
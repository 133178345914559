import { QUOTE_WORKFLOW_ACTIONS } from './actionTypes';
import { returnSaveRate } from '../../service/saveRate';
import { round } from '../../service/helpers';
import { USD } from '../../service/clientConstants';

// all information for calculating the final price are set in this reducer
const INITIAL_STATE = {
    fileReadyToSubmit: true,
    apiDataLoading: false,
    apiData: {
        perimeter: 0,
        thumbnail: null
    },
    fileData: { name: "", uuid: "", length: "", width: "" },
    file: null,
    basePrice: null, // cost for 1 cut based off of formula thickness etc.
    finalPrice: null,
    discountRate: null,
    unitsInput: "", //  only "cm" or "mm"
    metal: {},
    currency: USD,
    thickness: "", //number in mm, string format
    quantity: "", // otherwise will be a number
    customQuantity: null, // also number
    percentLoaded: 0,
    loadingUpdate: "",
    inputErr: false,
    isCustomQuote: false,
    vat: null,
    currPrices: {},
    usdPriceInfo: {}
}

/*
    -set prevPriceInfo on initial get of prices
    { currency, basePrice, currPrices, customQuantity: 1, quantity: null }

    Scenarios involved:
    1.) User clicks LBP before quoting
    2.) User clicks it after quote (handle in reducer only)
    3.) case where everything changes in app at one time
    4.) apply USD || LBP conditional for every price calculation in app, including sagas

*/


export default function quoteWorkflowReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case QUOTE_WORKFLOW_ACTIONS.SET_CURRENCY:
            return {
                ...state,
                currency: action.payload,
            }
        case QUOTE_WORKFLOW_ACTIONS.SET_FINAL_PRICE: 
            return {
                ...state,
                finalPrice: action.payload
            }
        case QUOTE_WORKFLOW_ACTIONS.SET_USD_PRICE_INFO:
            return {
                ...state,
                usdPriceInfo: action.payload
            }
        case QUOTE_WORKFLOW_ACTIONS.SET_PRICES:
            let { basePrice, pricesObj } = action.payload;
            return {
                ...state,
                currPrices: pricesObj,
                basePrice: basePrice,
                finalPrice: basePrice,
            }
        case QUOTE_WORKFLOW_ACTIONS.RESET_PRICES:
            return {
                ...state,
                currPrices: {},
                basePrice: null,
                finalPrice: null
            }
        case QUOTE_WORKFLOW_ACTIONS.SET_INPUT_ERR:
            return {
                ...state,
                inputErr: action.payload
            }
        case QUOTE_WORKFLOW_ACTIONS.SET_VAT:
            return {
                ...state,
                vat: action.payload
            }
        case QUOTE_WORKFLOW_ACTIONS.END_WORKFLOW: 
            return {
                ...INITIAL_STATE,
                currency: state.currency,
                vat: state.vat
            }
        case QUOTE_WORKFLOW_ACTIONS.RESET_INPUTS:
            return {
                ...INITIAL_STATE,
                currency: state.currency,
                vat: state.vat
            }
        case QUOTE_WORKFLOW_ACTIONS.SET_IS_CUSTOM_QUOTE:
            return {
                ...state,
                isCustomQuote: action.payload
            }
        case QUOTE_WORKFLOW_ACTIONS.SET_FILE_LENGTH:
            return {
                ...state,
                fileData: {
                    ...state.fileData,
                    length: action.payload
                },
                thickness: ""
            }
        case QUOTE_WORKFLOW_ACTIONS.SET_FILE_WIDTH:
            return {
                ...state,
                fileData: {
                    ...state.fileData,
                    width: action.payload
                },
                thickness: ""
            }
        case QUOTE_WORKFLOW_ACTIONS.BACK_TO_METALS:
            return {
                ...state,
                metal: {},
                thickness: "",
                quantity: null,
                customQuantity: null
            }
        case QUOTE_WORKFLOW_ACTIONS.SET_FILE_READY_TO_SUBMIT:
            return {
                ...state,
                fileReadyToSubmit: action.payload
            }
        case QUOTE_WORKFLOW_ACTIONS.SET_API_DATA_LOADING:
            return {
                ...state,
                apiDataLoading: action.payload
            }
        case QUOTE_WORKFLOW_ACTIONS.SET_API_DATA:
            if (action.payload.thumbnail) {
                let blob = new Blob([action.payload.thumbnail], {type: "image/png"});
                return {
                    ...state,
                    apiData: {
                        perimeter: round(action.payload.perimeter, 2),
                        thumbnail: URL.createObjectURL(blob)
                    }
                }
            } else {
                return {
                    ...state,
                    apiData: action.payload
                }
            }
            
        case QUOTE_WORKFLOW_ACTIONS.SET_FILE_DATA:
            return {
                ...state,
                fileData: action.payload
            }
        case QUOTE_WORKFLOW_ACTIONS.SET_FILE:
            return {
                ...state,
                file: action.payload
            }
        case QUOTE_WORKFLOW_ACTIONS.SET_UNITS_INPUT:
            return {
                ...state,
                unitsInput: action.payload
            }
        case QUOTE_WORKFLOW_ACTIONS.SET_METAL_TYPE:
            return {
                ...state,
                metal: action.payload
            }
        case QUOTE_WORKFLOW_ACTIONS.SET_THICKNESS:

            return {
                ...state,
                thickness: action.payload
            }
        case QUOTE_WORKFLOW_ACTIONS.SET_QUANTITY:
            return {
                ...state,
                quantity: action.payload,
                customQuantity: null,
                discountRate: returnSaveRate(action.payload, state.metal.quantityChoices),
            }
        case QUOTE_WORKFLOW_ACTIONS.SET_CUSTOM_QUANTITY:
            return {
                ...state,
                quantity: null,
                customQuantity: action.payload,                
                discountRate: returnSaveRate(action.payload, state.metal.quantityChoices),
            }
        case QUOTE_WORKFLOW_ACTIONS.SET_PERCENT_LOADED:
            return {
                ...state,
                percentLoaded: action.payload
            }
        case QUOTE_WORKFLOW_ACTIONS.SET_LOADING_UPDATE:
            return {
                ...state,
                loadingUpdate: action.payload
            }
        case QUOTE_WORKFLOW_ACTIONS.DIMS_RESET: {
            return {
                ...state,
                basePrice: null, 
                finalPrice: null,
                discountRate: null,
                thickness: "", 
                quantity: "", 
                customQuantity: null
            }
        }
        default:
            return {
                ...state
            }
    }
}
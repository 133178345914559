import React, { useState } from 'react'
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios'
import { Button } from 'semantic-ui-react'
import { BACKEND } from '../../../../service/clientConstants'
import { SUCCESS_CONFIG, FAIL_CONFIG } from '../../../../service/notifications'
import { store } from 'react-notifications-component';
import { addUpdatedMetal, setMetalsState, setStaticSiteText, setUpdatedSiteText } from '../../../../redux/app-state/appActions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectMetalsState } from '../../../../redux/app-state/appSelectors';
import s from './RichTextEditor.module.scss';

function RichTextEditor({ 
        addUpdatedMetal, setUpdatedSiteText, setMetalsState, 
        currHtml, destination, toolbar, setStaticSiteText,
        setParentHtml=null, height='600', metalName='',
        customCard=false
}) {
    const [fullHtml, setFullHtml]   = useState(currHtml);
    const [loading, setLoading]    = useState(false);
    let btnText;
    if (customCard) {
        if (destination === 'customCardTitle' && !currHtml) {
            btnText = 'Create Title'
        } else if (destination === 'customCardImg' && !currHtml) {
            btnText = 'Add Card Thumbnail'
        } else if (destination === 'customCardBtm' && !currHtml) {
            btnText = 'Add Bottom Section'
        }
    }

    function handleSubmitHtml() {
        let route, purpose;
        if (destination === 'guidelines') {
            route = 'text';
            purpose = 'guidelinesHtml';
        } else if (destination === 'materialsTop') {
            route   = 'text'
            purpose = 'materialsTop'
        } else if (destination === 'materialsBottom') {
            route   = 'text'
            purpose = 'materialsBottom';
        } else if (destination === 'metalCardImg') {
            route   = 'metals'
            purpose = 'metalCardImgUpdate'
        } else if (destination === 'metalCardBottom') {
            route   = 'metals'
            purpose = 'metalCardBottomUpdate'
        } else if (destination === 'metalPageTop') {
            route   = 'metals';
            purpose = 'metalPageTopUpdate'
        } else if (destination === 'metalPageBottom') {
            route   = 'metals';
            purpose = 'metalPageBottomUpdate';
        } else if (destination === 'metalPageThumbnail') {
            route   = 'metals';
            purpose = 'metalPageThumbnailUpdate';
        } else if (destination === 'customCardTitle') {
            route = 'text';
            purpose = 'customCardTitleUpdate'
        } else if (destination === 'customCardImg') {
            route = 'text';
            purpose = 'customCardImgUpdate'
        } else if (destination === 'customCardBtm') {
            route = 'text';
            purpose = 'customCardBtmUpdate'
        } else if (destination === 'customPageThumbnail') {
            route = 'text';
            purpose = 'customPageThumbnailUpdate';
        } else if (destination === 'customPageTop') {
            route = 'text';
            purpose = 'customPageTopUpdate';
        } else if (destination === 'customPageBottom') {
            route = 'text';
            purpose = 'customPageBottomUpdate';
        }

        let confirmed = window.confirm('Are you sure you want to make this change?');


        // these can update siteText state directly, because the associated component can safely update
        let immediateUpdatePurposes = ['guidelinesHtml']
        if (confirmed) {
            setLoading(true);
            axios
            .patch((BACKEND + '/admin/' + route), { fullHtml, purpose, metalName })
            .then(res => {
                setLoading(false);
                if (res.data.success) {
                    // text route gives card and site text, metal route gives back metal and metals, html
                    let { siteText, card, metal, metals, html } = res.data;
                    if (route === 'text') {
                        if (immediateUpdatePurposes.includes(purpose)) {
                            return setStaticSiteText(siteText);
                        }
                        if (purpose.includes("Page")) {
                            setUpdatedSiteText(siteText);
                            return setParentHtml(html);
                        } 
                        if (purpose.includes("Card")) {
                            setUpdatedSiteText(siteText);
                            return setParentHtml(card, destination) // won't work
                        }
                        // only for materials page top and bottom html
                        if (purpose.includes("materials")) {
                            setUpdatedSiteText(siteText);
                            return setParentHtml(html);
                        }
                    } else if (route === 'metals') {
                        
                        // all metal page updates
                        if (purpose.includes("Page")) {
                            addUpdatedMetal(metal);
                            return setParentHtml(html);
                        } 
                        
                        // other routes
                        else if (destination === 'metalCardImg' || destination === 'metalCardBottom') {
                            setParentHtml(html, destination);
                            addUpdatedMetal(metal);
                        } else {
                            setMetalsState(metals);
                        }
                    }
                    SUCCESS_CONFIG.message = res.data.msg;
                    store.addNotification(SUCCESS_CONFIG);
                } else {
                    FAIL_CONFIG.message = res.data.msg;
                    store.addNotification(FAIL_CONFIG);
                }
            })
            .catch(e => console.log(e));    
        }
    }
    return (
        <div className={s.editorWrap}>
        {
            <>
                <Editor
                    initialValue={currHtml ? currHtml : null}
                    apiKey="cpba22tamnhl1vidk82cw660gpxwo5g112obs4jwq4hpbs4s"
                    init={{
                        valid_children : '-h1[h1]',
                        menubar: false,
                        convert_urls: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount hr image imagetools'
                        ],
                        toolbar: toolbar,
                        height: height,
                    }}
                    onEditorChange={(content, editor) => {
                        setFullHtml(content);
                    }}
                />
                <Button 
                    color="yellow" 
                    onClick={handleSubmitHtml}
                    loading={loading}
                >{btnText ? btnText : 'Update Changes' }</Button>
            </>
        }
        </div>
    );
}

const mapStateToProps = createStructuredSelector(
    {
        metals: selectMetalsState
    }
)    

function mapDispatchToProps(dispatch) {
    return {
        setMetalsState: (arr) => dispatch(setMetalsState(arr)),
        setStaticSiteText: (obj) => dispatch(setStaticSiteText(obj)),
        addUpdatedMetal: (metal) => dispatch(addUpdatedMetal(metal)),
        setUpdatedSiteText: (siteText) => dispatch(setUpdatedSiteText(siteText))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(RichTextEditor);
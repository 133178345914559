import React, { useState } from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectAdminLoggedIn } from '../../../../redux/app-state/appSelectors';
import { METAL_PAGE_TOOLBAR, SANITIZE_OPTS } from '../../../../service/clientConstants';
import { formatHtml } from '../../../../service/helpers';
import RichTextEditor from '../admin_elements/RichTextEditor'
import sanitizeHtml from 'sanitize-html';
import parse from 'html-react-parser'
function MaterialPageTopText({ destination, material, adminLoggedIn }) {
    const [localTopHtml, setLocalTopHtml] = useState(material.pageTopHtml);

    function updateHtml(html) {
        setLocalTopHtml(sanitizeHtml(html, SANITIZE_OPTS))
    }
    return (
        <div>
        {
            localTopHtml ? parse(localTopHtml, {
                replace: formatHtml
            }) : (null)
        }
        {
            adminLoggedIn ? (
                <RichTextEditor
                    currHtml={localTopHtml ? localTopHtml : null}
                    destination={destination}
                    toolbar={METAL_PAGE_TOOLBAR}
                    height='300'
                    metalName={material.name}
                    setParentHtml={updateHtml}
                />
            ) : (null)
        }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    adminLoggedIn: selectAdminLoggedIn
})
export default connect(mapStateToProps)(MaterialPageTopText);
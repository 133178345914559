import React from 'react'
import styles from './Elements.module.scss';
import nestingImg from '../../../../assets/nesting-ex.png';
import multiplesImg from '../../../../assets/multiples-ex.png';

function Multiples(props) {
    return (
        <div className={styles.wrapper}>
            <h2>Leave the nesting process for us</h2>
            <p>
                If you need to cut the same part multiple times, just add the quantity 
                requested when you fill the form, we have a professional team with professional programs that can do the most efficient 
                nesting to save your time, your money and fasten the process.
            </p>
            <img src={nestingImg} alt="correct multiples paths example"/>
            <b><p style={{marginBottom: '0'}}>Do you have multiple unique parts you want cut from the same metal or maybe the same design with different size?</p></b>
            <p>Please leave enough space between the unique designs on your file or send it for custom quotation.</p>
            <img src={multiplesImg} alt="multiple designs in one file example"/>
        </div>
    )
}
 
export default Multiples;
import React, { useRef, useEffect, useState } from 'react'
import { setCustomQuantity } from '../../../../redux/quote-workflow/quoteWorkflowActions';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect'
import { selectCustomQuantity, selectMetal, selectQuantity } from '../../../../redux/quote-workflow/quoteWorkflowSelectors';
import RadioChoice from './RadioChoice';
import styles from './QuantitySetter.module.scss';

const useMountEffect = scrollFunc => useEffect(scrollFunc, []);


// displays radio buttons with a custom input with the first radio button
function QuantitySetter({ setCustomQuantity, customQuantity, metal, quantity }) {

    let choices       = [];
    const [localCustomQty, setLocalCustomQty] = useState("1")
    if (metal.quantityChoices) {
        choices = Object.keys(metal.quantityChoices);
    }
    const myRef         = useRef(null);
    const executeScroll = () => myRef.current.scrollIntoView(); // run this function from an event handler or pass it to useEffect to execute scroll
    useMountEffect(executeScroll);

    useEffect(() => {
        if (!isNaN(localCustomQty) && localCustomQty !== '') {
            setCustomQuantity(parseInt(localCustomQty));
        }
    }, [localCustomQty])

    useEffect(() => {
        if (quantity) {
            setLocalCustomQty('');
        }
    }, [quantity])


    // customQuantity default value = null
    // customQuantity must be set to null if quantity is set; quantity represents only radio btn vals
    function handleChange(event) {
        const { name, value } = event.target;
        if (name === "customInput") {
            if (!customQuantity) { // handles the first radio button click
                setLocalCustomQty(1);
            }
        } else if (name === "customQty") {
            if (parseInt(value) > 0 || isNaN(value)) {
                setLocalCustomQty(parseInt(value));
            } else if (value === '') {
                setLocalCustomQty('');
                setCustomQuantity(null);
            }
        }
    }


    return (
        <ul className={styles.ulWrapper} ref={myRef}>
            <div className={styles.title}>Quantity</div>
            <div className={styles.inputWrapper}>
                <div>
                    <input type="radio" name="customInput" value={1} checked={ customQuantity ? true : false } onChange={handleChange}/>
                </div>
                <div className={styles.iconWrapper}>
                    <input 
                        type="number" 
                        min="1" 
                        name="customQty" 
                        onChange={handleChange} 
                        value={localCustomQty} 
                        className={styles.customQtyInput} 
                    />
                    {/* <span>{customQuantity ? customQuantity : 1}</span>
                    <div className={styles.arrowSection}>
                        <div style={{height: '50%'}}><img className={styles.icon} src={UpArrow} onClick={handleUpArrow} alt="up arrow"/></div>
                        <div style={{height: '50%'}}><img className={styles.icon} src={DownArrow} onClick={handleDownArrow} alt="down arrow"/></div>
                    </div> */}
                </div>
            </div>
            {
                choices.map((choice, idx) => <RadioChoice key={idx} choice={choice}/>)
            }
        </ul>
    )
}

const mapStateToProps = createStructuredSelector(
    {
        customQuantity: selectCustomQuantity,
        metal: selectMetal,
        quantity: selectQuantity
    }
);

function mapDispatchToProps(dispatch) {
    return {
        setCustomQuantity: (q) => dispatch(setCustomQuantity(q))
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(QuantitySetter);
import React, { useState, useEffect } from 'react'
import { Table, Button, Input } from 'semantic-ui-react';
import axios from 'axios';
import { store } from 'react-notifications-component';
import { SUCCESS_CONFIG, FAIL_CONFIG } from '../../../../../service/notifications';
import { BACKEND } from '../../../../../service/clientConstants'
import s from './DropzoneTextTable.module.scss';
import { createStructuredSelector } from 'reselect';
import { selectStaticSiteText } from '../../../../../redux/app-state/appSelectors';
import { connect } from 'react-redux';
import { setDropzoneText } from '../../../../../redux/app-state/appActions';

function DropzoneTextTable({ staticSiteText, setDropzoneText }) {
    // create new user state
    const INITIAL_STATE = {
        topHeading: "",
        cta1: "",
        cta2: "",
        cta3: "",
        note1: "",
        note2: ""
    }
    const [dropzoneTextInput, setDropzoneTextInput] = useState(INITIAL_STATE);
    const [inputEmpty, setInputEmpty] = useState(true);
    const [currDropzoneText, setCurrDropzoneText] = useState(staticSiteText.dropzoneText ? staticSiteText.dropzoneText : {});
    const [loading, setLoading] = useState(false);
    const [updated, setUpdated] = useState(false);


    // update rdx state when component unmounts
    useEffect(() => {
        return () => {
            if (updated) {
                setDropzoneText(currDropzoneText);
            }
        }
    }, [updated, currDropzoneText]);

    function handleEnterTable(e) {
        let { name, value } = e.target;
        setDropzoneTextInput(prevDropzoneText => {
            let obj = { ...prevDropzoneText };
            obj[name] = value;
            setInputEmpty(!Object.values(obj).some(x => (x !== null && x !== ''))); // checks if object is empty
            return obj;
        });
    }

    function handleSubmitDropzoneText(e) {
        setLoading(true);
        axios
        .patch((BACKEND + '/admin/text'), { dropzoneTextInput, purpose: "dropzoneTextEdit"})
        .then(res => {
            if (res.data.success) {
                setCurrDropzoneText(res.data.siteText.dropzoneText);
                SUCCESS_CONFIG.message = res.data.msg;
                store.addNotification(SUCCESS_CONFIG);
            } else {
                FAIL_CONFIG.message = res.data.msg;
                store.addNotification(FAIL_CONFIG);
            }
            setLoading(false);
            setUpdated(true);
        })
        .catch(e => console.log(e));
        setDropzoneTextInput(INITIAL_STATE);
    }
    return (
        <div className={s.wrapper}>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={9}>Current Dropzone Text</Table.HeaderCell>
                        <Table.HeaderCell>Edit</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    <Table.Row>
                        <Table.Cell>{currDropzoneText.topHeading}</Table.Cell>
                        <Table.Cell>
                            <Input 
                                name="topHeading" 
                                value={dropzoneTextInput.topHeading} 
                                type="text" 
                                onChange={handleEnterTable}
                                placeholder="enter new text (type [empty] to make the text empty)"
                                fluid
                            />
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell>{currDropzoneText.cta1}</Table.Cell>
                        <Table.Cell>
                            <Input 
                                name="cta1" 
                                value={dropzoneTextInput.cta1} 
                                type="text" 
                                onChange={handleEnterTable}
                                placeholder="enter new text (type [empty] to make the text empty)" 
                                fluid
                            />
                        </Table.Cell>
                    </Table.Row>
            
                    <Table.Row>
                        <Table.Cell>{currDropzoneText.cta2}</Table.Cell>
                        <Table.Cell>
                            <Input 
                                name="cta2" 
                                value={dropzoneTextInput.cta2} 
                                type="text" 
                                onChange={handleEnterTable}
                                placeholder="enter new text (type [empty] to make the text empty)" 
                                fluid
                            />
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell>
                            { currDropzoneText.cta3 ? currDropzoneText.cta3 : '[empty] '}**will only be on smaller screens**
                        </Table.Cell>
                        <Table.Cell>
                            <Input 
                                name="cta3" 
                                value={dropzoneTextInput.cta3} 
                                type="text" 
                                onChange={handleEnterTable}
                                placeholder="enter new text (type [empty] to make the text empty)" 
                                fluid
                            />
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell>{currDropzoneText.note1}</Table.Cell>
                        <Table.Cell>
                            <Input 
                                name="note1" 
                                value={dropzoneTextInput.note1} 
                                type="text" 
                                onChange={handleEnterTable}
                                placeholder="enter new text (type [empty] to make the text empty)" 
                                fluid
                            />
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell>{currDropzoneText.note2}</Table.Cell>
                        <Table.Cell>
                            <Input 
                                name="note2" 
                                value={dropzoneTextInput.note2} 
                                type="text" 
                                onChange={handleEnterTable}
                                placeholder="enter new text (type [empty] to make the text empty)" 
                                fluid
                            />
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>

                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan='2'>
                            <Button 
                                fluid 
                                loading={loading} 
                                onClick={handleSubmitDropzoneText}
                                disabled={inputEmpty}
                            >Publish</Button>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    staticSiteText: selectStaticSiteText
})

function mapDispatchToProps(dispatch) {
    return {
        setDropzoneText: (obj) => dispatch(setDropzoneText(obj))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DropzoneTextTable);
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import BasicMotionDiv from '../../reusable/BasicMotionDiv';
import { Button } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import useBodyClassChange, { isMaterial } from '../../../service/helpers';
import FilesDisplay from '../../reusable/FilesDisplay'
import { selectAdminLoggedIn, selectMetalsState, selectStaticSiteText } from '../../../redux/app-state/appSelectors';
import { updateMetalsState, updateSiteText } from '../../../redux/app-state/appActions';
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux';
import MetalsLinks from '../../reusable/MetalsLinks'
import s from './SpecificMaterialPage.module.scss'
import MaterialPageTopImg from './materials_elements/MaterialPageTopImg'
import MaterialPageTopText from './materials_elements/MaterialPageTopText'
import MaterialPageBottomHtml from './materials_elements/MaterialPageBottomHtml'
import NotFound from '../../top-level/pages/NotFound'


// because metals state is coming from Redux and not directly from the DB,
// we don't need to have a loading state to render RichTextEditor initial html
function SpecificMaterialPage({ history, metals, adminLoggedIn, staticSiteText: { customCards }, updateSiteText, updateMetalsState }) {
    useBodyClassChange(adminLoggedIn);
    const { material }                        = useParams();
    
    const [metal, setMetal]                   = useState({});
    const [customMaterial, setCustomMaterial] = useState({});
    const [isMetal, setIsMetal]               = useState(false);
    
    useEffect(() => {
        // determine if material is a metal or if it is a custom card material
        if (metals.length > 0) {
            const foundMetal = metals.find(m => m.name === material);
            const foundCustomMaterial = customCards.find(m => m.name === material)
            if (foundMetal === undefined) {
                setCustomMaterial(foundCustomMaterial)
                setIsMetal(false);
            } else {
                setMetal(foundMetal)
                setIsMetal(true);
            }
        }
        return () => {
            // update metals state on unmount, update sitetext on unmount
            updateMetalsState();
            updateSiteText();
        }
    }, []);

    if (!isMaterial(metals, customCards, material)) {
        return <NotFound/>
    }
    return (
        <BasicMotionDiv className={s.wrapper}>
            {
                adminLoggedIn ? (
                    <FilesDisplay/>
                ) : (null)
            }
            <div className={s.topSection}>
                <MaterialPageTopImg 
                    destination={isMetal ? 'metalPageThumbnail' : 'customPageThumbnail'} 
                    material={isMetal ? metal : customMaterial}
                />
                <MaterialPageTopText
                    destination={isMetal ? 'metalPageTop' : 'customPageTop'}
                    material={isMetal ? metal : customMaterial}
                />
            </div>

            
            <Button className={s.button} size="big" color="yellow" onClick={() => history.push('/quote')}>UPLOAD YOUR FILE</Button>

            <div className={s.btmHtmlSection}>
                <MaterialPageBottomHtml
                    destination={isMetal ? 'metalPageBottom' : 'customPageBottom'}
                    material={isMetal ? metal : customMaterial}
                />
            </div>
            <div className={s.btmSection}>
                <MetalsLinks/>
            </div>
        </BasicMotionDiv>
    )
}

const mapStateToProps = createStructuredSelector(
    {
        adminLoggedIn: selectAdminLoggedIn,
        metals: selectMetalsState,
        staticSiteText: selectStaticSiteText,
    }
);

function mapDispatchToProps(dispatch) {
    return {
        updateMetalsState: () => dispatch(updateMetalsState()),
        updateSiteText: () => dispatch(updateSiteText())
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SpecificMaterialPage));
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { BACKEND, BASIC_TRANSITION } from '../../service/clientConstants'
import { FAIL_CONFIG, SUCCESS_CONFIG } from '../../service/notifications';
import { store } from 'react-notifications-component';
import { Accordion, Button, Header, Icon } from 'semantic-ui-react';
import uuid from 'react-uuid'
import UploadFileComponent from './UploadFileComponent';
import s from './FilesDisplay.module.scss';
import { motion } from 'framer-motion';


// Material then thumbnails, graphics, images
function FilesDisplay() {

    const [hidden, setHidden]         = useState(false);
    const [rootPanels, setRootPanels] = useState([]);
    const [flipswitch, setFlipswitch] = useState(false);
    const [reloading, setReloading]   = useState(false);

    useEffect(() => {
        let isMounted = true;
        axios
        .get((BACKEND + '/filetree'))
        .then(res => {
            if (res.data.success && isMounted) {
                let { formattedObj } = res.data;
                let folders = Object.keys(formattedObj);
                setRootPanels(
                    folders.map(folder => {
                        return {
                            key: uuid(),
                            title: folder,
                            content: {
                                content: getLinksList(formattedObj[folder], folder)
                            }
                        }
                    })
                );
            }
            setReloading(false);
        })
        .catch(e => console.log(e));
        return () => { isMounted = false };
    }, [flipswitch]);
    
    function handleImgClick(e) {
        //navigator.clipboard.writeText(e.target.name);
        e.preventDefault();
        let el = document.createElement('textarea');
        el.value = e.target.value;
        el.setAttribute('readonly', '');
        el.style = {position: 'absolute', left: '-9999px'};
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        SUCCESS_CONFIG.message = "Copied to clipboard!"
        SUCCESS_CONFIG.id = uuid();
        store.addNotification(SUCCESS_CONFIG);
    }

    function handleImgDelete(link) {
        let confirmed = window.confirm('Are you sure you want to delete this image?');
        if (confirmed) {
            axios
            .delete((BACKEND + '/filetree'),{ data: { link }})
            .then(res => {
                if (res.data.success) {
                    setFlipswitch(!flipswitch);
                } else {
                    FAIL_CONFIG.message = res.data.msg;
                    store.addNotification(FAIL_CONFIG);
                }
                setReloading(false);
            })
            .catch(e => console.log(e));
        }

    }

    /*
       Only thumbnail images cannot be deletable 
    */
    function getLinksList(linksArray, folder) {
        return (
            <>
                <div style={linksArray.length === 0 ? {display: 'none'} : null} className={s.miniGallery}>
                {
                    linksArray.map((link, idx) => {
                        return (
                            <div key={idx} className={s.imgOuterWrap}>
                                <div onClick={() => handleImgDelete(link)} className={s.iconWrap}>
                                    <Icon  
                                        className={s.icon} 
                                        color='red' 
                                        name='close'
                                        value={link}
                                        link
                                    />
                                </div>
                                <input 
                                    type="image"
                                    value={link}
                                    name={folder}
                                    src={link}
                                    onClick={handleImgClick}
                                    alt="imgLink"
                                />
                            </div>
                        )
                    })
                }
                </div>
                {
                    linksArray.length > 0 ? (
                        <>
                            <div style={{marginTop: '1rem'}}>Click any image to copy url to clipboard!</div>
                            {
                                <UploadFileComponent 
                                    destination={folder} 
                                    includeLink={false}
                                    setter={() => setFlipswitch(!flipswitch)}
                                /> 
                            }
                        </>
                    ) : (
                        <div>
                            <div>Upload a file</div>
                            <UploadFileComponent 
                                destination={folder} 
                                includeLink={false}
                                setter={() => setFlipswitch(!flipswitch)}
                            />                        
                        </div>
                    )
                }
            </>
        )
    }


    function handleReloadClick() {
        setFlipswitch(!flipswitch);
        setReloading(true);
    }


    return (
        <motion.div key="filesDisplay" exit="exit" animate="visible" initial="hidden" variants={BASIC_TRANSITION} className={s.wrapper}>
            <Header as='h5' block>View Images Section</Header>
            {
                rootPanels && !hidden ? (
                    <>
                        <Accordion panels={rootPanels} styled />
                    </>
                ) : (null)
            }
            <div style={{marginTop: '1rem'}}>
                <Button
                    color="vk"
                    onClick={() => setHidden(!hidden)}>{hidden ? 'Show' : 'Hide'}</Button>
                <Button
                    color="vk"
                    loading={reloading}
                    onClick={handleReloadClick}>Reload</Button>
            </div>
        </motion.div>
    )
}

export default FilesDisplay;
import React, { useState, useEffect } from 'react'
import { Table, Button, Input } from 'semantic-ui-react';
import axios from 'axios';
import { connect } from 'react-redux';
import { FAIL_CONFIG, SUCCESS_CONFIG } from '../../../../../service/notifications';
import { setPricingVars } from '../../../../../redux/admin-state/adminActions'
import { BACKEND } from '../../../../../service/clientConstants'
import { store } from 'react-notifications-component';

// receives an index from map function and a pair from metal table
// idx  : number
// pair : Array [thickness (string), speed (number), maxLength (number), maxWidth(number)]
// metal: String
function DollarPaddingTableRow({ padding, from, to, idx, setPricingVars }) {
    const [updatedFrom, setUpdatedFrom]       = useState("");
    const [updatedTo, setUpdatedTo]           = useState("");
    const [inputReady, setInputReady]           = useState(false);
    const numRegex                              = /^\d+$/;

    useEffect(() => {
        if (updatedFrom || updatedTo) {
            setInputReady(true);
        } else {
            setInputReady(false);
        }
    }, [updatedFrom, updatedTo]);

    function handleUpdateClick() {
        
        //check if both strings are filled out, AND if there's only 1 char in each
        if (inputReady) {
            let confirmed = window.confirm('Are you sure you want to update the row? This action will immediately affect the quoting formula.');
            if (confirmed) {
                axios
                .patch((BACKEND + '/admin/pricing/dollar-padding'), {
                    padding: padding,
                    updatedTo,
                    updatedFrom
                })
                .then(res => {
                    if (res.data.success) {
                        setPricingVars(res.data.pricingVars);
                        setUpdatedFrom("");
                        setUpdatedTo("");
                        SUCCESS_CONFIG.message = res.data.msg;
                        store.addNotification(SUCCESS_CONFIG);
                    } else {
                        FAIL_CONFIG.message = res.data.msg + '\nPlease contact the administrator';
                        store.addNotification(FAIL_CONFIG);
                    }
                })
                .catch(e => console.log(e)); 
            }
        } else {
            FAIL_CONFIG.message = "Please make sure table input is valid.";
            store.addNotification(FAIL_CONFIG);
        }
    }

    function handleDeleteClick() {
        let confirmed = window.confirm('Are you sure you want to delete this whole table entry? This action will immediately affect the quoting formula.');
        if (confirmed) {
            axios
            .delete((BACKEND + '/admin/pricing/dollar-padding'),{ data: { padding: padding}})
            .then(res => {
                if (res.data.success) {
                    setPricingVars(res.data.pricingVars);
                    SUCCESS_CONFIG.message = res.data.msg;
                } else {
                    FAIL_CONFIG.message = res.data.msg + '\nPlease contact the administrator';
                    store.addNotification(FAIL_CONFIG);
                }
            })
            .catch(e => console.log(e));
        }
    }

    function handleChange(e) {
        const { name, value } = e.target;
        if (name === "updatedFrom") {
            if (!value) {
                setUpdatedFrom(value);
            }
            if (numRegex.test(value)) {
                setUpdatedFrom(value);
            } 
        } else if (name === "updatedTo") {
            if (!value) {
                setUpdatedTo(value);
            }
            if (numRegex.test(value)) {
                setUpdatedTo(value);
            } 
        } 
    }

    return (
        <Table.Row key={idx}>
            <Table.Cell>
                <table>
                    <tbody>
                        <Table.Row>
                            <Table.Cell>{padding}</Table.Cell>
                        </Table.Row>
                    </tbody>
                </table>
                
            </Table.Cell>

            <Table.Cell>
                <table>
                    <tbody>
                    <Table.Row>
                        <Table.Cell>{from}</Table.Cell>
                            <Table.Cell>
                                <Input 
                                    name="updatedFrom"
                                    value={updatedFrom}
                                    type="text" 
                                    placeholder="new value"
                                    onChange={handleChange}    
                                />
                            </Table.Cell>
                    </Table.Row>
                    </tbody>
                </table>
                
            </Table.Cell>

            <Table.Cell>
                <table>
                    <tbody>
                        <Table.Row>
                            <Table.Cell>{to}</Table.Cell>
                            <Table.Cell>
                                <Input 
                                    name="updatedTo" 
                                    value={updatedTo}
                                    type="text" 
                                    placeholder="new value"
                                    onChange={handleChange}    
                                />
                            </Table.Cell>
                        </Table.Row>
                    </tbody>
                </table>
            </Table.Cell>
            <Table.Cell>
                <Button color="red" onClick={handleDeleteClick}>Delete Row</Button>
                <Button disabled={!inputReady} color="yellow" onClick={handleUpdateClick}>Update Row</Button>
            </Table.Cell>
        </Table.Row>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        setPricingVars: (obj) => dispatch(setPricingVars(obj))
    }
}
 
export default connect(null, mapDispatchToProps)(DollarPaddingTableRow);
import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { setIsOnline } from '../../redux/app-state/appActions';
import { store } from 'react-notifications-component';

function OnlineSetter({ online, setIsOnline }) {
    useEffect(() => {
        setIsOnline(online);
           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [online, store]);
    return (
        <>
            
        </>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        setIsOnline: (online) => dispatch(setIsOnline(online))
    }
}

export default connect(null, mapDispatchToProps)(OnlineSetter);
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectPercentLoaded, selectLoadingUpdate, selectApiData, selectFile } from '../../../redux/quote-workflow/quoteWorkflowSelectors';
import { selectIsOnline } from '../../../redux/app-state/appSelectors';
import { resetInputs } from '../../../redux/quote-workflow/quoteWorkflowActions';
import { resetResultsModal, setResultsModalWorkflowStarted } from '../../../redux/results-modal/resultsModalActions';
import { formatSize } from '../../../service/helpers';
import { Progress, Button } from 'semantic-ui-react'
import dxfIcon from '../../../assets/dxf_icon_grey.png';
import pdfIcon from '../../../assets/pdf_icon_grey.png';
import dwgIcon from '../../../assets/dwg_icon_grey.png';
import cdrIcon from '../../../assets/cdr_icon.png';
import aiIcon from '../../../assets/ai_icon.png';
import epsIcon from '../../../assets/eps_icon.png';
import styles from './PreviewComponent.module.scss';


function PreviewComponent (props) {
    const { percentLoaded, loadingUpdate, apiData, isOnline, file,
        resetInputs, resetResultsModal, setResultsModalWorkflowStarted } = props;
    
    const [fileState, setFileState] = useState(null);
    const [icon, setIcon]           = useState(null);

    useEffect(() => {
        if (file) {
            setFileState(file);
            let fileIconInfoArr = file.name.split('.');
            let prefix = fileIconInfoArr[1];
            if (prefix === 'dxf') {
                setIcon(dxfIcon);
            } else if (prefix === 'dwg') {
                setIcon(dwgIcon);
            } else if (prefix === 'cdr') {
                setIcon(cdrIcon)
            } else if (prefix === 'ai') {
                setIcon(aiIcon);
            } else if (prefix === 'eps') {
                setIcon(epsIcon);
            } else if (prefix === 'pdf') {
                setIcon(pdfIcon);
            }
        }
    }, [file]);


    

    useEffect(() => {
        if (!isOnline && percentLoaded !== 100) {
            handleClick();
        }
           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOnline]);

    function handleClick () {
        resetInputs();
        resetResultsModal();
    }

    function handleViewResults() {
        setResultsModalWorkflowStarted(true);
    }

    return (
        <>
            <div className={styles.wrapper}>
                
                <div className={styles.infoWrapper}>
                    <div>
                        <span className={styles.file}>
                            <img src={icon} alt='icon'/>
                            {fileState ? fileState.name : null} {fileState ? formatSize(fileState.size):null}
                            <span className={styles.remove} onClick={handleClick}></span>
                            <Button onClick={handleClick} size="tiny" color="red">&#10005; reset</Button>

                        </span>
                    </div>

                    {
                        apiData.perimeter > 0 || apiData.error || percentLoaded === 100 ? (
                            <div className={styles.resultsBtn}>
                                <Button onClick={handleViewResults}>View Results</Button>
                            </div>
                        ) : (null)
                    }
                    

                    <div className={styles.done}> 
                        {
                            percentLoaded === 0 ? 'Uploading...' : ( apiData.perimeter > 0 || apiData.error || percentLoaded === 100 ? 'Done' : loadingUpdate)
                        }
                    </div>
                    <Progress className={styles.niceProg} size='medium' color='yellow' percent={apiData.perimeter > 0 ? 100 : percentLoaded}/>
                </div>
            </div>
        </>
        
    )
}

function mapDispatchToProps(dispatch) {
    return {
        resetInputs: () => dispatch(resetInputs()),
        resetResultsModal: () => dispatch(resetResultsModal()),
        setResultsModalWorkflowStarted: (bool) => dispatch(setResultsModalWorkflowStarted(bool))
    }
}

const mapStateToProps = createStructuredSelector(
    {
        percentLoaded: selectPercentLoaded,
        loadingUpdate: selectLoadingUpdate,
        apiData: selectApiData,
        isOnline: selectIsOnline,
        file: selectFile
    }
);


export default connect(mapStateToProps, mapDispatchToProps)(PreviewComponent);
import { ORDER_ACTIONS } from './actionTypes';

export function setOrderCurrency(userLoggedIn, currency) {
    return {
        type: ORDER_ACTIONS.SET_ORDER_CURRENCY,
        payload: { userLoggedIn, currency }
    }
}



export function updateCartPrices(cart) {
    return {
        type: ORDER_ACTIONS.UPDATE_CART_PRICES,
        payload: cart
    }
}



export function setNewCart(obj) {
    return {
        type: ORDER_ACTIONS.SET_NEW_CART,
        payload: obj
    }
}



export function resetOrderState() {
    return {
        type: ORDER_ACTIONS.RESET_ORDER_STATE,
    }
}



export function setFormDataObj(formDataObj) {
    return {
        type: ORDER_ACTIONS.SET_CUSTOM_QUOTE_DATA,
        payload: formDataObj
    }
}



export function setOrderId(id, userLoggedIn) {
    return {
        type: ORDER_ACTIONS.SET_ORDER_ID,
        payload: { id, userLoggedIn }
    }
}


export function setMerchantId(id, userLoggedIn) {
    return {
        type: ORDER_ACTIONS.SET_MERCH_ID,
        payload: { id, userLoggedIn }
    }
}


export function setSessionId(id, userLoggedIn) {
    return {
        type: ORDER_ACTIONS.SET_SESSION_ID,
        payload: { id, userLoggedIn }
    }
}


export function setOrder(orderObj, userLoggedIn) {
    return {
        type: ORDER_ACTIONS.SET_ORDER,
        payload: { customerInfo: orderObj, userLoggedIn }
    }
}


export function addToCart(object) {
    return {
        type: ORDER_ACTIONS.ADD_TO_CART,
        payload: object
    }
}

export function removeFromCart(idx) {
    return {
        type: ORDER_ACTIONS.REMOVE_FROM_CART,
        payload: idx
    }
}

export function decreaseItemQty(idx) {
    return {
        type: ORDER_ACTIONS.DECREASE_ITEM_QTY,
        payload: idx
    }
}

export function increaseItemQty(idx) {
    return {
        type: ORDER_ACTIONS.INCREASE_ITEM_QTY,
        payload: idx
    }
}


export function setCheckoutModalStarted(bool) {
    return {
        type: ORDER_ACTIONS.SET_CHECKOUT_MODAL_STARTED,
        payload: bool
    }
}


export function setPriceData(obj) {
    return {
        type: ORDER_ACTIONS.SET_PRICE_DATA,
        payload: obj
    }
}
import React, { useEffect } from 'react'
import MaterialCard from './materials_elements/MaterialCard'
import Heading from './materials_elements/Heading'
import { withRouter } from 'react-router-dom'
import { selectMetalsState, selectAdminLoggedIn, selectStaticSiteText } from '../../../redux/app-state/appSelectors'
import BasicMotionDiv from '../../reusable/BasicMotionDiv'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import s from './MaterialsPage.module.scss';
import useBodyClassChange from '../../../service/helpers'
import MetalsLinks from '../../reusable/MetalsLinks'
import CustomCard from '../pages/materials_elements/CustomCard'
import { setMetalsState, updateMetalsState, updateSiteText } from '../../../redux/app-state/appActions'
import uuid from 'react-uuid'
import CardSelector from './materials_elements/CardSelector'


function MaterialsPage ({ history, metals, adminLoggedIn, updateMetalsState, updateSiteText, staticSiteText: { customCards, cardSelections } }) {
    useBodyClassChange(adminLoggedIn);
    useEffect(() => {
        return () => {
            if (adminLoggedIn) {
                // updates rdx state in case of updates
                updateMetalsState();
                updateSiteText();
            }
        }
    }, [])


    let newCustomCards = [], metalCards = [], totalCards= [], sortedCards = [];
    metalCards = metals.map((m, idx) => <MaterialCard key={m._id} {...m}/>)
    if (customCards.length > 0) {
        newCustomCards = customCards.map((card, idx) => <CustomCard key={idx} {...card}/>)
    }
    totalCards  = [...newCustomCards, ...metalCards];
    sortedCards = totalCards.sort((a, b) => a.props.name.localeCompare(b.props.name));
    if (cardSelections !== undefined) {
        //remember, sortedCards are react elements, not normal objects
        sortedCards = sortedCards.filter(card => cardSelections[card.props.name] === true);
    }
    if (adminLoggedIn) {
        sortedCards.push(
            <CustomCard key={uuid()} creator={true} />
        )
    }


    return (
        <BasicMotionDiv className={s.wrapper}>
            <Heading/>
            {
                adminLoggedIn ? (
                    <CardSelector/>
                ) : (null)
            }
            <div className={s.cardsWrapper}>
                {sortedCards}
            </div>
            <MetalsLinks/>
        </BasicMotionDiv>
    )
}

const mapStateToProps = createStructuredSelector(
    {
        metals: selectMetalsState,
        adminLoggedIn: selectAdminLoggedIn,
        staticSiteText: selectStaticSiteText,
    }
)

function mapDispatchToProps(dispatch) {
    return {
        setMetalsState: (metals) => dispatch(setMetalsState(metals)),
        updateMetalsState: () => dispatch(updateMetalsState()),
        updateSiteText: () => dispatch(updateSiteText())
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MaterialsPage))
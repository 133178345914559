import React, { useState, useEffect } from 'react'
import logo from '../../assets/logo.png';
import s from './Header.module.scss';
import { withRouter } from 'react-router-dom';
import BurgerMenu from 'react-hamburger-menu';
import Links from './sub-components/Links';
import { useScrollToTop } from '../../service/helpers';
import { createStructuredSelector } from 'reselect';
import { selectAdminLoggedIn } from '../../redux/app-state/appSelectors';
import { connect } from 'react-redux';
import AdminDisplay from './pages/admin_elements/sub-components/AdminDisplay';

function Header({ history, adminLoggedIn }) {
    useScrollToTop();
    const [width, setWidth]                   = useState(0);
    const [burgerClicked, setBurgerClicked]   = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    function handleBurgerClick() {
        setBurgerClicked(!burgerClicked);
        setMobileMenuOpen(!mobileMenuOpen);
    }
    function updateDims() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        updateDims();
        window.addEventListener('resize', updateDims);
    }, []);

    const styles = {
        boxShadow: '0 4px 6px -6px #222'
    }

    return (
        <div id="mainHeader" style={adminLoggedIn ? styles : null} className={s.wrapper}>
            {
                width < 1024 ? (
                    <>
                        <div className={s.top}>
                            <img src={logo} onClick={() => history.push("/")} alt="logo" />
                            <BurgerMenu
                                isOpen={burgerClicked}
                                menuClicked={handleBurgerClick}
                                width={18}
                                height={15}
                                strokeWidth={2}
                                rotate={0}
                                color="black"
                                borderRadius={0}
                                animationDuration={0.5}
                                className={s.burger}
                            />
                        </div>
                        {burgerClicked ? <hr /> : null}
                    </>
                ) : (
                    <div className={s.top}>
                        <img style={{cursor: "pointer"}} src={logo} onClick={() => history.push("/")} alt="logo" />
                        <Links width={width}/>
                    </div>
                )
            }
            {
                burgerClicked ? (
                    <>
                        <div>
                            <Links/>
                            {
                                adminLoggedIn && <AdminDisplay/>
                            }
                        </div>
                        <hr/>
                    </>
                    
                ) : (null)
            }
        </div>        
    );
}

const mapStateToProps = createStructuredSelector({
    adminLoggedIn: selectAdminLoggedIn
});

export default withRouter(connect(mapStateToProps)(Header));
/* eslint-disable no-self-compare, no-eval, no-unused-vars */
import { returnSaveRate } from './saveRate';
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import React from 'react'
import { Link } from 'react-router-dom'
import { FRONTEND } from './clientConstants'
import { domToReact } from 'html-react-parser'
import axios from 'axios'
import { BACKEND, LBP } from './clientConstants'
// used in MetalTable.jsx
// checks if an input string matches the correct format (e.g., 12mm)
export function isValidInput(string) {
    if (+string === +string) { // tests if number or not
        return false;
    } else if (string.includes("mm")) {
        const numString = string.substring(0, string.indexOf("mm"));
        if ( (+numString === +numString) && (string.length - string.indexOf("mm") === 2) ) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

// used in ThicknessSelector.jsx
// sorts an array of arrays by thickness in ascending order
// array param is in format [ [thickness, speed], ..., ..., ]
// thickness and speed are of string type
export function sortThicknessTable(array) {
    return array.sort((a, b) => {
        return parseFloat(a) > parseFloat(b) ? 1 : -1
    });
}

// used in QuantityChoicesTable.jsx
// converts and returns a string of comma separated values to an arr of numbers
// the goal is to overwrite the existing choices array of arrays ( [..., [choice, multiplier], ... ) in the metal
export function returnNumberArray(string, existingChoices) {

    // format user input string into an array of numbers in ascending order
    let stringArr = string.split(',').sort((a, b) => parseFloat(a) > parseFloat(b) ? 1 : -1);
    let inputArr = stringArr.map(x => {
        return parseFloat(x);
    });

    // format existingchoices in ascending order
    let existingArr = existingChoices.sort((a, b) => a > b ? 1 : -1);


    // return final array with existing choices
    return inputArr.map((choice, idx) => {
        if (existingArr[idx] && existingArr[idx][1] ) {
            return [choice, existingArr[idx][1]]
        } else {
            return [choice, '']
        }
    });
};

// takes in user input in ManageDisplay.jsx
// returns true if decimal is in format: 0.XX exactly
export function isFormattedDecimal(value) {
    if (value.indexOf('.') === 1 && value.indexOf('0') === 0 && value.length === 4) {
        let arr = value.split('.');
        if (arr.length === 2) {
            return true;
        }
        return false;
    } else {
        return false;
    }
}




// use in reducer for creating a new cart and updated count
// use in orderReducer.js
export async function returnNewCartAndCount(existingCart, idx, add) {
    let newCart = [];
    let newCount = 0;
    for (let i = 0; i < existingCart.length; i++) {
        if (i !== idx) { // for cart items we don't care about
            newCart.push(existingCart[i]);
            newCount += parseInt(existingCart[i].finalQuantity);
        } else { // for the cart item we do care about
            let updatedItem = existingCart[i];

            if (add) {
                updatedItem.finalQuantity = parseInt(updatedItem.finalQuantity) + 1;
            } else if (!add && existingCart[i].finalQuantity > 1) {
                updatedItem.finalQuantity = parseInt(updatedItem.finalQuantity) - 1;
            }

            let { finalQuantity, metal, apiData, thickness, fileData, unitsInput, currency } = updatedItem;
            updatedItem.discount = returnSaveRate(finalQuantity, metal.quantityChoices)


            // match the pattern sent by saga:
            let params = { perimeter: apiData.perimeter, metal, thickness, fileData, unitsInput };
            let qty    = finalQuantity;


            // get price
            try {
                const res = await axios.post((BACKEND + '/price'), { params, qty, multiple: false, currency });
                updatedItem.finalPrice = res.data.finalPrice;
            } catch(err) {
                return { err } 
            }

            newCart.push(updatedItem);
            newCount += parseInt(finalQuantity);
        }
    }
    return { newCart, newCount }
}

// used in SubmissionForm.jsx
// check if current thickness choice is in new metal thickness table
// yes? set the thickness choice no? ""
// table is in format [ [thickness, rate] ... ]
// takes in currThickness state (string), user selected metal dropdown change (string), and metalsArr from db as [] of objects
// returns a boolean value
export function inTable(currThickness, newMetalName, metalsArr) {
    const newMetal = metalsArr.find(obj => obj.name === newMetalName);
    for (let e of newMetal.table) {
        if (e[0] === currThickness) {
            return true;
        }
    }
    return false;
}

// use in ReceiptPage.jsx
export function formatFormData(orderState) {
    const formData = new FormData();

    //cart
    orderState.cart.forEach((item, idx) => {
        formData.append("files[]", item.file, item.file.name)
        formData.append(`cartItem ${idx + 1}`, JSON.stringify({
            apiData: item.apiData,
            basePrice: item.basePrice,
            discount: item.discount,
            fileData: item.fileData,
            finalPrice: item.finalPrice,
            metal: item.metal,
            thickness: item.thickness,
            unitsInput: item.unitsInput,
            finalQuantity: item.finalQuantity,
            currency: item.currency
        }));
    });

    
    let completeUserInfo;
    // order id
    if (orderState.nonUserOrder.orderId) { 
        completeUserInfo = {
            ...orderState.nonUserOrder,
            ...orderState.priceData,
            cartItems: orderState.cartItems
        }
        let keys = Object.keys(completeUserInfo);
        keys.forEach(key => {
            if (typeof(completeUserInfo[key]) === 'number') {
                if (completeUserInfo[key] % 1 !== 0) {
                    completeUserInfo[key] = completeUserInfo[key].toFixed(2);
                }
            }
        });
        formData.append("orderInfo", JSON.stringify(completeUserInfo))
    } else {
        completeUserInfo = {
            ...orderState.userOrder,
            pricingData: orderState.pricingData,
            cartItems: orderState.cartItems.toString()
        }
        formData.append("orderInfo", JSON.stringify(completeUserInfo))
    }
    return formData;
}


// dropzone uploader and preview component; takes in byte size of file and returns a nice string
export function formatSize(bytes) {
    const units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    let l = 0
    let n = bytes

    while (n >= 1024) {
        n /= 1024
        l += 1
    }

    //console.log("Bytes: " + bytes)
   // console.log(`(${n.toFixed(n >= 10 || l < 1 ? 0 : 1)}${units[l]})`);
    return `(${n.toFixed(n >= 10 || l < 1 ? 0 : 1)}${units[l]})`
}




export function getCutSpeed(table, thickness) {
    for (let i = 0; i < table.length; i++) {
        if (table[i][0] === thickness) {
            return parseFloat(table[i][1]);
        }
    }
}


// return a boolean, makes sure user input is within set boundaries for a metal's max length and max width,
// and the minimum size of 20cm x 20cm
// fileData     : obj w/ length and width as strings
// thicknessTbl : array of arrays. Each arr element is [thickness (string), cutSpeed (number), length(number), width(number)]
// thickness    : string
// units        : string
export function withinBounds(fileData, thicknessTbl, thickness, units) {
    let { length, width } = fileData;
    length                = parseInt(length);
    width                 = parseInt(width);
    let tblEntry          = thicknessTbl.filter(entry => entry[0] === thickness)[0];
    let tblLength         = tblEntry[2];
    let tblWidth          = tblEntry[3];
    if (units === "mm") {
        if (length < 200 || width < 200) {
            return false
        }
        if (length <= tblLength && width <= tblWidth) {
            return true
        } else {
            return false;
        }
    } else if (units === "cm") {
        tblWidth  = tblWidth / 10;
        tblLength = tblLength / 10;
        if (length <= 20 || width <= 20) {
            return false
        }
        if (length < tblLength && width < tblWidth) {
            return true
        } else {
            return false;
        }
    }
    return false;
}


export function getBoundariesMsg(metal, thickness, units) {
    let entry = metal.table.filter(entry => entry[0] === thickness)[0];
    let part1, part2;
    if (units === "mm") {
        part1 = `To generate a live quote, the length must be between 200mm and ${entry[2]}mm`;
        part2 = `, and width must be between 200mm and ${entry[3]}mm for ${metal.name} ${thickness}. `;
    } else {
        part1 = `To generate a live quote, length must be between 20cm and ${entry[2]/10}cm`;
        part2 = `, and width must be between 20cm and ${entry[3]/10}cm for ${metal.name} ${thickness}. `;
    }
    return part1 + part2 + "If you need this exact size, continue to a custom quote.";
}

export default function useBodyClassChange(adminLoggedIn) {
    const location = useLocation();
    const locations = ['/cart', '/quote', '/'];
    useEffect(() => {
        if (locations.indexOf(location.pathname) === -1) {
            if (adminLoggedIn) {
                document.body.classList.add('tempNoOverflow')
            }
        } else {
            if (adminLoggedIn) {
                document.body.classList.remove('tempNoOverflow')
            }
        }
    }, [location.pathname]);
}

export function useScrollToTop() {
    const location = useLocation();
    useEffect(() => {
        document.getElementById('mainHeader').scrollIntoView();
    }, [location.pathname]);
}


// for use in parsing html in guidelines and other places
// if p tag is domNode, it will remove the p tag and spit out image
// do not manipulate params: params are dictated by html-react-parser
export function formatHtml(domNode) {
    const validTags = ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'pre'];
    if (validTags.includes(domNode.name) && domNode.type === 'tag') {
        let newChildren = [];
        let styles      = {};

        if (domNode.attribs && domNode.attribs.style) {
            styles = convertStyleToObject(domNode.attribs.style);
        }
        if (domNode.children.length > 0) {
            domNode.children.forEach((childNode, idx) => {
                if (childNode.name === 'a') {
                    let href = childNode.attribs.href;
                    let splitter;
                    if (!href.includes("http://")) {
                        splitter = FRONTEND.split("http://")[1];
                    } else {
                        splitter = FRONTEND;
                    }
                    if (href.includes(splitter)) {
                        let text = childNode.children[0].data;
                        const urlToPush = href.split(splitter)[1];
                        newChildren.push(<Link key={idx} to={urlToPush}>{text}</Link>);       
                    } else {
                        if (childNode.attribs) {
                            childNode.attribs.key = idx;
                        }
                        // non link component, but an anchor
                        newChildren.push(domToReact([childNode]))
                    }
                } else {
                    if (childNode.attribs) {
                        childNode.attribs.key = idx;
                    }
                    newChildren.push(domToReact([childNode]));
                }
            });
        }
        if (domNode.name === 'p') return <p style={styles}>{newChildren}</p>
        if (domNode.name === 'h1') return <h1 style={styles}>{newChildren}</h1>
        if (domNode.name === 'h2') return <h2 style={styles}>{newChildren}</h2>
        if (domNode.name === 'h3') return <h3 style={styles}>{newChildren}</h3>
        if (domNode.name === 'h4') return <h4 style={styles}>{newChildren}</h4>
        if (domNode.name === 'h5') return <h5 style={styles}>{newChildren}</h5>
        if (domNode.name === 'h6') return <h5 style={styles}>{newChildren}</h5>
    }
}

function convertStyleToObject(style) {
    return style.split(';')
    .filter(s => s.length)
    .reduce((a, b) => {
        const keyValue = b.split(':');
        a[camelize(keyValue[0])] = keyValue[1] ; 
        return a;
    } ,{})
}

function camelize(string) {
    return string.replace(/-([a-z])/gi,(s, group) =>  group.toUpperCase());
}

export function isMaterial(metals, customCards, param) {
    if (metals.some(m => m.name === param)) {
        return true;
    }
    if (customCards.some(c => c.name === param)) {
        return true;
    }
    return false
}


export function round(value, decimals) {
    let str = Number(Math.round(value +'e'+ decimals) +'e-'+ decimals).toFixed(decimals);
    return parseFloat(str);
}


export function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min);
}
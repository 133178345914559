import React, { useState } from 'react'
import { Input, Button } from 'semantic-ui-react';
import { FAIL_CONFIG, SUCCESS_CONFIG } from '../../../../service/notifications';
import MetalTable from './sub-components/MetalTable';
import axios from 'axios';
import { store } from 'react-notifications-component';
import { BACKEND } from '../../../../service/clientConstants';
import { randomIntFromInterval } from '../../../../service/helpers';
import s from './ManageTables.module.scss';
import { connect } from 'react-redux';
import { setMetalsState } from '../../../../redux/app-state/appActions';
import { createStructuredSelector } from 'reselect';
import { selectMetalsState } from '../../../../redux/app-state/appSelectors';
/*
    1. Input metals into DB, one at a time for testing
    2. Input the lengths for each, one at a time for testing
    3. Input the mm/minute rate for each, on a a time for testing
*/
function ManageTables({ metals, setMetalsState }) {
    const re = /^\d+(\.\d{1,9})?$/;

    // input state and vals
    const INITIAL_STATE = {
        metal: "", density: "", pricePerKg: ""
    }
    const [densityErr, setDensityErr]       = useState(false);
    const [pricePerKgErr, setPricePerKgErr] = useState(false);
    const [inputFull, setInputFull]         = useState(false);
    const [metalData, setMetalData] = useState(INITIAL_STATE);
    
    function handleAddMetal() {   
        axios
        .post((BACKEND + '/admin/metals'), { metalData })
        .then(res => {
            if (res.data.success) {
                SUCCESS_CONFIG.message = res.data.msg;
                store.addNotification(SUCCESS_CONFIG);
                setMetalData(INITIAL_STATE);
                setMetalsState(res.data.metals);
            } else {
                FAIL_CONFIG.message = res.data.msg + '\nIf the problem continues, please contact the administrator';
                store.addNotification(FAIL_CONFIG);
            }
        }).catch(e => {
            console.log(e);
        });
    }


    function handleChange(e) {
        let { name, value } = e.target;
        setMetalData(prevData => {
            let obj = { ...prevData };
            obj[name] = value;
            setInputFull(!Object.values(obj).some(x => x === "")); // checks if object is full
            return obj;
        });
        if (name === "density") {
            if (!re.test(value) && value ) {
                setDensityErr(true);
            } else {
                setDensityErr(false);
            }

        } else if (name === "pricePerKg") {
            if (!re.test(value) && value ) {
                setPricePerKgErr(true);
            } else {
                setPricePerKgErr(false);
            }
        }
    }

    
    

    return (
        <div className={s.form}>
            <div className={s.wrapper}>
                <h1 className={s.title}>Edit Metal Tables</h1>
                <div className={s.directions}>
                    <h3>Directions</h3>
                    <ul>
                        <li>When adding a new metal, you must fill out every field in order to create a new table.</li>
                        <li>Enter numbers/decimals only for density and price per KG</li>
                    </ul>
                </div>
                <div className={s.inner}>
                    <h3>Add New Metal</h3>
                        <Input 
                            label="Metal name" 
                            type="text" 
                            name="metal"
                            placeholder="e.g., Stainless Steel Matte"
                            onChange={handleChange}
                            className={s.name}
                            value={metalData.metal}
                        />
                        <Input 
                            label="Density" 
                            type="text" 
                            name="density"
                            placeholder="e.g., 2.33"
                            onChange={handleChange}
                            className={s.name}
                            value={metalData.density}
                        />
                        <Input 
                            label="Price Per KG" 
                            type="text" 
                            name="pricePerKg"
                            placeholder="e.g., 3.72"
                            onChange={handleChange}
                            className={s.name}
                            value={metalData.pricePerKg}
                        />
                    <div>
                        <Button onClick={handleAddMetal} disabled={densityErr || pricePerKgErr || !inputFull}>Submit</Button>
                    </div>
                </div>
            </div>
            <div>
                {
                    metals && metals.length > 0 ? (
                        metals
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((metal, idx) => (
                            <MetalTable 
                                key={randomIntFromInterval(1, 1000000)} 
                                metal={metal}
                            />
                        ))
                    ) : (null)
                }
            </div>

        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    metals: selectMetalsState
})

function mapDispatchToProps(dispatch) {
    return {
        setMetalsState: (array) => dispatch(setMetalsState(array))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageTables);
import React, { useState, useEffect, useRef } from 'react'
import { Button, Label } from 'semantic-ui-react';
import axios from 'axios';
import { store } from 'react-notifications-component';
import { FAIL_CONFIG, SUCCESS_CONFIG } from '../../service/notifications';
import { BACKEND, FILE_UPLOAD_KEY } from '../../service/clientConstants'
import s from './UploadFileComponent.module.scss'; 
import { setMetalsState, setStaticSiteText } from '../../redux/app-state/appActions';
import { connect } from 'react-redux';

// from: admin panel for thickness selectors
//       admin panel for home page
//       Materials Page for each metal: thumbnail, iconsImg
//       Metal Page: Thumbnail title image, iconsImg, any amount of images
//       guidelines page

// destination: string
//              possible values: home, metals, guidelines
// setter     : function (usually a set state)
// url        : string metal's image url
// metalName  : string 
// includeLink: Boolean (if set to false, it will not display ability for link to show)
//              also, it is mainly set to false for the FilesDisplay.jsx component
function UploadFileComponent({ destination, url, setMetalsState, setStaticSiteText, setter=null, metalName=null, includeLink=true }) {

    const [file, setFile]                  = useState(null);
    const [fileName, setFileName]          = useState('');
    const fileInputElement                 = useRef(null);
    const [localUrl, setLocalUrl]          = useState(url);
    const [loading, setLoading]            = useState(false);

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (url) {
                setLocalUrl(url);
            }
        }
        return () => {
            mounted = false;
        }
    }, [url])

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            if (!file) {
                if (localUrl && localUrl.includes(FILE_UPLOAD_KEY)) {
                    setFileName(localUrl.split(FILE_UPLOAD_KEY)[1]);
                } else if (!localUrl) {
                    setFileName ('');
                }
            } else {
                setFileName(file.name);
            }
        }
        return () => {
            mounted = false;
        }
    }, [file, localUrl])

    function handleFileBtnClick(e) {
        e.preventDefault();
        fileInputElement.current.click();
    }

    function handleFileChange(e) {
        if (e.target.files[0]) {
            if (includeLink === false) { // only for filesDisplay.js; automatic upload
                handleFileSubmit(e.target.files[0]);
            } else {
                setFile(e.target.files[0]);
                setFileName(e.target.files[0].name);
            }
        }
        
    }

    function handleFileReset() {
        setFile(null);
        fileInputElement.current.value = '';
        setLoading(false);
    }

    function handleFileSubmit(file) {
        setLoading(true);
        const formData     = new FormData();
        const METALS_ROUTE = '/admin/metals';
        const TEXT_ROUTE   = '/admin/text';
        const FILES_ROUTE  = '/filetree';
        let route, folder;
        formData.append("baseUrl", BACKEND);

        // file must be appended LAST in order for multer to access req.body
        if (destination === 'thumbnails') {
            route  = METALS_ROUTE;
            folder = 'public/thumbnails/'
            formData.append("folder", folder);
            formData.append("metalName", metalName);
            formData.append("thumbnailFile", file);
        } else if (destination === 'homePage') {
            // home page image is updated where site text is updated
            route  = TEXT_ROUTE;
            folder = 'public/pages/home/'
            formData.append("folder", folder);
            formData.append("homePageImg", file);
        } else if (destination === 'guidelines') {
            route  = FILES_ROUTE;
            folder = 'public/pages/guidelines/';
            formData.append("folder", folder);
            formData.append("guidelinesImg", file);
        } else if (destination === 'material-detail-page') {
            route  = FILES_ROUTE;
            folder = 'public/pages/material-detail-page/'
            formData.append("folder", folder);
            formData.append("materialDetailImg", file);
        } else if (destination === 'materials-overview') {
            route  = FILES_ROUTE;
            folder = 'public/pages/materials-overview/'
            formData.append("folder", folder);
            formData.append("materialOverviewImg", file);
        }


        axios({
            method: 'post',
            url: (BACKEND + route),
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then(res => {
            if (res.data.success) {
                if (route === METALS_ROUTE) {
                    // set file name too
                    //setFileName(res.data.originalName);
                    setter(res.data.metal);
                } else if (route === TEXT_ROUTE) {
                    setFileName(res.data.originalName)
                    setLocalUrl(res.data.imgUrl);
                    setter(res.data.imgUrl);
                } else if (route === FILES_ROUTE) {
                    setFileName('');
                    setter();
                }
                SUCCESS_CONFIG.message = res.data.msg;
                store.addNotification(SUCCESS_CONFIG);
                // metals route updates whole state, so this will update an unmounted component
                if (route !== METALS_ROUTE) setFile(null);
            } else {
                FAIL_CONFIG.message = res.data.msg;
                store.addNotification(FAIL_CONFIG);
            }
            setLoading(false);
        })
        .catch(e => {
            console.log(e);
        });
    }



    return (
        <div className={!includeLink ? s.fileDisplayWrapper : null}>
            <Button className={s.btnWrapper} type="button" as='div' labelPosition='left'>
                <Label basic>{fileName ? fileName : 'No file'}</Label>
                <Button 
                    type="button" 
                    color="yellow" 
                    className={s.fileBtn} 
                    onClick={handleFileBtnClick}>{!includeLink ? 'Select File' : 'Change'}</Button>
                <input type="file" ref={fileInputElement} onChange={handleFileChange} accept=".jpg,.png,.jpeg" hidden/>
            </Button>
            {
                includeLink ? (
                    <>
                        <Button disabled={!file} onClick={handleFileReset}>Reset</Button>
                        <Button color="blue" disabled={!file} onClick={() => handleFileSubmit(file)} loading={loading}>{!includeLink ? 'Upload' : 'Update'}</Button>
                    </>

                ) : (null)
            }
            {
                includeLink ? (
                    <Button circular className={s.viewFileBtn}>
                    {
                        !file && localUrl ? (
                            <a className={s.viewImg} href={localUrl} target='_blank' rel="noopener noreferrer">View File</a>
                        ) : (
                            <a className={s.viewImg}>N/A</a>
                        )
                    }
                    </Button>
                ) : (null)
            }
        </div>
    )
}

function mapDispatchToProps(dispatch) {
    return {
        setStaticSiteText: (siteText) => dispatch(setStaticSiteText(siteText)),
        setMetalsState: (metals)  => dispatch(setMetalsState(metals))
    }
}
export default connect(null, mapDispatchToProps)(UploadFileComponent);
/* eslint-disable no-eval, no-unused-vars */
import React, { useEffect } from 'react'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux';
import { selectApiData, selectInputErr } from '../../../../redux/quote-workflow/quoteWorkflowSelectors';
import NormalPrices from './sub-components/NormalPrices'
import CustomQuotePrices from './sub-components/CustomQuotePrices'

// thickness is needed to remount and request new prices; changes will remount
function Prices({ apiData, inputErr }) {  
    
    return (
        apiData.perimeter > 0 && !inputErr ? (
            <NormalPrices/>
        ) : (
            <CustomQuotePrices/>
        )
    )
}

 
const mapStateToProps = createStructuredSelector(
    {
        apiData: selectApiData,
        inputErr: selectInputErr,
    }
);

export default connect(mapStateToProps)(Prices);
import React from 'react'
import HomePageEdit from './sub-components/HomePageEdit'
function ManageHomePage() {
    return (
        <div>
            <HomePageEdit/>
        </div>
    )
}
 
export default ManageHomePage;
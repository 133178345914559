import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from './rootReducer';
import rootSaga from './rootSaga';

// for handling async actions, and adding redux logger
const sagaMiddleWares = createSagaMiddleware();
const middleWares     = [sagaMiddleWares];
if (process.env.NODE_ENV === "development") {
    middleWares.push(logger);
}

export const store = createStore(rootReducer, applyMiddleware(...middleWares));
sagaMiddleWares.run(rootSaga);
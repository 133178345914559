import React from 'react'
import DropzoneTextTable from './sub-components/DropzoneTextTable';

function ManageQuotePage() {
    
    return (
        <div>
            <DropzoneTextTable/>
        </div>
    )
}
 
export default ManageQuotePage;
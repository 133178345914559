import React from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { selectAdminLoggedIn, selectMetalsState } from "../../redux/app-state/appSelectors";
import s from './MetalsLinks.module.scss';
function MetalsLinks({ metals, adminLoggedIn }) {
    const location = useLocation();

    return (
        <>
        {
            !adminLoggedIn && location.pathname.includes('/materials/') ? (
                <>
                    <h1 style={{ textAlign: "center" }}>Other Metals</h1>
                    <div className={s.linkWrapper}>
                    {
                        metals.length > 0 ? metals.sort((a, b) => a.name.localeCompare(b.name)).map((metal, idx) => {
                            let dest = `/materials/${metal.name}`
                            return <Link key={idx} to={dest}>{metal.name.toUpperCase()}</Link>
                        }) : (null)
                    }
                    </div>
                </>
            ) : adminLoggedIn ? (
                <div className={s.adminWrapper}>
                    <h1 style={{ textAlign: "center" }}>Links to use for metal pages</h1>
                    <div className={s.linkWrapper}>
                    {
                        metals.length > 0 ? metals.map((metal, idx) => {
                            let dest = `/materials/${metal.name}`
                            return <Link key={idx} to={dest}>{metal.name.toUpperCase()}</Link>
                        }) : (null)
                    }
                    </div>
                </div>
            ) : (
                null
            )
        }
        </>
    )
}
const mapStateToProps = createStructuredSelector(
    {
        metals: selectMetalsState,
        adminLoggedIn: selectAdminLoggedIn
    }
)
export default connect(mapStateToProps)(MetalsLinks);

import { ORDER_ACTIONS } from './actionTypes'
const INITIAL_STATE = {
    nonUserOrder: {
        merchantId: "",
        sessionId: "",
        orderId: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        company: "",
        note: "",
        currency: "USD",
    },
    userOrder: {
        merchantId: "",
        sessionId: "",
        orderId: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        company: "",
        note: "",
        currency: "USD",
    },
    cart: [],
    priceData: {},
    cartItems: 0,
    checkoutModalStarted: false,
    customQuoteFormData: null,
} 

export default function orderReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case ORDER_ACTIONS.SET_ORDER_CURRENCY:
            let { userLoggedIn, currency } = action.payload;
            return {
                ...state,
                userOrder: {
                    ...state.userOrder,
                    currency: userLoggedIn ? currency : ""
                },
                nonUserOrder: {
                    ...state.nonUserOrder,
                    currency: userLoggedIn ? "" : currency
                }
            }
        case ORDER_ACTIONS.UPDATE_CART_PRICES:
            return {
                ...state,
                cart: action.payload
            }
        case ORDER_ACTIONS.SET_NEW_CART: {
            return {
                ...state,
                cart: action.payload.newCart,
                cartItems: action.payload.newCount
            }
        }
        case ORDER_ACTIONS.SET_CUSTOM_QUOTE_DATA:
            return {
                ...state,
                customQuoteFormData: action.payload
            }
        case ORDER_ACTIONS.SET_CHECKOUT_MODAL_STARTED:
            return {
                ...state,
                checkoutModalStarted: action.payload
            }
        case ORDER_ACTIONS.SET_ORDER_ID:
            if (action.payload.userLoggedIn) {
                return {
                    ...state,
                    userOrder: {
                        ...state.userOrder,
                        orderId: action.payload.id
                    }
                }
            } else {
                return {
                    ...state,
                    nonUserOrder: {
                        ...state.nonUserOrder,
                        orderId: action.payload.id
                    }
                }
            }
        case ORDER_ACTIONS.SET_MERCH_ID:
            if (action.payload.userLoggedIn) {
                return {
                    ...state,
                    userOrder: {
                        ...state.userOrder,
                        merchantId: action.payload.id
                    }
                }
            } else {
                return {
                    ...state,
                    nonUserOrder: {
                        ...state.nonUserOrder,
                        merchantId: action.payload.id
                    }
                }
            }
        case ORDER_ACTIONS.SET_SESSION_ID:
            if (action.payload.userLoggedIn) {
                return {
                    ...state,
                    userOrder: {
                        ...state.userOrder,
                        sessionId: action.payload.id
                    }
                }
            } else {
                return {
                    ...state,
                    nonUserOrder: {
                        ...state.nonUserOrder,
                        sessionId: action.payload.id
                    }
                }
            }
        case ORDER_ACTIONS.SET_ORDER:
            if (action.payload.userLoggedIn) {
                return {
                    ...state,
                    userOrder: {
                        ...state.userOrder,
                        ...action.payload.customerInfo
                    }
                }
            } else {
                return {
                    ...state,
                    nonUserOrder: {
                        ...state.nonUserOrder,
                        ...action.payload.customerInfo
                    }
                }
            }
        case ORDER_ACTIONS.ADD_TO_CART:
            return {
                ...state,
                cart: [...state.cart, action.payload],
                cartItems: state.cartItems + parseFloat(action.payload.finalQuantity) // payload is from selectCartItem in quoteWorkflowSelectors
            }
        case ORDER_ACTIONS.REMOVE_FROM_CART:
            return {
                ...state,
                cartItems: state.cartItems - state.cart[action.payload].finalQuantity,
                cart: state.cart.filter((item, idx) => idx !== action.payload)
            }
        case ORDER_ACTIONS.SET_PRICE_DATA:
            return {
                ...state,
                priceData: action.payload
            }
        case ORDER_ACTIONS.RESET_ORDER_STATE:
            return {
                ...INITIAL_STATE
            }
        default:
            return {
                ...state
            }
    }
}

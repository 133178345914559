import React, { useState } from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Dropdown } from 'semantic-ui-react'
import { setCurrency } from '../../../redux/quote-workflow/quoteWorkflowActions';
import { LBP, USD } from '../../../service/clientConstants';
import lbFlag from '../../../assets/lebanon-flag-icon-64.png';
import usFlag from '../../../assets/united-states-of-america-flag-icon-64.png';
import s from './CurrencySelector.module.scss';
import { selectShowDollarsOnly } from '../../../redux/app-state/appSelectors';


function CurrencySelector({ setCurrency, showDollarsOnly }) {
    const opts = [
        { key: 0, text: USD, value: 0, image: {src: usFlag }},
    ];


    if (showDollarsOnly === false) {
        opts.push({ key: 1, text: LBP, value: 1, image: {src: lbFlag }});
    }
   


    const [currSelection, setCurrSelection] = useState(0);

    

    function handleChange({ currentTarget }) {
        let { value, textContent } = currentTarget;
        setCurrSelection(value);
        setCurrency(textContent);
    }

    return (
        <div className={s.wrapper}>
            <Dropdown 
                options={opts} 
                onChange={handleChange} 
                value={currSelection}
                fluid
                selection
                compact
            />
        </div>
    )
}



const mapStateToProps = createStructuredSelector({
    showDollarsOnly: selectShowDollarsOnly
});

function mapDispatchToProps(dispatch) {
    return {
        setCurrency: (str) => dispatch(setCurrency(str))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CurrencySelector);
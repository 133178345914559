/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Table, Button, Input } from 'semantic-ui-react';
import DollarPaddingTableRow from './DollarPaddingTableRow';
import axios from 'axios';
import { connect } from 'react-redux'
import { setPricingVars } from '../../../../../redux/admin-state/adminActions'
import { createStructuredSelector } from 'reselect'
import { selectPricingVars } from '../../../../../redux/admin-state/adminSelectors';
import { store } from 'react-notifications-component';
import { FAIL_CONFIG } from '../../../../../service/notifications';
import { BACKEND } from '../../../../../service/clientConstants'
import s from './MetalTable.module.scss';

// metal is a string, table is an array of [ [length, speed], ... ]
function DollarPaddingTable({ setPricingVars, pricingVars }) {
    const [newPadding, setNewPadding]                   = useState("");
    const [paddingInputValid, setPaddingInputValid]     = useState(true);
    const [from, setFrom]                               = useState("");
    const [fromInputValid, setFromInputValid]           = useState(true);
    const [to, setTo]                                   = useState("");
    const [toInputValid, setToInputValid]               = useState(true);
    const numRegex                                      = /^\d+$/;
    const [inputReady, setInputReady]                   = useState(false);

    useEffect(() => {
        if (newPadding && from && to) {
            if (paddingInputValid && fromInputValid && toInputValid) {
                setInputReady(true);
            } else {
                setInputReady(false);
            }
        }
    }, [from, newPadding, to, ]);


    function handleClick() {
        //check if both strings are filled out, AND if there's only 1 char in each
        if (inputReady) {
            axios
            .post((BACKEND + '/admin/pricing/dollar-padding'), {
                paddingData: {
                    padding: newPadding,
                    from: from,
                    to: to,
                }
            })
            .then(res => {
                if (res.data.success) {
                    setFrom("");
                    setTo("");
                    setNewPadding("");
                    setPricingVars(res.data.pricingVars);
                } else {
                    FAIL_CONFIG.message = res.data.msg;
                    store.addNotification(FAIL_CONFIG);
                }
            })
            .catch(e => console.log(e)); 
        } else {
            FAIL_CONFIG.message = "Please make sure table input is valid."
            store.addNotification(FAIL_CONFIG);
        }   
    }
    
    function handleChange(e) {
        const { name, value } = e.target;
        if (name === "newPadding") {
            setNewPadding(value);
            if (!value) return setPaddingInputValid(true);
            if (!numRegex.test(value)) {
                return setPaddingInputValid(false);
            } else {
                setPaddingInputValid(true); 
            }
        } else if (name === "from") {
            setFrom(value);
            if (!value) return setFromInputValid(true);
            if (!numRegex.test(value)) {
                return setFromInputValid(false);
            } else {
                setFromInputValid(true); 
            }
        } else if (name === "to") {
            setTo(value);
            if (!value) return setToInputValid(true);
            if (!numRegex.test(value)) {
                return setToInputValid(false);
            } else {
                setToInputValid(true);
            }
        }
    }

    return (
        <div className={s.wrapper}>
            <div className={s.inner}>
                <div className={s.top}>
                    <h3>Dollar Padding Table</h3>
                    <ul>
                        <li>the "from" and "to" range represents the final price of a cart item, based off of one file. A user can select more than one cut per cart item.</li>
                        <li>If an admin user accidentally enters in two padding entries with overlapping ranges, the first range will be used.</li>
                        <li>E.g., if the first entry is 2$ from 1$ to 30$ and the second entry is 5$ from 20$ to 50$, there will be an overlapping range (20 - 30 are in both ranges). If the cart item's final price is 25$, the formula will use the first padding, 2$</li>
                        <li>To avoid this, make sure from and to ranges don't overlap.</li>
                    </ul>
                </div>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell colSpan={1}>Padding ($)</Table.HeaderCell>
                            <Table.HeaderCell colSpan={1}>From ($)</Table.HeaderCell>
                            <Table.HeaderCell colSpan={1}>To ($)</Table.HeaderCell>
                            <Table.HeaderCell colSpan={1}>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
    
                    <Table.Body>
                    {
                        pricingVars.dollarPaddingTbl ? (
                            Object.keys(pricingVars.dollarPaddingTbl).map((padding, idx) => (
                                <DollarPaddingTableRow
                                    key={idx}
                                    idx={idx}
                                    padding={padding} 
                                    from={pricingVars.dollarPaddingTbl[padding].from} 
                                    to={pricingVars.dollarPaddingTbl[padding].to}
                                />
                            ))
                        ) : (null)
                    }
                    </Table.Body>
    
                    <Table.Footer>
                        <Table.Row>
                            <Table.Cell>
                                <Input error={!paddingInputValid} value={newPadding} name="newPadding" placeholder="$" type="text" onChange={handleChange} fluid/>
                            </Table.Cell>
                            <Table.Cell>
                                <Input error={!fromInputValid} value={from} name="from" placeholder="$ price" type="text" onChange={handleChange} fluid/>
                            </Table.Cell>
                            <Table.Cell>
                                <Input error={!toInputValid} value={to} name="to" placeholder="$ price" type="text" onChange={handleChange} fluid/>
                            </Table.Cell>
                            <Table.Cell>
                                <Button 
                                    fluid 
                                    onClick={handleClick} 
                                    disabled={!inputReady}
                                >
                                Publish
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                    </Table.Footer>
                </Table>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector(
    {
        pricingVars: selectPricingVars,
    }
);

function mapDispatchToProps(dispatch) {
    return {
        setPricingVars: (obj) => dispatch(setPricingVars(obj))
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(DollarPaddingTable);
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import ReactNotification from 'react-notifications-component';
import axios from 'axios'
import Header from './Header';
import Footer from './Footer';
import WorkflowStartPage from '../workflow/WorkflowStartPage';
import Login from './pages/Login';
import Admin from './pages/Admin';
import PrivateRoute from './PrivateRoute';
import AdminLogin from './pages/AdminLogin';
import Cart from './pages/Cart';
import { Switch, Route, withRouter, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { setResultsModalWorkflowStarted } from '../../redux/results-modal/resultsModalActions';
import { selectApiDataLoading, selectFileData } from '../../redux/quote-workflow/quoteWorkflowSelectors';
import { 
    setApiData, 
    setApiDataLoading, 
    setPercentLoaded, 
    setLoadingUpdate,
    setVat
} from '../../redux/quote-workflow/quoteWorkflowActions';
import { selectAdminLoggedIn, selectIsOnline, selectStateLoaded } from '../../redux/app-state/appSelectors';
import { socket } from '../../service/socket.js';
import { Detector } from 'react-detect-offline';
import { FAIL_CONFIG, RETRY_CONFIG } from '../../service/notifications';
import { store } from 'react-notifications-component';
import OnlineSetter from './OnlineSetter';
import ReceiptPage from './pages/ReceiptPage';
import 'react-notifications-component/dist/theme.css'
import ConfirmationPage from './pages/ConfirmationPage';
import Guidelines from './pages/Guidelines';
import HomePage from './pages/HomePage';
import { BACKEND } from '../../service/clientConstants'
import { AnimatePresence } from 'framer-motion'
import MaterialsPage from './pages/MaterialsPage';
import SpecificMaterialPage from './pages/SpecificMaterialPage';
import BasicMotionDiv from '../reusable/BasicMotionDiv'
import NotFound from './pages/NotFound'
import OldGuidelines from './pages/OldGuidelines'
import { setMetalsState, setStaticSiteText, setStateLoaded, setShowDollarsOnly } from "../../redux/app-state/appActions";


function App(props) {

    const { setApiData, setApiDataLoading, setResultsModalWorkflowStarted, 
        setPercentLoaded, setLoadingUpdate, adminLoggedIn,
        rdxFileData, isOnline, vat, setVat, setMetalsState,
        setStaticSiteText, stateLoaded, setStateLoaded, setShowDollarsOnly
    } = props;
    const location = useLocation();

    // socket connects to our backend and listens for emitted events from the back-end
    useEffect(() => {
        // we must check every file name from current request in case user cancels while uploading
        // if user removes file during upload, the /uploads route will still be processing
        socket.on('begin loading', ({ currentFileData }) => {
            if (currentFileData.uuid === rdxFileData.uuid) {
                setApiDataLoading(true);
                setLoadingUpdate('Uploading...')
            }
        });
        socket.on("LOADING_UPDATE", ({percent, currentFileData}) => { // for all other workflow functions
            if (currentFileData.uuid === rdxFileData.uuid) {
                setPercentLoaded(percent);
                setLoadingUpdate("Processing...")
            }
        });
        socket.on("WORK_ITEM_STATUS_CHECK", ({msg, currentFileData}) => { // for work item status check only
            if (currentFileData.uuid === rdxFileData.uuid) {
                setLoadingUpdate(msg);
            }
        });
        socket.on('processing error', (error) => {
            let { currentFileData } = error;
            if (currentFileData !== undefined) {
                if (currentFileData.uuid === rdxFileData.uuid) {
                    setPercentLoaded(100);
                    setApiData(error);
                    setResultsModalWorkflowStarted(true);
                    if (error.res.devData.statusMessage === 'Gateway Time-out') {
                        RETRY_CONFIG.message = "Looks like there was a network issue. Please re-upload the file and try again."
                        store.addNotification(RETRY_CONFIG);
                    }
                }
                if (JSON.stringify(error).includes("TIMEOUT")) {
                    store.addNotification(RETRY_CONFIG);
                }
            } else {
                setPercentLoaded(100);
                setApiData(error);
                setResultsModalWorkflowStarted(true);
            }
        });
        socket.on('incoming results', ({currentFileData, results}) => { // if error, res and fileProcessed will be valid, otherwise only fileProcessed and data
            // first "if" is when the user clears file while loading
            // in this case percentLoaded will have been set to 0 while we get a response
            if (currentFileData.uuid === rdxFileData.uuid) {
                setApiData(results);
                setApiDataLoading(false);
                setPercentLoaded(100);
                setResultsModalWorkflowStarted(true);
            }
        });

        // cleanup on every re-render due to change in rdxFileData.name
        return () => socket.removeAllListeners();
    }, [rdxFileData.name]);
    

    useEffect(() => {
        if (!isOnline) {
            FAIL_CONFIG.id = 'connection';
            FAIL_CONFIG.message = 'The internet was disconnected. Please wait until the connection is restored.'
            store.addNotification(FAIL_CONFIG);
        }
    }, [isOnline])


    useEffect(() => {
        const getState = async () => {
            const [res1, res2, res3, res4] = await Promise.all([
                axios.get(BACKEND + '/admin/pricing/vat'),
                axios.get(BACKEND + '/admin/metals'),
                axios.get(BACKEND + '/admin/text'),
                axios.get(BACKEND + '/admin/pricing/togglecurrency')
            ]);
            await setVat(res1.data.vat);
            await setMetalsState(res2.data.metals);
            await setStaticSiteText(res3.data.text[0]);
            await setShowDollarsOnly(res4.data.showDollarsOnly);
            setStateLoaded(true);
        };
        getState();
    }, [setStateLoaded]);


    return (
        <div className="App">
            {
                stateLoaded ? (
                    <>
                        <ReactNotification/>
                        <Detector render={({ online }) => <OnlineSetter online={online} />}/>
                        <Header/>
                        <AnimatePresence>
                            <Switch location={location} key={location.pathname}>
                                <Route exact path="/" component={HomePage}/>
                                <Route exact path="/quote" component={WorkflowStartPage}/>
                                {/* <Route exact path="/login" component={Login}/> */}
                                <Route exact path="/guidelines" component={Guidelines}/>  
                                <Route exact path="/admin/login" component={AdminLogin}/> 
                                <Route exact path="/materials" component={MaterialsPage}/> 
                                <Route exact path="/materials/:material" component={SpecificMaterialPage}/>
                                <Route exact path ="/receiptPage" component={ReceiptPage}/>
                                <Route exact path ="/confirmation" component={ConfirmationPage}/>
                                <Route exact path="/cart" component={Cart}/> 
                                <Route exact path="/oldguidelines" component={OldGuidelines}/>
                                <PrivateRoute path="/admin" authed={adminLoggedIn} component={Admin}/>
                                <Route render={({ location }) => {
                                    if (location.pathname.includes("admin")) {
                                        return (
                                            <BasicMotionDiv>
                                                <Redirect to="/admin/login" />
                                            </BasicMotionDiv>  
                                        )
                                    } else {
                                        return <NotFound/>
                                    }
                                }} />
                            </Switch>
                        </AnimatePresence>
                        <Footer/>
                    </>
                ) : (null)
            }
        </div>
    );
}

const mapStateToProps = createStructuredSelector(
    {
        apiDataLoading: selectApiDataLoading,
        adminLoggedIn: selectAdminLoggedIn,
        rdxFileData: selectFileData,
        isOnline: selectIsOnline,
        stateLoaded: selectStateLoaded
    }
);

function mapDispatchToProps(dispatch) {
    return {
        setApiData: (data) => dispatch(setApiData(data)),
        setApiDataLoading: (bool) => dispatch(setApiDataLoading(bool)),
        setResultsModalWorkflowStarted: (bool) => dispatch(setResultsModalWorkflowStarted(bool)),
        setPercentLoaded: (num) => dispatch(setPercentLoaded(num)),
        setLoadingUpdate: (update) => dispatch(setLoadingUpdate(update)),
        setVat: (vat) => dispatch(setVat(vat)),
        setMetalsState: (array) => dispatch(setMetalsState(array)),
        setStaticSiteText: (obj) => dispatch(setStaticSiteText(obj)), 
        setStateLoaded: (bool) => dispatch(setStateLoaded(bool)),
        setShowDollarsOnly: (bool) => dispatch(setShowDollarsOnly(bool))
    }
}

// withRouter must always be the outermost wrapping function w/ redux
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));


import React, { useState, useEffect } from 'react'
import dxfIcon from '../../../../assets/dxf_icon_grey.png';
import pdfIcon from '../../../../assets/pdf_icon_grey.png';
import dwgIcon from '../../../../assets/dwg_icon_grey.png';
import cdrIcon from '../../../../assets/cdr_icon.png';
import aiIcon from '../../../../assets/ai_icon.png';
import epsIcon from '../../../../assets/eps_icon.png';
import { store } from 'react-notifications-component';
import { INPUT_MSG_CONFIG } from '../../../../service/notifications';
import { connect } from 'react-redux';
import { setUnitsInput, setFileLength, setFileWidth, handleDimsChange, setInputErr } from '../../../../redux/quote-workflow/quoteWorkflowActions';
import { createStructuredSelector } from 'reselect'
import { selectUnitsInput, selectFileData, selectApiData, selectMetal, selectThickness, selectIsCustomQuote } from '../../../../redux/quote-workflow/quoteWorkflowSelectors';
import { selectMetalChoicesVisible, selectQuantityChoicesVisible, selectThicknessChoicesVisible } from '../../../../redux/results-modal/resultsModalSelectors';
import { setMetalChoicesVisible, setQuantityChoicesVisible, setThicknessChoicesVisible } from '../../../../redux/results-modal/resultsModalActions';
import { withinBounds, getBoundariesMsg } from '../../../../service/helpers';
import styles from './OverviewSection.module.scss';
import { Input } from 'semantic-ui-react';

// first component in MainPane's hierarchy
// displays the heading, preview img, file name and icon, unit verification/input
function OverviewSection({ quantityChoicesVisible, unitsInput, setUnitsInput, fileData, thicknessChoicesVisible, handleDimsChange, setInputErr, isCustomQuote,
    setMetalChoicesVisible, metalChoicesVisible, apiData, setFileLength, setFileWidth, setQuantityChoicesVisible, metal, thickness, setThicknessChoicesVisible }) {

    const [length, setLength] = useState(fileData.length ? fileData.length : "");
    const [width, setWidth]   = useState(fileData.width ? fileData.width : "");

    

    useEffect(() => {
        if (length && width && !thicknessChoicesVisible) {
            setMetalChoicesVisible(true);
        } else if (!length || !width) {
            // only metal choices can be seen; once it is chosen, they are no longer visible
            if (metalChoicesVisible) {
                setMetalChoicesVisible(false);
            }

            // thickness choices visible will also show qty choices; qty choices are dependent on thickness choice being visible in MAne Pane
            if (thicknessChoicesVisible) {
                setThicknessChoicesVisible(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [length, width]);


    useEffect(() => {
        if (thickness && unitsInput) {
            if (!withinBounds(fileData, metal.table, thickness, unitsInput)) {
                setInputErr(true);
                if (!apiData.error) {
                    if (isCustomQuote) {

                    } else {
                               
                    }
                    INPUT_MSG_CONFIG.message = getBoundariesMsg(metal, thickness, unitsInput);
                    store.addNotification(INPUT_MSG_CONFIG); 
                }
            } else {
                setInputErr(false);
            }
        } 
           // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [thickness, fileData, unitsInput]);


    // get the right icon for the file's name 
    const re      = new RegExp('^[1-9][0-9]{0,8}$');
    const fileExt = fileData.name.split('.')[1];
    let iconSrc = "";
    if (fileExt === 'dxf') {
        iconSrc = dxfIcon;
    } else if (fileExt === 'dwg') {
        iconSrc = dwgIcon;
    } else if (fileExt === 'ai') {
        iconSrc = aiIcon;
    } else if (fileExt === 'cdr') {
        iconSrc = cdrIcon;
    } else if (fileExt === 'eps') {
        iconSrc = epsIcon;
    } else {
        iconSrc = pdfIcon;
    }

    // sets metalChoicesVisible to true when radio button is clicked
    // sets unitsInput in quoteWorkflow redux state
    function handleUnitsCheck(event) {
        setUnitsInput(event.target.value);
    }

    function handleDimChange(event) {
        const { name, value } = event.target;

        // pricing error occurs if user changes dims if they chose a quantity
        setQuantityChoicesVisible(false);
        handleDimsChange(); // resets some state values
        // if user changes dims, don't show prices and qty choices because of pricing error
        if (name === "width") {
            if (value === "") {
                setFileWidth(value);
                return setWidth(value);
            }

            if (re.test(value)) {
                setWidth(value)
                setFileWidth(value);
            }
        } else if (name === "length") {
            if (value === "") {
                setFileLength(value);
                return setLength(value);
            }
            if (re.test(value)) {
                setLength(value);
                setFileLength(value);
            }
        }
    } 

    return (
        <div>
            <div className={styles.wrapper}>
                <div className={styles.titleWrapper}>
                    <h1 className={styles.title}>Quote</h1>
                </div>
    
                <div className={styles.detailsCol}>
                    <div className={styles.fileInfo}>
                        <img className={apiData.thumbnail ? styles.blob : styles.icon} src={apiData.thumbnail ? apiData.thumbnail : iconSrc} alt="icon"/>
                        <p style={{paddingLeft: '8px'}}>{fileData.name}</p>
                    </div>

                    <div className={styles.verificationDisplay}>
                        <p>Please choose the units for the design:</p>
                        
                        <div className={styles.unitsInput}>Units:
                            <input style={{marginLeft: '5px'}} type="radio" value="mm" checked={unitsInput === 'mm' ? true : false} onChange={handleUnitsCheck}/>
                            <span>Millimeters</span>
                            <input style={{marginLeft: '5px'}} type="radio" value="cm" checked={unitsInput === 'cm' ? true : false} onChange={handleUnitsCheck}/>
                            <span>Centimeters</span>
                        </div>

                        <div>
                        <p>Please enter the width and height of the drawing:</p>
                        <div className={styles.lenAndWidth}>
                            <Input 
                                label='length' 
                                type="text" 
                                name="length"
                                value={length}
                                onChange={handleDimChange} 
                                className={styles.dimInput}
                                disabled={unitsInput ? false : true}
                            />
                            <span style={{margin: '0 1rem'}}>x</span>
                            <Input 
                                label='width' 
                                type="text" 
                                name="width" 
                                value={width}
                                onChange={handleDimChange} 
                                className={styles.dimInput}
                                disabled={unitsInput ? false : true}
                            />   
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                thicknessChoicesVisible ? (
                    <div className={styles.metal}>{metal.name}</div>
                ) : (null)
            }
            <hr style={{marginTop: '0.5rem'}}/>
        </div>
    )
}

const mapStateToProps = createStructuredSelector(
    {
        unitsInput: selectUnitsInput,
        fileData: selectFileData,
        apiData: selectApiData,
        thicknessChoicesVisible: selectThicknessChoicesVisible,
        metalChoicesVisible: selectMetalChoicesVisible,
        metal: selectMetal,
        thickness: selectThickness,
        quantityChoicesVisible: selectQuantityChoicesVisible,
        isCustomQuote: selectIsCustomQuote
    }
)
 
function mapDispatchToProps(dispatch) {
    return {
        setUnitsInput: (units) => dispatch(setUnitsInput(units)),
        setMetalChoicesVisible: (bool) => dispatch(setMetalChoicesVisible(bool)),
        setFileLength: (height) => dispatch(setFileLength(height)),
        setFileWidth: (width) => dispatch(setFileWidth(width)),
        setQuantityChoicesVisible: (bool) => dispatch(setQuantityChoicesVisible(bool)),
        handleDimsChange: () => dispatch(handleDimsChange()),
        setInputErr: (bool) => dispatch(setInputErr(bool)),
        setThicknessChoicesVisible: (bool) => dispatch(setThicknessChoicesVisible(bool))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OverviewSection);
// takes in the custom quantity chosen by user(number), and the current quantity choices (object)
// returns the correct save rate for custom quantities not in the current quantity choices
// ex: if curr choices are only 2 and 5, 10, custom quantity choices 2-4 will be at the 2 rate, 5-9 at the 5 rate
export function returnSaveRate(customQuantity, choicesObj={}) {
    let choicesArr = [];
    choicesArr= Object.keys(choicesObj);
    const sortedChoices = choicesArr.sort((a, b) => parseFloat(a) > parseFloat(b) ? 1 : -1).map(Number);
    let lastChoice = null;
    if (choicesArr.length > 0) {
        lastChoice = parseFloat(sortedChoices[sortedChoices.length - 1]);
    }
    if (typeof(customQuantity) === 'string') {
        customQuantity = parseFloat(customQuantity);
    }
    // make sure custom Quantity is in between the current choice range and not a current choice
    if (customQuantity === 1 || lastChoice === null) return null;
    if (sortedChoices.includes(customQuantity)) 
        return formatRate(choicesObj[customQuantity.toString()]);
    if (customQuantity > lastChoice) 
        return formatRate(choicesObj[lastChoice.toString()]);

    for (let i = 0; i < sortedChoices.length; i++) {
        const curr = sortedChoices[i];
        const next = sortedChoices[i + 1];
        if (customQuantity > curr && customQuantity < next) {
            return formatRate(choicesObj[curr.toString()])
        }
    }
}


function formatRate(string) {
    if (!string) return null
    let num = string.split('.')[1];
    if (num[0] === "0") {
        return 'Save ' + num[1] + '%'
    } else {
        return 'Save ' + num + '%'
    }
}








// // apiData only has perimeter and thumbnail
// // metal is the whole metal object
// // quantity is the chosen quantity
// export function returnPrice(perimeter, metal, quantity, thickness) {


//     -----FORMULAS------
//     (Total Perimeter)/(Cutting Speed)+ (Total Perimeter)/(Evaporating Speed)
//     (Total Perimeter)/(Cutting Speed)

//     const choicesArr    = Object.keys(metal.quantityChoices);
//     const sortedChoices = choicesArr.sort((a, b) => parseFloat(a) > parseFloat(b) ? 1 : -1).map(Number);
//     const lastChoice    = parseFloat(sortedChoices[sortedChoices.length - 1]);
//     let totalLength     = parseFloat(perimeter / 1000); // L in meters
//     let cutSpeed, CUT_TIME, BASE_PRICE;
//     for (let i = 0; i < metal.table.length; i++) {
//         if (metal.table[i][0] === thickness) {
//             cutSpeed = parseFloat(metal.table[i][1]);
//             if (metal.name === "Stainless Steel" && metal.table[i][2] !== undefined) { // replace with ss_arr
//                 evapSpeed = parseFloat(metal.table[i][2]);
//             }
//         }
//     }
//     set time for all cases
//     CUT_TIME   = Math.ceil(round((totalLength / cutSpeed), 4));
//     BASE_PRICE = round((CUT_TIME * 2), 2);

//     if (evapSpeed !== undefined) {
//         CUT_TIME = Math.ceil(round(((totalLength / cutSpeed) + (totalLength / evapSpeed)), 4));
//     } else {
//         CUT_TIME = Math.ceil(round((totalLength / cutSpeed), 4));
//     }


//     ensure correct data type for quantity
//     if (typeof(quantity) === 'string') {
//         quantity = parseFloat(quantity);
//     }


//     if (quantity === null || quantity === 1) {
//         return round(BASE_PRICE, 2);
//     } else if (sortedChoices.includes(quantity)){
//         return getSubtotal(BASE_PRICE, quantity, metal);
//     } else if (quantity > lastChoice) {
//         return getSubtotal(BASE_PRICE, quantity, metal, lastChoice);
//     } else {
//         let choice;
//         for (let i = 0; i < sortedChoices.length; i++) {
//             const curr = sortedChoices[i];
//             const next = sortedChoices[i + 1];
//             if (quantity > curr && quantity < next) {
//                 choice = curr;
//             }
//             break;
//         }
//         return getSubtotal(BASE_PRICE, quantity, metal, choice);
//     }
// }


// export function returnBasePrice(perimeter, metal, thickness) {
//     const choicesArr    = Object.keys(metal.quantityChoices);
//     const sortedChoices = choicesArr.sort((a, b) => parseFloat(a) > parseFloat(b) ? 1 : -1).map(Number);
//     let totalLength     = parseFloat(perimeter / 1000); // L in meters
//     let cutSpeed, evapSpeed, CUT_TIME, BASE_PRICE;
//     for (let i = 0; i < metal.table.length; i++) {
//         if (metal.table[i][0] === thickness) {
//             cutSpeed = parseFloat(metal.table[i][1]);
//             if (metal.name === "Stainless Steel" && metal.table[i][2] !== undefined) { // replace with ss_arr
//                 evapSpeed = parseFloat(metal.table[i][2]);
//             }
//         }
//     }
// //     set time for stainless steel brushed etc.
//     if (evapSpeed !== undefined) {
//         CUT_TIME = Math.ceil(round(((totalLength / cutSpeed) + (totalLength / evapSpeed)), 4));
//     } else {
//         CUT_TIME = Math.ceil(round((totalLength / cutSpeed), 4));
//     }
//     BASE_PRICE = CUT_TIME * 2;
//     return round(BASE_PRICE, 2);
// }




































import { ADMIN_ACTIONS } from './actionTypes';
const INITIAL_STATE = {
    editLocation: '',
    activeButtonName: '',
    pricingVars: {}
} 

function adminReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case ADMIN_ACTIONS.RESET_ADMIN:
            return {
                ...INITIAL_STATE
            }
        case ADMIN_ACTIONS.SET_EDIT_LOCATION:
            return {
                ...state,
                editLocation: action.payload
            }
        case ADMIN_ACTIONS.SET_ACTIVE_BTN_NAME:
            return {
                ...state,
                activeButtonName: action.payload
            }
        case ADMIN_ACTIONS.SET_PRICING_VARS:
            return {
                ...state,
                pricingVars: action.payload
            }
        default:
            return {
                ...state
            }
    }
}
export default adminReducer;

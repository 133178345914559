import { createSelector } from 'reselect';
import { round } from '../../service/helpers';

const orderState = rootState => rootState.orderState;
// const INITIAL_STATE = {
//     nonUserOrder: {
//         merchantId: "",
//         sessionId: "",
//         orderId: "",
//         firstName: "",
//         lastName: "",
//         email: "",
//         phone: "",
//         company: "",
//         note: "",
//     },
//     userOrder: {
//         merchantId: "",
//         sessionId: "",
//         orderId: "",
//         firstName: "",
//         lastName: "",
//         email: "",
//         phone: "",
//         company: "",
//         note: ""
//     },
//     cart: [],
//     priceData: {},
//     cartItems: 0,
//     checkoutModalStarted: false,
//     customQuoteFormData: null,
// }   


// _____________________formData_____________________//
export const selectFormData = createSelector(
    [orderState],
    orderState => orderState.customQuoteFormData
);



// _____________________cart_____________________//
export const selectCart = createSelector(
    [orderState],
    orderState => orderState.cart
);

// _____________________whole state_____________________//
export const selectOrderState = createSelector(
    [orderState],
    orderState => orderState
);

// _____________________cartItems_____________________//
export const selectCartItems = createSelector(
    [orderState],
    orderState => parseFloat(orderState.cartItems.toFixed(2))
);


// _____________________totalPrice_____________________//
export const selectCartSubtotal = createSelector(
    [orderState],
    (orderState) => {
        let subTotal = 0;        
        orderState.cart.forEach(item => {
            subTotal = subTotal + parseFloat(item.finalPrice)
        });
        return round(subTotal, 2);
    }
);


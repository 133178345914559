/* eslint-disable react-hooks/exhaustive-deps, no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Button, Form } from 'semantic-ui-react';
import { socket } from '../../../service/socket';
import axios from 'axios';
import { withRouter} from 'react-router-dom';
import BasicMotionDiv from '../../reusable/BasicMotionDiv'
import s from './Login.module.scss';

function Login({ history }) {

    const [email, setEmail]       = useState('');
    const [pwd, setPwd]           = useState('');
    const [loginLoading, setLoginLoading] = useState(false);

    useEffect(() => {
        socket.on('login success', data => history.push('/login'));
    }, []);

    function handleOnChange(event) {
        const { name, value } = event.target;
        switch (name) {
            case "email":
                setEmail(value);
                return
            case "pwd":
                setPwd(value);
                return
            default:
                return
        }
    }
    function handleSubmit(event) {
        event.preventDefault();
        setLoginLoading(true);
        axios
        .post('/login', {email, pwd})
        .then(data => {
            alert("successfully sent credentials");
        }).catch(e => {
            console.log(e);
            alert("error sending credentials");
        });
    }

    function handleSignUpSubmit(event) {

    }

    return (
        <BasicMotionDiv className={s.wrapper}>
            <div className={s.form}>
                <h1 className={s.title}>I already have an account</h1>
                <h1 className={s.subtitle}>Sign-in with your email and password</h1>
                <Form onSubmit={handleSubmit}>
                    <Form.Field>
                        <label>Email</label>
                        <input type='email' name='email' placeholder='email address' onChange={handleOnChange} required/>
                    </Form.Field>
                    <Form.Field>
                        <label>Password</label>
                        <input type="password" name='pwd' placeholder='password' onChange={handleOnChange} required/>
                    </Form.Field>
                    <Button type='submit' loading={loginLoading}>Submit</Button>
                </Form>
            </div>

            <div className={s.form}>
                <h1 className={s.title}>I do not have an account</h1>
                <h1 className={s.subtitle}>Sign-up with your email and password</h1>
                <Form onSubmit={handleSignUpSubmit}>
                    <Form.Group>
                        <Form.Field className={s.field}>
                            <label>Email</label>
                            <input type='email' name='newEmail' placeholder='email address' onChange={handleOnChange} required/>
                        </Form.Field>
                        <Form.Field className={s.field}>
                            <label>Password</label>
                            <input type="password" name='newPwd' placeholder='password' onChange={handleOnChange} required/>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field className={s.field}>
                            <label>First Name</label>
                            <input type="text" name='fName' placeholder='first name' onChange={handleOnChange} required/>
                        </Form.Field>
                        <Form.Field className={s.field}>
                            <label>Last Name</label>
                            <input type="text" name='lName' placeholder='last name' onChange={handleOnChange} required/>
                        </Form.Field>
                    </Form.Group>
                    <Form.Field>
                        <label>Company Name</label>
                        <input type="text" name='company' placeholder='Acme SAL' onChange={handleOnChange} required/>
                    </Form.Field>
                    <Button type='submit'>Submit</Button>
                </Form>
            </div>
        </BasicMotionDiv>
    )
}
 
export default withRouter(Login);
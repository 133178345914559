import React from 'react'
import { Table, Icon } from 'semantic-ui-react'
import { decreaseItemQty, increaseItemQty, removeFromCart } from '../../../../redux/order-state/orderActions';
import { connect } from 'react-redux';
import { USD } from '../../../../service/clientConstants';

function CartItem({ currency, basePrice, thickness, discount, removeFromCart, fileDims,
    fileData, finalQuantity, metal, finalPrice, idx, decreaseItemQty, increaseItemQty}) {

    const leftArrow = {marginRight: '8px', cursor: 'pointer'};
    const rightArrow = {marginLeft: '8px', cursor: 'pointer'};

    return (
        <Table.Row>
            <Table.Cell textAlign='center'>{fileData.name}</Table.Cell>
            <Table.Cell textAlign='center'>{fileDims}</Table.Cell>
            <Table.Cell textAlign='center'>{metal.name}</Table.Cell>
            <Table.Cell textAlign='center'>{thickness}</Table.Cell>
            <Table.Cell textAlign='center'>
                <span style={leftArrow} onClick={() => decreaseItemQty(idx)}>&#10094;</span>
                    {finalQuantity}
                <span style={rightArrow} onClick={() => increaseItemQty(idx)}>&#10095;</span>
            </Table.Cell>
            <Table.Cell textAlign='center'>{currency === USD ? `$${basePrice}` : `LBP ${parseFloat(basePrice).toLocaleString()}`}</Table.Cell>
            <Table.Cell textAlign='center' style={{color: 'green', fontStyle: 'italic'}}>
                {
                    parseFloat(finalPrice) <= 10.00 ? (
                        null
                    ) : (discount)
                }
            </Table.Cell>
            <Table.Cell textAlign='center'>{currency === USD ? `$${finalPrice}` : `LBP ${parseFloat(finalPrice).toLocaleString()}`}</Table.Cell>
            <Table.Cell textAlign='center'>
                <Icon name='remove' style={{cursor: 'pointer'}} onClick={() => removeFromCart(idx)}/>
            </Table.Cell>
        </Table.Row>
    )
}
function mapDispatchToProps(dispatch) {
    return {
        decreaseItemQty: (idx) => dispatch(decreaseItemQty(idx)),
        increaseItemQty: (idx) => dispatch(increaseItemQty(idx)),
        removeFromCart: (idx) => dispatch(removeFromCart(idx))
    }
}

export default connect(null, mapDispatchToProps)(CartItem);
import React, { useState, useEffect } from 'react'
import { Table, Button, Input } from 'semantic-ui-react';
import axios from 'axios';
import { FAIL_CONFIG, SUCCESS_CONFIG } from '../../../../../service/notifications';
import { BACKEND } from '../../../../../service/clientConstants'
import { store } from 'react-notifications-component';

// receives an index from map function and a pair from metal table
// idx  : number
// pair : Array [thickness (string), speed (number), maxLength (number), maxWidth(number)]
// metal: String
// updateParentComponent: fn
function MetalTableRow({ idx, pair, metal, updateParentComponent }) {
    //const [evapSpeed, setEvapSpeed]             = useState("");
    //const [evapValid, setEvapValid]             = useState(true);

    const [updatedSpeed, setUpdatedSpeed]       = useState("");
    const [speedInputValid, setSpeedInputValid] = useState(true);

    const [updatedLength, setUpdatedLength]     = useState("");

    const [updatedWidth, setUpdatedWidth]       = useState("");


    const [inputReady, setInputReady]           = useState(false);
    const [dataPair, setDataPair]               = useState(pair);
    const re = /^\d+(\.\d{1,9})?$/;
    const numRegex                                      = /^\d+$/;

    const [width, setWidth]                    = useState(window.innerWidth);

    useEffect(() => {
        if (pair) {
            setDataPair(pair);
        }
    }, [pair]);

    // useEffect(() => {
    //     if (updatedSpeed || evapSpeed) {
    //         setHasInput(true);
    //     } else {
    //         setHasInput(false);
    //         setInputReady(false);
    //     }

    //     // handle case with evap speed
    //     if (dataPair[2] !== undefined) {
    //         if (hasInput) {
    //             if (evapValid && speedInputValid) {
    //                 setInputReady(true);
    //             } else {
    //                 setInputReady(false);
    //             }
    //         } else {
    //             setInputReady(false);
    //         }
    //     } else {
    //         if (hasInput) {
    //             if (updatedSpeed && speedInputValid) {
    //                 setInputReady(true);
    //             } else {
    //                 setInputReady(false);
    //             }
    //         } else {
    //             setInputReady(false);
    //         }
    //     }

        
    // }, [evapSpeed, updatedSpeed])

    


    useEffect(() => {
        if (updatedSpeed || updatedLength || updatedWidth) {
            setInputReady(true);
        } else {
            setInputReady(false);
        }
    }, [updatedSpeed, updatedWidth, updatedLength]);

    function handleUpdateClick() {
        let data = {
            metalName: metal, 
            oldPair: pair,
            newPair: [pair[0], updatedSpeed, updatedLength, updatedWidth]
        }
        

        
        //check if both strings are filled out, AND if there's only 1 char in each
        if (inputReady) {
            axios
            .patch((BACKEND + '/admin/tables'), data)
            .then(res => {
                if (res.data.success) {
                    setUpdatedSpeed("");
                    setUpdatedLength("");
                    setUpdatedWidth("");
                    //setEvapSpeed("");
                    setDataPair(res.data.updatedPair);
                    updateParentComponent(res.data.metal);
                    SUCCESS_CONFIG.message = res.data.msg;
                    store.addNotification(SUCCESS_CONFIG);
                } else {
                    FAIL_CONFIG.message = res.data.msg + '\nPlease contact the administrator';
                    store.addNotification(FAIL_CONFIG);
                }
            })
            .catch(e => console.log(e)); 
        } else {
            FAIL_CONFIG.message = "Please make sure table input is valid.";
            store.addNotification(FAIL_CONFIG);
        }
    }

    function handleDeleteClick() {
        let confirmed = window.confirm('Are you sure you want to delete this whole table entry? This action will immediately affect the quoting formula.');
        let config = { 
            data: {
                metalType: metal, 
                entry: dataPair 
            }
        }
        if (confirmed) {
            axios
            .delete((BACKEND + '/admin/tables'), config)
            .then(res => {
                if (res.data.success) {
                    updateParentComponent(res.data.metal);
                } else {
                    FAIL_CONFIG.message = res.data.msg + '\nPlease contact the administrator';
                    store.addNotification(FAIL_CONFIG);
                }
            })
            .catch(e => console.log(e));
        }
    }

    function handleChange(e) {
        const { name, value } = e.target;
        if (name === "updatedSpeed") {
            setUpdatedSpeed(value);
            if (!value) return setSpeedInputValid(true);
            if (!re.test(value)) {
                return setSpeedInputValid(false);
            } else {
                setSpeedInputValid(true); 
            }
        } else if (name === "updatedLength") {
            if (!value) {
                setUpdatedLength(value);
            }
            if (numRegex.test(value)) {
                setUpdatedLength(value);
            } 
        } else if (name === "updatedWidth") {
            if (!value) {
                setUpdatedWidth(value);
            }
            if (numRegex.test(value)) {
                setUpdatedWidth(value);
            } 
        }
    }

    return (
        <Table.Row key={idx}>
            <Table.Cell>
                <table>
                    <tbody>
                        <Table.Row>
                            <Table.Cell>
                                {
                                    width < 768 ? (
                                        dataPair[0].split("mm")[0] + " (thickness)"
                                    ) : (
                                        dataPair[0].split("mm")[0]
                                    )
                                }
                            </Table.Cell>
                        </Table.Row>
                    </tbody>
                </table>
                
            </Table.Cell>

            <Table.Cell>
                <table>
                    <tbody>
                    <Table.Row>
                        <Table.Cell>
                            {
                                width < 768 ? (
                                        dataPair[1] + " (speed)"
                                    ) : (
                                        dataPair[1]
                                    )
                            }
                        </Table.Cell>
                            <Table.Cell>
                                <Input 
                                    name="updatedSpeed"
                                    value={updatedSpeed}
                                    type="text" 
                                    placeholder="new value"
                                    onChange={handleChange}
                                    error={!speedInputValid}
                                />
                            </Table.Cell>
                        
                        {/* {
                            dataPair[2] === undefined ? (
                                <>
                                    <Table.Cell>
                                        <Button color="yellow" onClick={handleUpdateClick} disabled={!inputReady || !hasInput}>Update</Button>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Button onClick={handleDeleteClick}>Delete Row</Button>
                                    </Table.Cell>
                                </>
                                
                            ) : (null)
                        } */}
                    </Table.Row>
                    </tbody>
                </table>
                
            </Table.Cell>

            <Table.Cell>
                <table>
                    <tbody>
                        <Table.Row>
                            <Table.Cell>
                                {
                                    width < 768 ? (
                                        dataPair[2] + " (width[mm])"
                                    ) : (
                                        dataPair[2]
                                    )
                                }
                            </Table.Cell>
                            <Table.Cell>
                                <Input 
                                    name="updatedLength" 
                                    value={updatedLength}
                                    type="text" 
                                    placeholder="new value"
                                    onChange={handleChange}    
                                />
                            </Table.Cell>
                        </Table.Row>
                    </tbody>
                </table>
                
            </Table.Cell>

            <Table.Cell>
                <table>
                    <tbody>
                        <Table.Row>
                            <Table.Cell>
                                {
                                    width < 768 ? (
                                        dataPair[3] + " (length[mm])"
                                    ) : (
                                        dataPair[3]
                                    )
                                }
                            </Table.Cell>
                            <Table.Cell>
                                <Input 
                                    name="updatedWidth"
                                    value={updatedWidth}
                                    type="text" 
                                    placeholder="new value"
                                    onChange={handleChange}    
                                />
                            </Table.Cell>
                        </Table.Row>
                    </tbody>
                </table>
                
            </Table.Cell>

            <Table.Cell>
                <Button color="red" onClick={handleDeleteClick}>Delete Row</Button>
                <Button disabled={!inputReady || !speedInputValid} color="yellow" onClick={handleUpdateClick}>Update Row</Button>
            </Table.Cell>

            {/* {
                dataPair[2] !== undefined ? (
                    <Table.Cell>
                        <table>
                            <tbody>
                            <Table.Row>
                                <Table.Cell>{dataPair[2]}</Table.Cell>
                                    <Table.Cell>
                                        <Input 
                                            name="evapSpeed" 
                                            type="text" 
                                            placeholder="new value"
                                            value={evapSpeed}
                                            onChange={handleChange}    
                                        />
                                    </Table.Cell>
                                <Table.Cell>
                                    <Button color="yellow" onClick={handleUpdateClick} disabled={!inputReady || !hasInput}>Update</Button>
                                </Table.Cell>
                                <Table.Cell>
                                    <Button onClick={handleDeleteClick}>Delete Row</Button>
                                </Table.Cell>
                            </Table.Row>
                            </tbody>
                        </table>
                        
                    </Table.Cell>
                    
                ) : (null)
            } */}
        </Table.Row>
    )
}
 
export default MetalTableRow;
import React from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectMetalsState } from '../../../../../redux/app-state/appSelectors';
import MetalSelector from '../MetalSelector';
import s from './MetalChoices.module.scss';
import uuid from 'react-uuid';
import { motion } from 'framer-motion';
import { WORKFLOW_TRANSITION } from '../../../../../service/clientConstants';
function MetalChoices({ metalsState }) {

    const metals = metalsState.filter(metal => metal.table.length > 0).sort((a, b) => a.name.localeCompare(b.name));
    return (
        <motion.div variants={WORKFLOW_TRANSITION} initial="hidden" exit="exit" animate="visible" className={s.metalTypesWrapper}>
            <div className={s.choices}>
            {   
                metals.map( metal => <MetalSelector key={uuid()} metal={metal}>{metal.name}</MetalSelector>)
            }
            </div>
        </motion.div>
    )
}

const mapStateToProps = createStructuredSelector(
    {
        metalsState: selectMetalsState
    }
)
export default connect(mapStateToProps)(MetalChoices);
import { APP_ACTIONS } from './actionTypes';



export function setFromButtonClick(bool) {
    return {
        type: APP_ACTIONS.SET_FROM_BTN_CLICK,
        payload: bool
    }
}


export function updateSiteText() {
    return {
        type: APP_ACTIONS.UPDATE_SITE_TEXT,
    }
}


export function setUpdatedSiteText(text) {
    return {
        type: APP_ACTIONS.SET_UPDATED_SITE_TEXT,
        payload: text
    }
}


export function updateMetalsState() {
    return {
        type: APP_ACTIONS.UPDATE_METALS_STATE,
    }
}


export function addUpdatedMetal(m) {
    return {
        type: APP_ACTIONS.ADD_UPDATED_METAL,
        payload: m
    }
}



export function setDropzoneText(obj) {
    return {
        type: APP_ACTIONS.SET_DROPZONE_TEXT,
        payload: obj
    }
}


export function setHomePageInfo(obj) {
    return {
        type: APP_ACTIONS.SET_HOMEPAGE_INFO,
        payload: obj
    }
}



export function setAdminLogin(bool) {
    return {
        type: APP_ACTIONS.SET_ADMIN_LOGIN,
        payload: bool
    }
}

export function setIsOnline(bool) {
    return {
        type: APP_ACTIONS.SET_IS_ONLINE,
        payload: bool
    }
}


export function setMetalsState(array) {
    return {
        type: APP_ACTIONS.SET_METALS_STATE,
        payload: array
    }
}


export function setStaticSiteText(obj) {
    return {
        type: APP_ACTIONS.SET_STATIC_SITE_TEXT,
        payload: obj
    }
}


export function setStateLoaded(bool) {
    return {
        type: APP_ACTIONS.SET_STATE_LOADED,
        payload: bool
    }
}


export function setShowDollarsOnly(bool) {
    return {
        type: APP_ACTIONS.SET_SHOW_DOLLARS_ONLY,
        payload: bool
    }
}
import React from 'react'
import QuantityChoicesTable from './sub-components/QuantityChoicesTable';
import s from './ManageChoices.module.scss';

function ManageChoices() {

    return (
        <div className={s.wrapper}>
            <QuantityChoicesTable/>
        </div>
    )
}
 
export default ManageChoices;
import React, { useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import DragAndDropText from "./DragAndDropText";
import PreviewComponent from "./PreviewComponent";
import {
    setFileData,
    setFile,
    setPercentLoaded,
    setIsCustomQuote,
} from "../../../redux/quote-workflow/quoteWorkflowActions";
import { selectFile } from "../../../redux/quote-workflow/quoteWorkflowSelectors";
import { selectUserLoggedIn } from "../../../redux/app-state/appSelectors";
import { setResultsModalWorkflowStarted } from "../../../redux/results-modal/resultsModalActions";
import { BACKEND } from "../../../service/clientConstants";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { motion } from 'framer-motion'
import { BASIC_TRANSITION } from '../../../service/clientConstants'
import uuid from "uuid";
import axios from "axios";
import s from "./NewDropzone.module.scss";


function NewDropzone({ file, setFile, setFileData, setResultsModalWorkflowStarted, setPercentLoaded, userLoggedIn, setIsCustomQuote,}) {
    useEffect(() => {
        document.getElementById('mainHeader').scrollIntoView();
    }, []);
    
    const autoFinish = () => {
        setIsCustomQuote(true);
        setResultsModalWorkflowStarted(true);
        setPercentLoaded(100);
    };
    const onDrop = useCallback((files) => {
        // set the rdx file data
        let id = uuid();
        let fileName = files[0].name;
        setFile(files[0]);
        setFileData({ name: fileName, uuid: id });

        // filter out bad files
        if (files[0].size > 1000000) {
            autoFinish();
            return;
        }
        if (
            fileName.includes(".ai") ||
            fileName.includes(".eps") ||
            fileName.includes(".cdr")
        ) {
            autoFinish();
            return;
        }

        // create form
        const formData = new FormData();

        // file must be appended at the end for multer to access req.body
        formData.append("uuid", id);
        formData.append("userLoggedIn", userLoggedIn);
        formData.append("fileFromClient", files[0]);
        const url = BACKEND + "/uploads";
        axios
            .post(url, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((res) =>{ let x = res; })
            .catch((e) => {
                console.log(e);
                // console.log(e.response.data);
                // console.log(e.repsonse.status);
                // console.log(e.response.headers);
            });
    }, []);
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: ".pdf,.dwg,.dxf,.ai,.eps,.cdr",
    });
    return (
        <motion.div 
            {...getRootProps()} 
            className={s.outside}
            variants={BASIC_TRANSITION}
            initial="hidden"
            exit="exit"
            animate="visible"
        >
            {file ? (
                <>
                    <PreviewComponent />
                </>
            ) : (
                <>
                    <input {...getInputProps()} />
                    <DragAndDropText />
                </>
            )}
        </motion.div>
    );
}

const mapStateToProps = createStructuredSelector({
    file: selectFile,
    userLoggedIn: selectUserLoggedIn,
});

function mapDispatchToProps(dispatch) {
    return {
        setFile: (file) => dispatch(setFile(file)),
        setFileData: (dataObj) => dispatch(setFileData(dataObj)),
        setResultsModalWorkflowStarted: (bool) =>
            dispatch(setResultsModalWorkflowStarted(bool)),
        setPercentLoaded: (num) => dispatch(setPercentLoaded(num)),
        setIsCustomQuote: (bool) => dispatch(setIsCustomQuote(bool)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewDropzone);

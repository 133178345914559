import React from 'react'
import { connect } from 'react-redux';
import { setMetalType } from '../../../../redux/quote-workflow/quoteWorkflowActions';
import { setMetalChoicesVisible, setThicknessChoicesVisible } from '../../../../redux/results-modal/resultsModalActions'
import styles from './MetalSelector.module.scss';

// rectangular selection for each metalName type. children refers to what is inbetween html tags
function MetalNameSelector({ metal, setMetalType, setMetalChoicesVisible, children, setThicknessChoicesVisible}) {
    let hexColor = '';
    if (metal.name.toUpperCase() === 'ALUMINUM') {
        hexColor = '#cccccc';
    } else if (metal.name.toUpperCase() === 'BRASS') {
        hexColor = '#f5ce62';
    } else if (metal.name.toUpperCase() === 'STAINLESS STEEL') {
        hexColor = '#adadad';
    } else if (metal.name.toUpperCase() === 'COPPER') {
        hexColor = '#c56a0d';
    } else if (metal.name.toUpperCase() === 'STEEL') {
        hexColor = '#8a8580';
    }

    let vettedThumbnailUrl;
    if (metal.thumbnailUrl) {
        vettedThumbnailUrl = metal.thumbnailUrl;
    }

    if (vettedThumbnailUrl && vettedThumbnailUrl.includes(' ')) {
        vettedThumbnailUrl = metal.thumbnailUrl.replace(/ /g, '%20');
    }
    const bgStyle = {
        background: vettedThumbnailUrl ? `url(${vettedThumbnailUrl})` : hexColor,
        backgroundSize: 'cover'
    }

    // automatically moves user to next display, sets metalName type
    function handleClick() {
        setMetalType(metal);
        setMetalChoicesVisible(false);
        setThicknessChoicesVisible(true);
    }

    return (
        <div className={styles.wrapper} onClick={handleClick}>
            <div style={bgStyle} className={styles.colorPrev}/>
            <div className={styles.text}>{children}</div>
        </div>
    )
}


function mapDispatchToProps(dispatch) {
    return {
        setMetalType: (string) => dispatch(setMetalType(string)),
        setMetalChoicesVisible: (bool) => dispatch(setMetalChoicesVisible(bool)),
        setThicknessChoicesVisible: (bool) => dispatch(setThicknessChoicesVisible(bool))

    }
}
 
export default connect(null, mapDispatchToProps)(MetalNameSelector);
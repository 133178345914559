module.exports = {
    SUCCESS_CONFIG: {
        id: "",
        title: "Success",
        message: "",
        type: "success",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "bounceIn"],
        animationOut: ["animated", "bounceOut"],
        dismiss: {
            showIcon: true,
            duration: 5000
        }
    },
    FAIL_CONFIG: {
        id: "",
        title: "There was a problem.",
        message: "",
        type: "danger",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "bounceIn"],
        animationOut: ["animated", "bounceOut"],
        dismiss: {
            showIcon: true,
            duration: 10000
        },
    },
    ADD_CARD_RETRY: {
        title: "That name is taken.",
        message: "That card or metal has already been created; please choose another or edit the existing card.",
        type: "warning",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "bounceIn"],
        animationOut: ["animated", "bounceOut"],
        dismiss: {
            showIcon: true,
            duration: 10000
        },
    },
    ADD_TO_CART: {
        id: "",
        title: "Successfully added to cart.",
        message: "Go to cart, or quote another file.",
        type: "success",
        insert: "bottom",
        container: "top-center",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            showIcon: true,
            duration: 4000
        }
    },
    RETRY_CONFIG: {
        title: "Network issue",
        message: "Please re-submit the file and try again.",
        type: "warning",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "bounceIn"],
        animationOut: ["animated", "bounceOut"],
        dismiss: {
            showIcon: true,
            duration: 10000
        }
    },
    INPUT_MSG_CONFIG: {
        title: "Just so you know...",
        message: "",
        type: "warning",
        insert: "top",
        container: "top-center",
        animationIn: ["animated", "bounceIn"],
        animationOut: ["animated", "bounceOut"],
        dismiss: {
            showIcon: true,
            duration: 15000
        }
    }
}
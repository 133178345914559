import React from 'react'
import { Modal } from 'react-responsive-modal';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import SubmissionForm from './items/SubmissionForm';
import { selectCustomQuoteModalStarted } from '../../../redux/custom-quote-modal/customQuoteSelectors';
import { setCustomQuoteModalStarted } from '../../../redux/custom-quote-modal/customQuoteActions';
import s from './CustomQuoteModal.module.scss';
 
function CustomQuoteModal({ customQuoteModalStarted, setCustomQuoteModalStarted }) {

    function handleOnClose() {
        setCustomQuoteModalStarted(false);
    }

    return (
        <Modal open={customQuoteModalStarted} onClose={handleOnClose} center> 
            <h1 className={s.title}>Custom Quote Form</h1>
            <SubmissionForm/>
        </Modal>
    )
}
 
const mapStateToProps = createStructuredSelector(
    {
        customQuoteModalStarted: selectCustomQuoteModalStarted
    }
);

function mapDispatchToProps(dispatch) {
    return {
        setCustomQuoteModalStarted: (bool) => dispatch(setCustomQuoteModalStarted(bool))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomQuoteModal);

module.exports = {
    BACKEND: "http://68.183.218.18:5000",
    FRONTEND: "http://68.183.218.18:5001",
    BASIC_TRANSITION: {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                duration: 1
            }
        },
        exit: { 
            opacity: 0,
            transition: {
                duration: 0.2
            }
        }
    },
    WORKFLOW_TRANSITION: {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.4
            }
        },
        exit: { 
            opacity: 0,
            transition: {
                duration: 0.2
            }
        }
    },
    ADMIN_LOGIN_TRANSITION: {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                duration: 1
            }
        },
        exit: { 
            opacity: 0,
            transition: {
                duration: 0.1
            }
        }
    },
    HOME_PAGE_TRANSITION: {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                duration: 1.25
            }
        },
        exit: {
            opacity: 0,
            transition: {
                duration: 0.22,
            }
        }
    },
    DROPZONE_TITLE_TRANSITION: {
        hidden: {x: "-80%", opacity: 1},
        visible: {
            x: 0,
            transition: {
                type: "spring",
                bounce: 0.25,
                duration: 1
            }
        },
        exit: {
            opacity: 0,
            transition: {
                duration: 0.22
            }
        }
    },
    DROPZONE_OTHER_TRANSITION: {
        hidden: { y: "70%", opacity: 0 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5,
                delay: 1.33
            },
        },
        exit: {
            opacity: 0,
            transition: {
                duration: 0.22
            }
        }
    },
    ADMIN_PANEL_TRANSITION: {
        hideMenu: { 
            right: "-1000px",
            transition: {
                duration: 0.2,
            }
        },
        showMenu: { 
            right: "0px",
            transition: {
                duration: 0.2,
            }
        },
    },
    FILE_UPLOAD_KEY: "_57U3xiqoWOVG91IvBK20Qq", // must match backend as well
    GUIDELINES_TOOLBAR: 'undo redo formatselect | link bold italic backcolor | hr image | alignleft aligncenter alignright alignjustify | bullist numlist',
    METAL_PAGE_TOOLBAR: 'undo redo formatselect | link bold italic backcolor | image | bullist numlist',
    IMG_CARD_TOOLBAR: '| image |',
    BTM_CARD_TOOLBAR: '| link bold italic | hr image | formatselect bullist numlist |',
    CUSTOM_CARD_TITLE: '| bold italic hr |',
    SANITIZE_OPTS: {
        allowedSchemesAppliedToAttributes: ['src', 'cite' ],
        allowedTags: [
            "address", "article", "aside", "footer", "header", "h1", "h2", "h3", "h4",
            "h5", "h6", "hgroup", "main", "nav", "section", "blockquote", "dd", "div",
            "dl", "dt", "figcaption", "figure", "hr", "li", "main", "ol", "p", "pre",
            "ul", "a", "abbr", "b", "bdi", "bdo", "br", "cite", "code", "data", "dfn",
            "em", "i", "kbd", "mark", "q", "rb", "rp", "rt", "rtc", "ruby", "s", "samp",
            "small", "span", "strong", "sub", "sup", "time", "u", "var", "wbr", "caption",
            "col", "colgroup", "table", "tbody", "td", "tfoot", "th", "thead", "tr", "img", "hr"
        ],
        allowedAttributes: {
            '*': ['style', 'href','src', 'width', 'height'],
        },
        allowedStyles: {
            '*': {
                'text-align': [/^left$/, /^right$/, /^center$/],
                'background-color': [/#([a-fA-F0-9]{3}){1,2}\b/]
            }
        }
    },
    FOOTER_LINKS_INITIAL: {
        facebook: '', instagram: '', twitter: '', youtube: '',
        pinterest: '', linkedin: '', tiktok: ''
    },
    FOOTER_SHOW_INITIAL: {
        facebook: false, instagram: false, twitter: false, youtube: false,
        pinterest: false, linkedin: false, tiktok: false
    },
    METAL_FILLER: {
        cardImgHtml: "", name: "", cardBottomHtml: "",
    },
    USD: "USD",
    LBP: "LBP",
    ADMIN_PAGE_CONSTANTS: {
        MANAGE_ADMIN: "MANAGE_ADMIN",
        MANAGE_QUOTE_PAGE: "MANAGE_QUOTE_PAGE",
        MANAGE_HOME_PAGE: "MANAGE_HOME_PAGE",
        MANAGE_QTY_CHOICES: "MANAGE_QTY_CHOICES",
        MANAGE_TABLES: "MANAGE_TABLES",
        MANAGE_PRICING: "MANAGE_PRICING",
        MANAGE_FOOTER: "MANAGE_FOOTER",
        SIGN_OUT: "SIGN_OUT"
    }
}

import { createSelector } from 'reselect';

const customQuoteModalState = rootState => rootState.customQuoteModalState;
// const INITIAL_STATE = {
//     customQuoteModalStarted: false,
//     formComplete: false,
// }  

// _____________________formComplete_____________________//
export const selectFormComplete = createSelector(
    [customQuoteModalState],
    customQuoteModalState => customQuoteModalState.formComplete
);


// _____________________formComplete_____________________//
export const selectCustomQuoteModalStarted = createSelector(
    [customQuoteModalState],
    customQuoteModalState => customQuoteModalState.customQuoteModalStarted
);
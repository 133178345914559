import React from 'react'
import styles from './WorkflowStartPage.module.scss';
import ResultsModal from './_results-modal/ResultsModal';
import CustomQuoteModal from './_custom-quote-modal/CustomQuoteModal';
import NewDropzone from './_elements/NewDropzone'
import { withRouter } from 'react-router-dom';
import useBodyClassChange from '../../service/helpers';
import { motion } from 'framer-motion';
import { BASIC_TRANSITION } from '../../service/clientConstants';
import { createStructuredSelector } from 'reselect';
import { selectAdminLoggedIn } from '../../redux/app-state/appSelectors'
import { connect } from 'react-redux';
function WorkflowStartPage({ adminLoggedIn }) {
    useBodyClassChange(adminLoggedIn);

    return (
        <motion.div key="workflow" exit="exit" animate="visible" initial="hidden" variants={BASIC_TRANSITION} className={styles.wrapper}>
            <NewDropzone/>
            <ResultsModal/>
            <CustomQuoteModal/>
        </motion.div>
    )
}

const mapStateToProps = createStructuredSelector(
    {
        adminLoggedIn: selectAdminLoggedIn
    }
)

export default withRouter(connect(mapStateToProps)(WorkflowStartPage));

import React, { useState, useEffect } from "react";
import styles from "./DragAndDropText.module.scss";
import { motion } from "framer-motion";
import { DROPZONE_OTHER_TRANSITION, DROPZONE_TITLE_TRANSITION  } from '../../../service/clientConstants'
import { createStructuredSelector } from "reselect";
import { selectStaticSiteText } from "../../../redux/app-state/appSelectors";
import { connect } from "react-redux";

function DragAndDropText({ staticSiteText}) {
    const [width, setWidth] = useState(window.innerWidth);    
    const updateDimensions = () => {
        setWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => {
            window.removeEventListener("resize", updateDimensions);
        }
    }, []);

    let dropzoneText;
    if (staticSiteText.dropzoneText) {
        dropzoneText = staticSiteText.dropzoneText;
    }
    return (
        <div>
            <div className={styles.topSection}>
                <motion.div key="title" variants={DROPZONE_TITLE_TRANSITION} exit="exit" initial="hidden" animate="visible" className={styles.welcome}>
                    {dropzoneText.topHeading}
                </motion.div>
                <motion.div key="text1" variants={DROPZONE_OTHER_TRANSITION} exit="exit" initial="hidden" animate="visible" className={styles.cta}>
                    {dropzoneText.cta1}
                </motion.div>
                <motion.div key="text2" variants={DROPZONE_OTHER_TRANSITION} exit="exit" initial="hidden" animate="visible" className={styles.clickAnywhere}>
                    {width > 1024 ? dropzoneText.cta2 : dropzoneText.cta3}
                </motion.div>
            </div>

            <motion.div key="fileTypes" variants={DROPZONE_OTHER_TRANSITION} exit="exit" initial="hidden" animate="visible" className={styles.btmSection}>
                <p className={styles.fileTypes}>{dropzoneText.note1}</p>
                <p className={styles.fileTypes}>{dropzoneText.note2}</p>
            </motion.div>
        </div>
    );
}
const mapStateToProps = createStructuredSelector({
    staticSiteText: selectStaticSiteText
})
export default connect(mapStateToProps)(DragAndDropText);

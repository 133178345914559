import React, { useEffect, useState, useRef } from 'react'
import { Button, Ref } from 'semantic-ui-react'
import BurgerMenu from 'react-hamburger-menu';
import { connect } from 'react-redux';
import { setAdminLogin } from '../../../../../redux/app-state/appActions';
import { setActiveBtn, setEditLocation } from '../../../../../redux/admin-state/adminActions';
import { createStructuredSelector } from 'reselect';
import { selectActiveBtnName, selectEditLocation } from '../../../../../redux/admin-state/adminSelectors';
import { ADMIN_PAGE_CONSTANTS, ADMIN_PANEL_TRANSITION } from '../../../../../service/clientConstants';
import s from './PanelButtons.module.scss';
import { motion } from 'framer-motion';

function PanelButtons({ setAdminLogin, setActiveBtn, editLocation, setEditLocation, activeButtonName  }) {
    const [burgerClicked, setBurgerClicked]   = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [width, setWidth]                   = useState(0);
    const quoteDisplayRef                     = useRef();
    const homePageDisplayRef                  = useRef();
    const footerRef                           = useRef();
    const blue                                = { backgroundColor: '#595eff'}
    const red                                 = { backgroundColor: '#d25b5b'}
    function handleBurgerClick() {
        setBurgerClicked(!burgerClicked);
        setMobileMenuOpen(!mobileMenuOpen);
    }

    // resizing window width
    function updateDims() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        updateDims();
        window.addEventListener('resize', updateDims);
    }, []);


    // button clicks
    useEffect(() => {
        if (editLocation === 'Edit Quote Display') {
            quoteDisplayRef.current.click();
            setEditLocation('');
        } else if (editLocation === 'Edit Home Page') {
            homePageDisplayRef.current.click();
            setEditLocation('');
        } else if (editLocation === 'Edit Footer') {
            footerRef.current.click();
            setEditLocation('');
        }
    }, [editLocation]);
    function handleClick(e) {
        let { name } = e.target;
        if (name === ADMIN_PAGE_CONSTANTS.SIGN_OUT) {
            let confirmed = window.confirm('Are you sure you want to sign out?');
            if (confirmed) {
                return setAdminLogin(false);
            }
        }
        if (activeButtonName === name) {
            setActiveBtn('');
        } else {
            setActiveBtn(name);
        }
        setBurgerClicked(!burgerClicked);
    }



    return (
        <>
            {
                width < 1024 && (
                    <div className={s.burgerWrap}>
                        <BurgerMenu
                            isOpen={burgerClicked}
                            menuClicked={handleBurgerClick}
                            width={18}
                            height={15}
                            strokeWidth={2}
                            rotate={0}
                            color="black"
                            borderRadius={0}
                            animationDuration={0.5}
                        />
                    </div>
                )
            }

            <motion.div 
                variants={ADMIN_PANEL_TRANSITION} 
                initial={width < 1024 ? "hideMenu" : "showMenu"}
                animate={!burgerClicked && width < 1024 ? "hideMenu" : "showMenu"}  
                className={s.btnWrapper}
            >
                { width < 1024 && <div style={{height: '5rem'}}></div> }
                <Ref innerRef={homePageDisplayRef} ><Button style={blue} content='Manage Home Page' onClick={handleClick} name={ADMIN_PAGE_CONSTANTS.MANAGE_HOME_PAGE} /></Ref>
                <Ref innerRef={quoteDisplayRef}><Button style={blue} content='Manage Quote Page' onClick={handleClick} name={ADMIN_PAGE_CONSTANTS.MANAGE_QUOTE_PAGE} /></Ref>
                <Ref innerRef={footerRef}><Button style={blue} content='Manage Footer' onClick={handleClick} name={ADMIN_PAGE_CONSTANTS.MANAGE_FOOTER} /></Ref>
                <Button content='Manage Metal Tables' style={red} onClick={handleClick} name={ADMIN_PAGE_CONSTANTS.MANAGE_TABLES} />
                <Button content='Manage Qty Choices/Discounts' style={red} onClick={handleClick} name={ADMIN_PAGE_CONSTANTS.MANAGE_QTY_CHOICES} />
                <Button content='Manage Pricing' style={red}  onClick={handleClick} name={ADMIN_PAGE_CONSTANTS.MANAGE_PRICING} />
                <Button content='Manage Admin Users' style={red} onClick={handleClick} name={ADMIN_PAGE_CONSTANTS.MANAGE_ADMIN}/>
                <Button content='Sign Out' color="grey" onClick={handleClick} name={ADMIN_PAGE_CONSTANTS.SIGN_OUT}/>
            </motion.div>
        </>
    )
}

const mapStateToProps = createStructuredSelector({
    editLocation: selectEditLocation,
    activeButtonName: selectActiveBtnName
});

function mapDispatchToProps(dispatch){
    return {
        setAdminLogin: (bool) => dispatch(setAdminLogin(bool)),
        setActiveBtn: (string) => dispatch(setActiveBtn(string)),
        setEditLocation: (string) => dispatch(setEditLocation(string))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(PanelButtons);
import React, { useState } from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectAdminLoggedIn, selectStaticSiteText } from '../../../../redux/app-state/appSelectors';
import { GUIDELINES_TOOLBAR, SANITIZE_OPTS } from '../../../../service/clientConstants';
import { formatHtml } from '../../../../service/helpers';
import RichTextEditor from '../admin_elements/RichTextEditor'
import sanitizeHtml from 'sanitize-html';
import parse from 'html-react-parser'
function HeadingTop({ staticSiteText: { materialsPage }, adminLoggedIn }) {
    const [topHtml, setTopHtml] = useState(materialsPage.top ? materialsPage.top : '');

    function updateHtml(html) {
        setTopHtml(sanitizeHtml(html, SANITIZE_OPTS))
    }

    return (
        <div>
        {
            adminLoggedIn ? (
                <RichTextEditor
                    currHtml={topHtml ? topHtml : null}
                    destination="materialsTop"
                    toolbar={GUIDELINES_TOOLBAR}
                    height='300'
                    setParentHtml={updateHtml}
                />
            ) : (null)
        }
        {
            topHtml ? parse(topHtml, {
                replace: formatHtml
            }) : (null)
        }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    adminLoggedIn: selectAdminLoggedIn,
    staticSiteText: selectStaticSiteText
})
export default connect(mapStateToProps)(HeadingTop);
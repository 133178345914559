import { createSelector } from 'reselect';

// const INITIAL_STATE = {
//     fileReadyToSubmit: true,
//     apiDataLoading: false,
//     apiData: {
//         perimeter: 0,
//         thumbnail: null
//     },
//     fileData: { name: "", uuid: "", length: "", width: "" },
//     file: null,
//     basePrice: null, // cost for 1 cut based off of formula thickness etc.
//     finalPrice: null,
//     discountRate: null,
//     unitsInput: "", //  only "cm" or "mm"
//     metal: {},
//     currency: USD,
//     thickness: "", //number in mm, string format
//     quantity: "", // otherwise will be a number
//     customQuantity: null, // also number
//     percentLoaded: 0,
//     loadingUpdate: "",
//     inputErr: false,
//     isCustomQuote: false,
//     vat: null,
//     currPrices: {},
//     prevPriceInfo: {},
//     usdPriceInfo: {}
// }

const quoteWorkflowState = rootState => rootState.quoteWorkflowState;

export const selectQty = createSelector(
    [quoteWorkflowState],
    (quoteWorkflowState) => {
        let qty       = quoteWorkflowState.quantity || quoteWorkflowState.customQuantity;
        let isCustomQty;
        if (qty === null) {
            isCustomQty = true;
        } else {
            isCustomQty = false;
        }
        return { isCustomQty, qty }
    }
)




export const selectConvertParams = createSelector(
    [quoteWorkflowState],
    (quoteWorkflowState) => {
        let basePrice  = quoteWorkflowState.basePrice;
        let finalPrice = quoteWorkflowState.finalPrice;
        let currPrices = quoteWorkflowState.currPrices;
        let usdPriceInfo = quoteWorkflowState.usdPriceInfo;
        return { basePrice, finalPrice, currPrices, usdPriceInfo }
    }
)

export const selectCurrency = createSelector(
    [quoteWorkflowState],
    (quoteWorkflowState) => quoteWorkflowState.currency
)




export const selectBasePrice = createSelector(
    [quoteWorkflowState],
    (quoteWorkflowState) => quoteWorkflowState.basePrice
)



export const selectFinalPrice = createSelector(
    [quoteWorkflowState],
    (quoteWorkflowState) => quoteWorkflowState.finalPrice
)



export const selectPriceParams = createSelector(
    [quoteWorkflowState],
    (quoteWorkflowState) => {
        let perimeter = quoteWorkflowState.apiData.perimeter;
        let metal = quoteWorkflowState.metal;
        let thickness = quoteWorkflowState.thickness;
        let fileData = quoteWorkflowState.fileData;
        let unitsInput = quoteWorkflowState.unitsInput;
        return { perimeter, metal, thickness, fileData, unitsInput }
    }
);


export const selectCurrPrices = createSelector(
    [quoteWorkflowState],
    (quoteWorkflowState) => quoteWorkflowState.currPrices
);



export const selectVat = createSelector(
    [quoteWorkflowState],
    (quoteWorkflowState) => quoteWorkflowState.vat
);


export const selectFileDims = createSelector(
    [quoteWorkflowState],
    (quoteWorkflowState) => {
        let fileDims = `${quoteWorkflowState.fileData.length}${quoteWorkflowState.unitsInput} \
        x \
        ${quoteWorkflowState.fileData.width}${quoteWorkflowState.unitsInput}`;
        return fileDims;
    }
)

export const selectCartItem = createSelector(
    [quoteWorkflowState],
    (quoteWorkflowState) => {
        let fileData = quoteWorkflowState.fileData;
        let fileDims = `${quoteWorkflowState.fileData.length}${quoteWorkflowState.unitsInput} \
        x \
        ${quoteWorkflowState.fileData.width}${quoteWorkflowState.unitsInput}`;
        let metal = quoteWorkflowState.metal;      
        let thickness = quoteWorkflowState.thickness;
        let apiData = quoteWorkflowState.apiData;   
        let finalQuantity = quoteWorkflowState.quantity ? quoteWorkflowState.quantity : quoteWorkflowState.customQuantity;
        let basePrice = quoteWorkflowState.basePrice; 
        let discount = quoteWorkflowState.discountRate; 
        let finalPrice = quoteWorkflowState.finalPrice;
        let unitsInput = quoteWorkflowState.unitsInput;
        let file = quoteWorkflowState.file;
        let currency = quoteWorkflowState.currency;
        let vat = quoteWorkflowState.vat;
        return {
            fileData, fileDims, 
            metal, apiData, 
            finalQuantity, basePrice, 
            discount, finalPrice, 
            unitsInput, file, 
            thickness, vat, currency
        }
    }
)


// _____________________discountRate_____________________//
export const selectCustomQuoteData = createSelector(
    [quoteWorkflowState],
    quoteWorkflowState => {
        let metal = quoteWorkflowState.metal;
        let thickness = quoteWorkflowState.thickness;
        let fixedQty = quoteWorkflowState.quantity;
        let customQty = quoteWorkflowState.customQuantity;
        let file = quoteWorkflowState.file;
        let fileData = quoteWorkflowState.fileData;
        let discountRate = quoteWorkflowState.discountRate;
        let unitsInput = quoteWorkflowState.unitsInput;
        return {
            metal, thickness, 
            fixedQty, customQty, 
            file, fileData, 
            discountRate, unitsInput
        }
    }
);



// _____________________isCustomQuote_____________________//
export const selectIsCustomQuote = createSelector(
    [quoteWorkflowState],
    quoteWorkflowState => quoteWorkflowState.isCustomQuote
);




// _____________________inputErr_____________________//
export const selectInputErr = createSelector(
    [quoteWorkflowState],
    quoteWorkflowState => quoteWorkflowState.inputErr
);




// _____________________loadingUpdate_____________________//
export const selectLoadingUpdate = createSelector(
    [quoteWorkflowState],
    quoteWorkflowState => quoteWorkflowState.loadingUpdate
);



// _____________________percentLoaded_____________________//
export const selectPercentLoaded = createSelector(
    [quoteWorkflowState],
    quoteWorkflowState => quoteWorkflowState.percentLoaded
);



// _____________________customQuantity_____________________//
export const selectCustomQuantity = createSelector(
    [quoteWorkflowState],
    quoteWorkflowState => quoteWorkflowState.customQuantity
);



// _____________________quantity_____________________//
export const selectQuantity = createSelector(
    [quoteWorkflowState],
    quoteWorkflowState => quoteWorkflowState.quantity
);


// _____________________thickness_____________________//
export const selectThickness = createSelector(
    [quoteWorkflowState],
    quoteWorkflowState => quoteWorkflowState.thickness
);



// _____________________metal_____________________//
export const selectMetal = createSelector(
    [quoteWorkflowState],
    quoteWorkflowState => quoteWorkflowState.metal
);



// _____________________unitsInput_____________________//
export const selectUnitsInput = createSelector(
    [quoteWorkflowState],
    quoteWorkflowState => quoteWorkflowState.unitsInput
);


// _____________________fileReadyToSubmit_____________________//
export const selectFileReadyToSubmit = createSelector(
    [quoteWorkflowState],
    quoteWorkflowState => quoteWorkflowState.fileReadyToSubmit
);


// _____________________apiDataLoading_____________________//
export const selectApiDataLoading = createSelector(
    [quoteWorkflowState],
    quoteWorkflowState => quoteWorkflowState.apiDataLoading
);

// _____________________fileData_____________________//
export const selectFileData = createSelector(
    [quoteWorkflowState],
    quoteWorkflowState => quoteWorkflowState.fileData
);

// _____________________file_____________________//
export const selectFile = createSelector(
    [quoteWorkflowState],
    quoteWorkflowState => quoteWorkflowState.file
);



// _____________________apiData_____________________//
export const selectApiData = createSelector(
    [quoteWorkflowState],
    quoteWorkflowState => quoteWorkflowState.apiData
);

function round(value, decimals) {
    let str = Number(Math.round(value +'e'+ decimals) +'e-'+ decimals).toFixed(decimals);
    return parseFloat(str);
}
// _____________________-----apiData.perimeter_____________________//
export const selectPerimeter = createSelector(
    [quoteWorkflowState],
    quoteWorkflowState => {
        let perimeter = quoteWorkflowState.apiData.perimeter;
        return round(perimeter, 2);
    }
);


export const selectHasDimsInput = createSelector(
    [quoteWorkflowState],
    quoteWorkflowState => {
        let length = quoteWorkflowState.fileData.length;
        let width = quoteWorkflowState.fileData.width;
        if ( (length && width) && length !== "" && length !== "") {
            return true;
        } else {
            return false
        }
    }
)


// _____________________cost_____________________//
export const selectCost = createSelector(
    [quoteWorkflowState],
    quoteWorkflowState => quoteWorkflowState.apiData.cost
);



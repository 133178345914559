import React from 'react'
import { withRouter } from 'react-router-dom';
import styles from './Elements.module.scss';
import fileIconImg from '../../../../assets/file-icons.png';

function FileFormat({ history }) {
    return (
        <div className={styles.wrapper}>
            <h1>File Formats</h1>
            <h2>Files Accepted</h2>
            <img src={fileIconImg} alt="file icons"/>
            <p>
                Currently our online quoter only accepts .dxf, .dwg, and .pdf files, less than 1MB size for laser cutting.
                <b><em> If you have designed your parts in another file type, please send us the original file, 
                and we will send you a custom quote right away.</em></b>
            </p>
            <p>
                We can accept other files such as .eps and .ai files, 
                however they are not eligible for automatic quoting. For other file types, 
                please request a <u>custom quote</u> and we will send you a quote as soon as possible.
            </p>
        </div>
    )
}
 
export default withRouter(FileFormat);
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom';
import { createStructuredSelector } from 'reselect'
import { selectOrderState } from '../../../redux/order-state/orderSelectors';
import { selectFromButtonClick } from '../../../redux/app-state/appSelectors';
import { setFromButtonClick } from '../../../redux/app-state/appActions';
import { Checkmark } from 'react-checkmark'
import { Placeholder, Segment, Dimmer, Loader } from 'semantic-ui-react'
import { resetOrderState } from '../../../redux/order-state/orderActions';
import { endWorkflow } from '../../../redux/quote-workflow/quoteWorkflowActions';
import { resetResultsModal } from '../../../redux/results-modal/resultsModalActions';
import { formatFormData } from '../../../service/helpers';
import { BACKEND } from '../../../service/clientConstants'
import BasicMotionDiv from '../../reusable/BasicMotionDiv'
import axios from 'axios'
import s from './ReceiptPage.module.scss'
import { motion } from 'framer-motion';

// user will land here after placing an actual order 
function ReceiptPage({ orderState, resetOrderState, resetResultsModal, endWorkflow, fromButtonClick, setFromButtonClick }) {

    const [loading, setLoading] = useState(true);
    const url = BACKEND + '/order';
    useEffect(() => {
        // must come from an actual click from form    
        if (fromButtonClick) {
            const formData = formatFormData(orderState);
            axios({
                method: 'post',
                url: url,
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
                if (res.data.success) {
                    resetOrderState();
                    resetResultsModal();
                    endWorkflow();
                    setLoading(false);
                }
            })
            .catch(e => {
                console.log(e);
            })
        }  
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!fromButtonClick) {
        return (
            <motion.div exit={{opacity: 0}}>
                <Redirect to="/"/>
            </motion.div>
        )
    }
  
    return (
        <BasicMotionDiv className={s.wrapper}>
        {
            loading ? (
                <div className={s.placeholderWrapper}>
                    <Placeholder className={s.placeholder}>
                        <Placeholder.Header>
                            <Placeholder.Line length="full"/>
                            <Placeholder.Line length="full"/>
                            <Placeholder.Line length="full"/>
                            <Placeholder.Line length="full"/>
                            <Placeholder.Line length="full"/>
                        </Placeholder.Header>
                    </Placeholder>

                    <Segment className={s.segment}>
                        <Dimmer active inverted>
                            <Loader inverted content='Loading' />
                        </Dimmer>
                    </Segment>
                </div>
            ) : (
                <div className={s.thankyou}>
                    <h1>Thank you!</h1>
                    <h3 style={{marginBottom: '4rem'}}>Payment successful! You'll receive an email confirmation soon, and you will hear from us when your design is ready.</h3>
                    <Checkmark size={100}/>
                </div>
            ) 
        }                                       
        </BasicMotionDiv>
    )
}
 
const mapStateToProps = createStructuredSelector(
    {
        orderState: selectOrderState,
        fromButtonClick: selectFromButtonClick
    }
);

function mapDispatchToProps (dispatch) {
    return {
        resetOrderState: () => dispatch(resetOrderState()),
        endWorkflow: () => dispatch(endWorkflow()),
        resetResultsModal: () => dispatch(resetResultsModal()),
        setFromButtonClick: (bool) => dispatch(setFromButtonClick(bool))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ReceiptPage);
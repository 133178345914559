export const ORDER_ACTIONS = {
    SET_ORDER_ID: "SET_ORDER_ID",
    SET_MERCH_ID: "SET_MERCH_ID",
    SET_SESSION_ID: "SET_SESSION_ID",
    SET_ORDER: "SET_ORDER",
    ADD_TO_CART: "ADD_TO_CART",
    REMOVE_FROM_CART: "REMOVE_FROM_CART",
    DECREASE_ITEM_QTY: "DECREASE_ITEM_QTY",
    INCREASE_ITEM_QTY: "INCREASE_ITEM_QTY",
    SET_CHECKOUT_MODAL_STARTED: "SET_CHECKOUT_MODAL_STARTED",
    SET_PRICE_DATA: "SET_PRICE_DATA",
    SET_CUSTOM_QUOTE_DATA: "SET_CUSTOM_QUOTE_DATA",
    RESET_ORDER_STATE: "RESET_ORDER_STATE",
    SET_NEW_CART: "SET_NEW_CART",
    UPDATE_CART_PRICES: "UPDATE_CART_PRICES",
    SET_ORDER_CURRENCY: "SET_ORDER_CURRENCY"
};
import { CUSTOM_QUOTE_MODAL_ACTIONS } from './actionTypes';
export function setCustomQuoteModalStarted(bool) {
    return {
        type: CUSTOM_QUOTE_MODAL_ACTIONS.SET_MODAL_STARTED,
        payload: bool
    }
}

export function setFormComplete(bool) {
    return {
        type: CUSTOM_QUOTE_MODAL_ACTIONS.SET_FORM_COMPLETE,
        payload: bool
    }
}


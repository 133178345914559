import { ORDER_ACTIONS } from './actionTypes'
import { takeLatest, call, put, all, select } from 'redux-saga/effects';
import { selectCart } from './orderSelectors'
import { setNewCart } from './orderActions'
import axios from 'axios';
import { returnNewCartAndCount } from '../../service/helpers'
import { store } from 'react-notifications-component';
import { FAIL_CONFIG } from '../../service/notifications';

// cart qty changes changes ------------------------------------------------------------------------
function* handleIncDecCartCount({ type, payload }) {
    let cart = yield select(selectCart);
    let finalQty = cart[payload].finalQuantity;
    if (finalQty === 1 && type === ORDER_ACTIONS.DECREASE_ITEM_QTY) {
        yield put(stop());
    } else {
        let add;
        if (type === ORDER_ACTIONS.INCREASE_ITEM_QTY) {
            add = true;
        } else {
            add = false;
        }
        const { newCart, newCount, err } = yield call(
            returnNewCartAndCount,
            cart, payload, add
        );
        if (err) {
            FAIL_CONFIG.message = 'Error updating cart. Contact Administrator.';
            return store.addNotification(FAIL_CONFIG)
        } else {
            yield put(setNewCart({ newCart, newCount }))
        }
    }
}

function stop() {
    return {
        type: ""
    }
} 



function* interceptIncDecCartCount() {
    yield takeLatest(
        [ORDER_ACTIONS.INCREASE_ITEM_QTY, ORDER_ACTIONS.DECREASE_ITEM_QTY],
        handleIncDecCartCount
    );
}




export function* orderSagas() {
    yield all([
        call(interceptIncDecCartCount),
    ]);
}
// here is where we retrieve the state from the quoteReducer
import { createSelector } from 'reselect';

// const INITIAL_STATE = {
//     resultsModalWorkflowStarted: false
//     metalChoicesVisible: false,
//     thicknessChoicesVisible: false,
//     quantityChoicesVisible: false
// }

// Crudely put, the rootState param is the object inside the combineReducers function in rootReducer.js,
// but it holds the values returned by each reducer 
const resultsModalState = rootState => rootState.resultsModalState;

// _____________________quantityChoicesVisible_____________________//
export const selectQuantityChoicesVisible = createSelector(
    [resultsModalState],
    resultsModalState => resultsModalState.quantityChoicesVisible
);


// _____________________resultsModalWorkflowStarted_____________________//
export const selectResultsModalWorkflowStarted = createSelector(
    [resultsModalState],
    resultsModalState => resultsModalState.resultsModalWorkflowStarted
);



// _____________________metalChoicesVisible_____________________//
export const selectMetalChoicesVisible = createSelector(
    [resultsModalState],
    resultsModalState => resultsModalState.metalChoicesVisible
);


// _____________________thicknessChoicesVisible_____________________//
export const selectThicknessChoicesVisible = createSelector(
    [resultsModalState],
    resultsModalState => resultsModalState.thicknessChoicesVisible
);









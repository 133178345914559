import React from 'react'
import { withRouter } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import FilesDisplay from '../../../reusable/FilesDisplay'
import styles from './Heading.module.scss';
import { selectAdminLoggedIn } from '../../../../redux/app-state/appSelectors'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import HeadingTop from './HeadingTop'
import HeadingBottom from './HeadingBottom'
function Heading({ history, adminLoggedIn }) {    
    return (
        <>
            {
                adminLoggedIn ? (
                    <FilesDisplay/>
                ) : (null)
            }
            <div className={styles.wrapper}>
                <HeadingTop/>
                
                <Button
                    color="yellow" 
                    size="huge" 
                    onClick={() => history.push("/quote")}
                    className={styles.btn}
                >Get Instant Quote</Button>
                
                <HeadingBottom/>
            </div>
        </>
       
    )
}

const mapStateToProps = createStructuredSelector(
    {
        adminLoggedIn: selectAdminLoggedIn,
    }
);
export default withRouter(connect(mapStateToProps)(Heading));
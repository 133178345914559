import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { motion } from 'framer-motion'
function PrivateRoute ({component: Component, authed, ...rest}) {
    return (
            <Route
                {...rest}
                render={(props) => authed === true
                ? (
                    <Component {...props} />
                ) : (
                    <motion.div exit={{opacity: '0'}}>
                        <Redirect to={{pathname: '/admin/login', state: {from: props.location}}} />
                    </motion.div>
                )}
            />
    )
  }

export default PrivateRoute;
import React, {forwardRef} from 'react'
import styles from './Button.module.scss';

// receives styleSize, onClick, and text props
const Button = forwardRef((props, ref) => {
    let classStyle = '';
    if (props.styleSize === 'large') {
        classStyle = styles.large;
    } else if (props.styleSize === 'medium') {
        classStyle=styles.medium;
    } else if (props.styleSize === 'small') {
        classStyle=styles.small;
    } else {
        classStyle = props.className;
    }


    let inlineStyles = {};
    if (props.color && props.style) {
        inlineStyles = { ...props.style };
        inlineStyles.backgroundColor = `${props.color}`;
    } else if (props.color) {
        inlineStyles.backgroundColor = `${props.color}`;
    } else if (props.style) {
        inlineStyles = { ...props.style };
    }
    if (props.disabled === true) {
        inlineStyles['backgroundColor'] = 'lightgrey';
        inlineStyles['cursor']          = 'default';
    }

    return (
        <>
            <button 
                name={props.name ? props.name : "reusableBtn"} 
                style={inlineStyles} 
                className={classStyle}
                onClick={(e) => props.onClick(e)}
                disabled={props.disabled ? true : false}
                ref={ref}
            >
            {props.text}
            </button>
        </>
    )
});

export default Button;

import React from 'react'
import BasicMotionDiv from '../../reusable/BasicMotionDiv'
import s from './NotFound.module.scss'
function NotFound(props) {
    return (
        <BasicMotionDiv className={s.wrapper}>
            <div className={s.whoops}>Whoops!</div>
            <div className={s.nothing}>Nothing was found here.</div>
            <div className={s.humor}>The links above are just dying to be clicked!</div>
        </BasicMotionDiv>
    )
}
 
export default NotFound;
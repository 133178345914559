import React from 'react'
import { Modal } from 'react-responsive-modal';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectResultsModalWorkflowStarted } from '../../../redux/results-modal/resultsModalSelectors';
import { setResultsModalWorkflowStarted } from '../../../redux/results-modal/resultsModalActions';
import MainPane from './MainPane';
import styles from './ResultsModal.module.scss';
import 'react-responsive-modal/styles.css';
import 'pure-react-carousel/dist/react-carousel.es.css';


function ResultsModal({ resultsModalWorkflowStarted, setResultsModalWorkflowStarted }) {
    function handleOnClose() {
        setResultsModalWorkflowStarted(false);
    }


    return (
        <Modal open={resultsModalWorkflowStarted} onClose={handleOnClose} classNames={{modal: styles.customModal}} center> 
            <MainPane/>
        </Modal>
    )
}

const mapStateToProps = createStructuredSelector(
    {
        resultsModalWorkflowStarted: selectResultsModalWorkflowStarted,
    }
);

function mapDispatchToProps(dispatch) {
    return {
        setResultsModalWorkflowStarted: (bool) => dispatch(setResultsModalWorkflowStarted(bool))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResultsModal);
import React, { useState, useEffect, useRef } from 'react'
import { Table, Button, Input } from 'semantic-ui-react';
import axios from 'axios';
import { store } from 'react-notifications-component';
import { SUCCESS_CONFIG, FAIL_CONFIG } from '../../../../../service/notifications';
import { BACKEND } from '../../../../../service/clientConstants'
import UploadFileComponent from '../../../../reusable/UploadFileComponent'
import s from './HomePageEdit.module.scss';
import { selectEditLocation } from '../../../../../redux/admin-state/adminSelectors';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect'
import { setEditLocation } from '../../../../../redux/admin-state/adminActions'
import { selectStaticSiteText } from '../../../../../redux/app-state/appSelectors';
import { setHomePageInfo } from '../../../../../redux/app-state/appActions';
function HomePageEdit({ editLocation, setEditLocation, staticSiteText, setHomePageInfo }) {

    // create new user state
    const INITIAL_STATE = {
        mainHeading: "",
        tagline: "",
        btnText: "",
        smallTagline: "",
        imgUrl: ""
    }
    const [inputEmpty, setInputEmpty]                   = useState(true);
    const [homePageText, setHomePageText]               = useState(INITIAL_STATE);
    const [homePageTextLoading, setHomePageTextLoading] = useState(false);
    const [currHomePageText, setCurrHomePageText]       = useState(staticSiteText.homePageInfo ? staticSiteText.homePageInfo : {});
    const [updated, setUpdated]                         = useState(false);
    const ref = useRef();

    // update rdx state when unmounting
    useEffect(() => {
        return () => {
            if (updated) {
                setHomePageInfo(currHomePageText);
            }
        } 
    }, [updated, currHomePageText]);

    // navigation from admin dropdown
    useEffect(() => {
        if (editLocation === 'Edit Home Page') {
            ref.current.scrollIntoView();
            setEditLocation('');
        }
    }, [editLocation]);

    function handleEnterTable(e) {
        let { name, value } = e.target;
        setHomePageText(prevState => {
            let obj = { ...prevState };
            obj[name] = value;
            setInputEmpty(!Object.values(obj).some(x => (x !== null && x !== ''))); // checks if object is empty
            return obj;
        });
    }

    function handleSubmitHomePageText() {
        setHomePageTextLoading(true);
        axios
        .patch((BACKEND + '/admin/text'), { homePageText, purpose: 'homePageTextEdit'})
        .then((res) => {
            if (res.data.success) {
                setCurrHomePageText(res.data.siteText.homePageInfo);
                setHomePageTextLoading(false);
                setUpdated(true);
                SUCCESS_CONFIG.message = res.data.msg;
                store.addNotification(SUCCESS_CONFIG);
            } else {
                FAIL_CONFIG.message = res.data.msg;
                store.addNotification(FAIL_CONFIG);
            }
        })
        .catch((e) => {
            console.log(e);
        });
        setHomePageText(INITIAL_STATE);
    }

    function fileUpdater(imgUrl) {
        setCurrHomePageText(prevState => {
            return {
                ...prevState,
                imgUrl: imgUrl
            }
        });
        setUpdated(true);
    }
    return (
        <div ref={ref} className={s.wrapper}>
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={9}>Home Page</Table.HeaderCell>
                        <Table.HeaderCell>Edit</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    <Table.Row>
                        <Table.Cell>{currHomePageText.mainHeading}</Table.Cell>
                        <Table.Cell>
                            <Input 
                                name="mainHeading" 
                                value={homePageText.mainHeading} 
                                type="text" 
                                onChange={handleEnterTable}
                                placeholder="Enter main heading here"
                                fluid
                            />
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell>{currHomePageText.tagline}</Table.Cell>
                        <Table.Cell>
                            <Input 
                                name="tagline" 
                                value={homePageText.tagline} 
                                type="text" 
                                onChange={handleEnterTable}
                                placeholder="enter main tagline here" 
                                fluid
                            />
                        </Table.Cell>
                    </Table.Row>
            
                    <Table.Row>
                        <Table.Cell>{currHomePageText.btnText}</Table.Cell>
                        <Table.Cell>
                            <Input 
                                name="btnText" 
                                value={homePageText.btnText} 
                                type="text" 
                                onChange={handleEnterTable}
                                placeholder="enter button text here" 
                                fluid
                            />
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell>{currHomePageText.smallTagline}</Table.Cell>
                        <Table.Cell>
                            <Input 
                                name="smallTagline" 
                                value={homePageText.smallTagline} 
                                type="text" 
                                onChange={handleEnterTable}
                                placeholder="enter small tagline here" 
                                fluid
                            />
                        </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                        <Table.Cell>
                            <label>File</label>
                        </Table.Cell>
                        <Table.Cell>
                        {
                            currHomePageText ? (
                                <UploadFileComponent 
                                    url={currHomePageText.imgUrl} 
                                    destination='homePage'
                                    setter={fileUpdater}
                                />
                            ) : (null)
                        }
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>

                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan='2'>
                            <Button 
                                fluid 
                                loading={homePageTextLoading} 
                                onClick={handleSubmitHomePageText}
                                disabled={inputEmpty}
                            >Publish</Button>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    editLocation: selectEditLocation,
    staticSiteText: selectStaticSiteText
});


function mapDispatchToProps(dispatch) {
    return {
        setEditLocation: (string) => dispatch(setEditLocation(string)),
        setHomePageInfo: (obj) => dispatch(setHomePageInfo(obj))
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(HomePageEdit);
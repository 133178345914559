import { RESULTS_MODAL_ACTION_TYPES } from './actionTypes';

// all info for display purposes of the results modal are set here
const INITIAL_STATE = {
    resultsModalWorkflowStarted: false,
    metalChoicesVisible: false,
    thicknessChoicesVisible: false,
    quantityChoicesVisible: false
} 

function resultsModalReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case RESULTS_MODAL_ACTION_TYPES.RESET_RESULTS_MODAL:
            return {
                ...INITIAL_STATE
            }
        case RESULTS_MODAL_ACTION_TYPES.SET_RESULTS_MODAL_WORKFLOW_STARTED:
            return {
                ...state,
                resultsModalWorkflowStarted: action.payload
            }
        case RESULTS_MODAL_ACTION_TYPES.SET_METAL_CHOICES_VISIBLE:
            return {
                ...state,
                metalChoicesVisible: action.payload
            }
        case RESULTS_MODAL_ACTION_TYPES.SET_THICKNESS_CHOICES_VISIBLE:
            return {
                ...state,
                thicknessChoicesVisible: action.payload
            }
        case RESULTS_MODAL_ACTION_TYPES.SET_QUANTITY_CHOICES_VISIBLE:
            return {
                ...state,
                quantityChoicesVisible: action.payload
            }
        default:
            return {
                ...state
            }
    }
}
export default resultsModalReducer;

import { ADMIN_ACTIONS } from './actionTypes';

export function resetAdmin() {
    return {
        type: ADMIN_ACTIONS.RESET_ADMIN,
    }
}



export function setPricingVars(obj) {
    return {
        type: ADMIN_ACTIONS.SET_PRICING_VARS,
        payload: obj
    }
}


export function setEditLocation(string) {
    return {
        type: ADMIN_ACTIONS.SET_EDIT_LOCATION,
        payload: string
    }
}

export function setActiveBtn(string) {
    return {
        type: ADMIN_ACTIONS.SET_ACTIVE_BTN_NAME,
        payload: string
    }
}
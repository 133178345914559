import React from 'react'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { selectSmIcons, selectStaticSiteText } from '../../redux/app-state/appSelectors'
import styles from './Footer.module.scss';
import whatsappSrc from '../../assets/Chat-with-us-on-Whatsapp.png';
import { withRouter } from 'react-router';

// footerLinks   : object w/ keys (see appReducer)
// smIcons       : array of links generated by react
function Footer({ history, smIcons, staticSiteText }) {
    const { location } = history;
    // DB starts uninitialized
    let footerLinks, footerShow, whatsappNum;
    if (staticSiteText.footerInfo) {
        if (staticSiteText.footerInfo.footerLinks) {
            footerLinks   = staticSiteText.footerInfo.footerLinks;
        }
        if (staticSiteText.footerInfo.footerShow) {
            footerShow    = staticSiteText.footerInfo.footerShow;
        }
        if (staticSiteText.footerInfo.whatsappNum) {
            whatsappNum = staticSiteText.footerInfo.whatsappNum;
        }
    }
    return (
        <div className={styles.wrapper} style={location.pathname === "/admin" ? {marginTop: '0'} : null}>
            <div className={styles.smLinksWrapper}>
            {
                Object.keys(smIcons).map((key, idx) => {
                    if (footerLinks) {
                        if (footerShow) {
                            if (footerShow[key] === true) {
                                return (
                                    <a key={idx} href={footerLinks[key]} target="_blank" rel="noopener noreferrer">
                                        <img src={smIcons[key]} alt="smIcon"/>
                                    </a>
                                )
                            } else { return null}
                        } else { return null }
                    } else { return null }
                })
            }
            </div>
            <div className={styles.normalLinks}>
                <a href="http://naggiar.net/content/story-behind" target="_blank" rel="noopener noreferrer">About Us</a>
                <a href="http://naggiar.net/content/contact-us" target="_blank" rel="noopener noreferrer">Contact Us</a>
            </div>
            <div>
                <a href={`https://wa.me/` + `${whatsappNum}?text=I%27m%20interested%20to%20know%20about%20your%20products`} target="_blank" rel="noopener noreferrer">
                    <img style={{width: '130px'}} src={whatsappSrc} alt="whatsapp chat with us"/>
                </a>
            </div>
        </div>
    )

}

const mapStateToProps = createStructuredSelector({
    smIcons: selectSmIcons,
    staticSiteText: selectStaticSiteText
})
export default withRouter(connect(mapStateToProps)(Footer));
import React, { useState } from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectAdminLoggedIn } from '../../../../redux/app-state/appSelectors';
import { METAL_PAGE_TOOLBAR, SANITIZE_OPTS } from '../../../../service/clientConstants';
import { formatHtml } from '../../../../service/helpers';
import RichTextEditor from '../admin_elements/RichTextEditor'
import sanitizeHtml from 'sanitize-html';
import parse from 'html-react-parser'
function MaterialPageBottomHtml({ destination, material, adminLoggedIn }) {
    const [localBtmHtml, setLocalBtmHtml] = useState(material.pageBottomHtml);

    function updateHtml(html) {
        setLocalBtmHtml(sanitizeHtml(html, SANITIZE_OPTS))
    }

    return (
        <div style={{width: '100%'}}>
        {
            localBtmHtml ? parse(localBtmHtml, {
                replace: formatHtml
            }) : (null)
        }
        {
            adminLoggedIn ? (
                <RichTextEditor
                    currHtml={localBtmHtml ? localBtmHtml : null}
                    destination={destination}
                    toolbar={METAL_PAGE_TOOLBAR}
                    height='800'
                    metalName={material.name}
                    setParentHtml={updateHtml}
                />
            ) : (null)
        }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    adminLoggedIn: selectAdminLoggedIn
})
export default connect(mapStateToProps)(MaterialPageBottomHtml);
import React, { useState, useEffect } from 'react'
import s from './NormalPrices.module.scss'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { getPrices } from '../../../../../redux/quote-workflow/quoteWorkflowActions'
import { returnSaveRate } from '../../../../../service/saveRate'
import { selectBasePrice, selectCurrPrices, 
    selectCustomQuantity, 
    selectFinalPrice, 
    selectMetal, 
    selectThickness, 
selectCurrency } from '../../../../../redux/quote-workflow/quoteWorkflowSelectors'
import { LBP } from '../../../../../service/clientConstants'


// displays prices with their relative savings discount as a percentage statically; dynamically for customQuantity
function NormalPrices({ currPrices, customQuantity, thickness, basePrice, finalPrice, getPrices, metal, currency }) {
    
    const [loading, setLoading]           = useState(true);
    const [displayPrice, setDisplayPrice] = useState("");
    let currencySymbol;
    if (currency === LBP) {
        currencySymbol = "LBP ";
    } else {
        currencySymbol = "$";
    }

    // get prices
    useEffect(() => {
        if (Object.keys(currPrices).length === 0 || !finalPrice) {
            getPrices();
        }
    }, [])

    useEffect(() => {
        if (Object.keys(currPrices).length > 0 || finalPrice) {
            setLoading(false);
        }
    }, [currPrices])

    useEffect(() => {
        if (Object.keys(currPrices).length > 0 || finalPrice) {
            getPrices();
            //setLoading(true);
            
        }
    }, [thickness]);

    // set display price for first area
    useEffect(() => {
        if (!customQuantity) {
            setDisplayPrice(basePrice);
        } else if (parseFloat(finalPrice) > parseFloat(basePrice)) {
            setDisplayPrice(finalPrice);
        } else if (parseFloat(finalPrice) === parseFloat(basePrice)) {
            setDisplayPrice(finalPrice);
        }
    }, [basePrice, finalPrice, customQuantity])


    return (
        loading ? (
            null
        ) : (
            <ul className={s.ulWrapper}>
                <div className={s.title}>Total Cost</div>
                <div className={s.first}>

                    {currencySymbol}
                    {
                        currencySymbol === "LBP " ? (
                            parseFloat(displayPrice).toLocaleString()
                        ) : (
                            displayPrice
                        )
                    }
                    <span className={s.save}>
                    {
                        parseFloat(finalPrice) > 20 && customQuantity ? (
                            returnSaveRate(parseFloat(customQuantity), metal.quantityChoices)
                        ) : (null)
                    }
                    </span>
                </div>
                {
                    Object.keys(currPrices).map((choice, idx) => (
                        <div className={s.other} key={idx}>
                            {currencySymbol}
                            {
                                currencySymbol === "LBP " ? (
                                    parseFloat(currPrices[choice]).toLocaleString()
                                ) : (
                                    currPrices[choice]
                                )
                            }
                            {
                                currPrices[choice] > 20 ? (
                                    <span className={s.save}>{returnSaveRate(parseFloat(choice), metal.quantityChoices)}</span>
                                ) : (null)
                            }
                        </div>
                    ))
                }
            </ul>
        )
    )
}

const mapStateToProps = createStructuredSelector(
    {
        currPrices: selectCurrPrices,
        thickness: selectThickness,
        finalPrice: selectFinalPrice,
        basePrice: selectBasePrice,
        customQuantity: selectCustomQuantity,
        metal: selectMetal,
        currency: selectCurrency
    }
);

function mapDispatchToProps(dispatch) {
    return {
        getPrices: () => dispatch(getPrices()),
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(NormalPrices);
import React, { useEffect } from 'react'
import uuid from 'react-uuid';
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux';
import { setCheckoutModalStarted, setMerchantId, setOrderId, setSessionId } from '../../../../redux/order-state/orderActions'
import { selectOrderState } from '../../../../redux/order-state/orderSelectors';
import { selectUserLoggedIn } from '../../../../redux/app-state/appSelectors'
import { Modal } from 'react-responsive-modal';
import CheckoutForm from './sub-components/CheckoutForm';
import s from './CheckoutModal.module.scss';
import { randomIntFromInterval } from '../../../../service/helpers';


function CheckoutModal({userLoggedIn, setOrderId, orderState, setCheckoutModalStarted }) {
    const { checkoutModalStarted } = orderState;


    // set amount to total price
    useEffect(() => {
        const orderNum = randomIntFromInterval(10, 100000).toString();
        setOrderId(orderNum, userLoggedIn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    function handleOnClose() {
        setCheckoutModalStarted(false);
    }
    
    return (
        <Modal open={checkoutModalStarted} onClose={handleOnClose} classNames={{modal: s.modal}} center>
            <CheckoutForm />
        </Modal>     
    )
}


const mapStateToProps = createStructuredSelector( 
    {
        orderState: selectOrderState,
        userLoggedIn: selectUserLoggedIn
    }
)

function mapDispatchToProps(dispatch) {
    return {
        setSessionId: (id) => dispatch(setSessionId(id)),
        setMerchantId: (id) => dispatch(setMerchantId(id)),
        setOrderId: (id) => dispatch(setOrderId(id)),
        setCheckoutModalStarted: (bool) => dispatch(setCheckoutModalStarted(bool))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutModal);
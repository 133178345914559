import React from 'react'
import { Table } from 'semantic-ui-react'
import { LBP } from '../../../../../service/clientConstants';

function ItemEntry({ apiData, basePrice, thickness, unitsInput, discount,
    fileData, finalQuantity, metal, finalPrice, idx, fileDims, currency }) {

    return (
        <Table.Row key={idx}>
            <Table.Cell textAlign='center'>{fileData.name}</Table.Cell>
            <Table.Cell textAlign='center'>{fileDims}</Table.Cell>
            <Table.Cell textAlign='center'>{metal.name}</Table.Cell>
            <Table.Cell textAlign='center'>{thickness}</Table.Cell>
            <Table.Cell textAlign='center'>
                    {finalQuantity}
            </Table.Cell>
            <Table.Cell textAlign='center'>{currency === LBP ? `LBP ${basePrice.toLocaleString()}` : `$${parseFloat(basePrice).toFixed(2)}`}</Table.Cell>
            <Table.Cell textAlign='center' style={{color: 'green', fontStyle: 'italic'}}>{discount}</Table.Cell>
            <Table.Cell textAlign='center'>{currency === LBP ? `LBP ${finalPrice.toLocaleString()}` : `$${parseFloat(finalPrice).toFixed(2)}`}</Table.Cell>
        </Table.Row>
    )
}


export default ItemEntry;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { Button, Form, FormField, Dropdown, FormGroup, Label, Input, TextArea } from 'semantic-ui-react';
import { selectCustomQuoteData, selectFileDims } from '../../../../redux/quote-workflow/quoteWorkflowSelectors';
import { selectMetalsState, selectUserLoggedIn } from '../../../../redux/app-state/appSelectors';
import { setFromButtonClick } from '../../../../redux/app-state/appActions';
import { SUCCESS_CONFIG } from '../../../../service/notifications';
import { sortThicknessTable, inTable } from '../../../../service/helpers';
import { returnSaveRate } from '../../../../service/saveRate';
import { setCustomQuoteModalStarted } from '../../../../redux/custom-quote-modal/customQuoteActions';
import { endWorkflow } from '../../../../redux/quote-workflow/quoteWorkflowActions';
import { setFormDataObj } from '../../../../redux/order-state/orderActions';
import s from './SubmissionForm.module.scss';


// for custom quotes
function SubmissionForm({ history, currData, setCustomQuoteModalStarted, setFormDataObj, fileDims, userLoggedIn, metals, setFromButtonClick }) {
    const { metal, thickness, fixedQty, customQty, file, fileData, discountRate, unitsInput } = currData;    
    SUCCESS_CONFIG.message                = "Everything was submitted successfully! Expect an email or call soon."
    // state for form inputs
    const [newFile, setNewFile]           = useState(file);
    const [newFileData, setNewFileData]   = useState(fileData);
    const [currMetal, setCurrMetal]       = useState(metal);
    const [newThickness, setNewThickness] = useState(thickness);
    const [newUnitsInput, setNewUnitsInput] = useState(unitsInput)
    const [firstName, setFirstName]       = useState('');
    const [lastName, setLastName]         = useState('');
    const [email, setEmail]               = useState('');
    const [company, setCompany]           = useState('');
    const [newFixedQty, setNewFixedQty]   = useState(fixedQty && metal ? `${fixedQty} (${returnSaveRate(fixedQty, metal.quantityChoices)})`  : "");
    const [newCustomQty, setNewCustomQty] = useState(customQty ? [parseInt(customQty), discountRate] : [0]);
    const [note, setNote]                 = useState("");
    const [phone, setPhone]               = useState("");

    // state for logic
    const [isCustomQty, setIsCustomQty]   = useState(fixedQty ? false : true);
    const [isFixedQty, setIsFixedQty]     = useState(fixedQty ? true : false);
    const [hasQtyInput, setHasQtyInput]   = useState(fixedQty || customQty ? true : false);
    const [fileUploaded, setFileUploaded] = useState(file ? true : false);
    const re                              = new RegExp('^[1-9][0-9]{0,8}$'); // for length and width input
    //const phoneRegex                      = new RegExp('^[0-9]{2}-[0-9]{3}-[0-9]{3}$');
    const numRegex                        = /^[+0-9]+$/;

    // state for dropdowns and db data
    const [localMetals, setLocalMetals]           = useState([]);
    const [metalOptions, setMetalOptions]         = useState([]);
    const [thicknessOptions, setThicknessOptions] = useState([]);
    const [qtyOptions, setQtyOptions]             = useState([]);
    // const unitsOptions = [
    //     { value: "mm", text: "mm"},
    //     { value: "cm", text: "cm"}
    // ];


    // load full localMetals list
    useEffect(() => {
        setLocalMetals(metals.filter(m => m.table.length > 0));
        // format dropdown options, only show localMetals that have a table length; must match what they see in main pane choices
        setMetalOptions(metals.filter(metal => metal.table.length > 0).map(mtl =>  {  
            return { key: mtl.name, value: mtl.name, text: mtl.name }
        }));

        // just to be sure there is no undefined val: also TESTING
        if (!currMetal) setCurrMetal({});
    }, []);

    // handle metal changes
    useEffect(() => {
        if (currMetal) {
            // dropdown options must have same value and text vals
            const sortedThickOpts = sortThicknessTable(currMetal.table).map(choice => {
                return { value: choice[0], text: choice[0]}
            });
            setThicknessOptions(sortedThickOpts);
            let { quantityChoices } = currMetal;
            if (quantityChoices) { // make sure there is qty choices set in table
                setQtyOptions(Object.keys(quantityChoices).map(choice => {
                    let saveStr = `(${returnSaveRate(choice, quantityChoices)})`;
                    return { value: `${choice} ${saveStr}`, text: `${choice} ${saveStr}` }
                }));  
            }
                    
        }
    }, [currMetal]);


    // make sure hasQtyInput flag is set for form submission validation
    useEffect(() => {
        if (newFixedQty || (newCustomQty[0] !== 0 && !isNaN(newCustomQty[0]))) {
            setHasQtyInput(true);
        } else {
            setHasQtyInput(false)
        }
    }, [newFixedQty, newCustomQty]);




    function handleSubmit(e) {
        e.preventDefault();
        const megastate = {
            newFile, newFileData, metal, newThickness, firstName, lastName, newUnitsInput,
            email, company, newFixedQty, newCustomQty, isCustomQty, isFixedQty, note, phone,
            fileDims, userLoggedIn
        };
        let formData = new FormData();
        Object.keys(megastate).forEach(el => {
            if (typeof(megastate[el]) === "object" && el !== "newFile") {
                formData.append(el, JSON.stringify(megastate[el]))
            } else if (el === "newFile") {
                formData.append("customQuoteFile", megastate[el])
            } else {
                formData.append(el, megastate[el]);
            }
        });
        setFromButtonClick(true);
        setFormDataObj(formData);
        setCustomQuoteModalStarted(false);
        history.push("/confirmation");
    }

    // note: does not handle fixed Qty changes, because fixed Qty radio button has a dropdown
    // dropdown API is different than onChange API
    function handleOnChange(e) {
        const { name, value } = e.target;

        if (name === "length") {
            if (re.test(value) || value === "") {
                setNewFileData(prevVal => {
                    return {
                        ...prevVal,
                        length: value
                    }
                })
            }
        } else if (name === "width") {
            if (re.test(value) || value === "") {
                setNewFileData(prevVal => {
                    return {
                        ...prevVal,
                        width: value
                    }
                });
            }
        } else if (name === "phone") {
            if (!value) {
                setPhone(value);
            }
            if (numRegex.test(value)) {
                setPhone(value);
            }
        } else if (name === "customQty") {
            let num = parseInt(value)
            setNewCustomQty([num, returnSaveRate(num, currMetal.quantityChoices)])
        }
        //const inputRe = /^[0-9\b\-]{0,10}$/;
        switch (name) {
            case "firstName":
                setFirstName(value)
                return
            case "lastName":
                setLastName(value)
                return
            case "email":
                setEmail(value)
                return
            case "company":
                setCompany(value)
                return
            case "customQtyRadio":
                setIsCustomQty(true)
                setIsFixedQty(false);
                setNewFixedQty("");
                return
            case "fixedQtyRadio":
                setIsCustomQty(false);
                setIsFixedQty(true);
                setNewCustomQty([0]);
                return
            case "note":
                setNote(value);
                return
            case "phone":
                // if (value === '' || inputRe.test(value)) {
                //     console.log("ired");
                //     if (value[value.length - 1] === "-") { // deleting text
                //         setPhone(value.slice(0, -1))
                //     } else if (value.length === 2 || value.length === 6) { // adding text
                //         setPhone(value + '-')
                //     } else {
                //         setPhone(value);
                //     }
                // }
                return
            default:
                return
        }
    }

    // note: handles all dropdown changes; handles fixed Qty changes
    function handleDropdownChange(e, data) {
        const { textContent } = e.currentTarget;
        if (data.name === "metalType") {
            setCurrMetal(localMetals.find(obj => obj.name === textContent));
            // in qty options ?
            setNewFixedQty("");
            setNewCustomQty([0]);
            if (!inTable(newThickness, textContent, localMetals)) {
                setNewThickness(""); // must be reset since not all values are the same
            }
        } else if (data.name === "thickType") {
            setNewThickness(textContent)
        } else if (data.name === "fixedQty") {
            setNewCustomQty([0])
            setNewFixedQty(data.value);
        } else if (data.name === "unitsInput") {
            setNewUnitsInput(data.value);
        }
    }


    return (
        <div>
            <Form onSubmit={handleSubmit} className={s.wrapper} name="submissionForm" autoComplete="off">
                <Form.Group>
                    <FormField style={{width: '50%'}}>
                        <label>First Name</label>
                        <input type="text" name="firstName" placeholder='name' onChange={handleOnChange} required/>
                    </FormField>
                    <FormField style={{width: '50%'}}>
                        <label>Last Name</label>
                        <input type="text" name="lastName" placeholder='name' onChange={handleOnChange} required/>
                    </FormField>
                </Form.Group>
                <FormField>
                    <label>Email</label>
                    <input type="email" name="email" placeholder='email' onChange={handleOnChange} required/>
                </FormField>
                <FormField>
                    <label>Phone</label>
                    <input value={phone} type="text" name="phone" placeholder='e.g., +96199999999' onChange={handleOnChange}/>
                </FormField>
                <FormField>
                    <label>Company</label>
                    <input type="text" name="company" placeholder='company name' onChange={handleOnChange}/>
                </FormField>
                <FormField>
                    <label>Metal Type</label>
                    <Dropdown name="metalType" defaultValue={currMetal.name} options={metalOptions ? metalOptions : null} onChange={handleDropdownChange} selection required/>
                </FormField>
                <FormField>
                    <label>Thickness Type</label>
                    <Dropdown name="thickType" defaultValue={newThickness} options={thicknessOptions ? thicknessOptions : null} onChange={handleDropdownChange} selection required/>
                </FormField>
               

                <FormField>
                    <label>Notes</label>
                    <TextArea name="note" onChange={handleOnChange}/>
                </FormField>


                <Form.Group className={s.immutable}>
                    <FormField>
                        <label>File</label>
                            <Label basic>{newFileData.name}</Label>
                            {/* <Button type="button" className={s.fileBtn} onClick={handleFileBtnClick}>Change</Button>
                            <input type="file" ref={fileInputEl} onChange={handleFileChange} accept=".dxf,.dwg,.pdf" hidden/> */}
                    </FormField>

                    <FormField>
                        <label>Length</label>
                        <Label basic >{newFileData.length}</Label>
                    </FormField>
                    <FormField>
                        <label>Width</label>
                        <Label basic >{newFileData.width}</Label>
                    </FormField>
                    <FormField>
                    <label>Units</label>
                    {/* <Dropdown name="unitsType" defaultValue={newUnitsInput} options={unitsOptions} onChange={handleDropdownChange} selection required/> */}
                    <Label basic>{unitsInput}</Label>
                </FormField>
                </Form.Group>

                
                


                <FormGroup className={s.qtyFields}>
                    <FormField className={s.fieldWrapper}>
                        <div className="ui radio checkbox">
                            <input type="radio" name="customQtyRadio" checked={isCustomQty} onChange={handleOnChange} />
                            <label>Custom Quantity</label>
                        </div>
                        {
                            isCustomQty ? (
                                <Input 
                                    type="number" 
                                    min="1" 
                                    name="customQty" 
                                    className={s.customQtyInput}
                                    placeholder="Enter quantity" 
                                    onChange={handleOnChange} 
                                    label={newCustomQty[1] ? {tag: true, content: newCustomQty[1], size: 'mini', color: 'green'} : ''}
                                    labelPosition='right'
                                    value={newCustomQty[0]}
                                />
                            ) : (null)
                        }
                    </FormField>

                    {
                        currMetal.quantityChoices ? (
                            <FormField>
                                <div className="ui radio checkbox">
                                    <input type="radio" name="fixedQtyRadio" checked={isFixedQty} onChange={handleOnChange}/>
                                    <label>Fixed Quantity</label>
                                    {
                                        isFixedQty ? (
                                            <Dropdown className='fluid' name="fixedQty" options={qtyOptions} value={newFixedQty} onChange={handleDropdownChange} selection/>
                                        ) : (null)
                                    }
                                </div>
                            </FormField>
                        ) : (
                            null
                        )
                    }
                </FormGroup>

                <Form.Field>
                    <Button type="submit" disabled={!fileUploaded || !hasQtyInput || !newThickness || !newFile  || isNaN(newCustomQty[0])}>Submit</Button>
                    {
                        !hasQtyInput ? <Label pointing='left' className={s.labelMsg}>Please enter a valid quantity</Label> : null
                    }
                    {
                        !newThickness ? <Label pointing={!hasQtyInput ? 'above' : 'left'} className={s.labelMsg}>Please enter a thickness</Label> : null
                    }
                </Form.Field>
            </Form>
        </div>
    )
}

const mapStateToProps = createStructuredSelector(
    {
        currData: selectCustomQuoteData,
        fileDims: selectFileDims,
        userLoggedIn: selectUserLoggedIn,
        metals: selectMetalsState
    }
);

function mapDispatchToProps(dispatch) {
    return {
        setCustomQuoteModalStarted: (bool) => dispatch(setCustomQuoteModalStarted(bool)),
        endWorkflow: () => dispatch(endWorkflow()),
        setFormDataObj: (obj) => dispatch(setFormDataObj(obj)),
        setFromButtonClick: (bool) => dispatch(setFromButtonClick(bool))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SubmissionForm));
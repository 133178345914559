import React from 'react'
import styles from './Elements.module.scss';
import textImg from '../../../../assets/text-ex.png'
function ShapesOutlines(props) {
    return (
        <div className={styles.wrapper}>
            <h2>All text should be converted to shapes or outlines</h2>
            <p>
                If your design has any “active” text boxes, our machine will not be able to cut it properly. 
                If you want to include text, please convert each 
                text box into shapes or outlines; this is very easy to do in most design software.
            </p>
            <p>
                If you only work with Autodesk, please send us the text with the font and we will do it for you.
            </p>
            <img src={textImg} alt="text example"/>
        </div>
    )
}
 
export default ShapesOutlines;
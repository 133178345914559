import React from 'react'
import { Button } from 'semantic-ui-react';
import styles from './HomePage.module.scss';
import { withRouter } from 'react-router-dom';
import { HOME_PAGE_TRANSITION } from '../../../service/clientConstants'
import { motion } from 'framer-motion'
import useBodyClassChange from '../../../service/helpers';
import { createStructuredSelector } from 'reselect';
import { selectAdminLoggedIn, selectStaticSiteText } from '../../../redux/app-state/appSelectors';
import { connect } from 'react-redux';

// since we only have two props, destructure props using an object
function HomePage({ history, staticSiteText, adminLoggedIn }) {
    useBodyClassChange(adminLoggedIn);

    let homePageInfo;
    if (staticSiteText.homePageInfo) {
        homePageInfo = staticSiteText.homePageInfo;
    }
    
    return (
        <motion.div key="home" exit="exit" animate="visible" initial="hidden" variants={HOME_PAGE_TRANSITION}>
            <div className={styles.wrapper}>
                <div className={styles.firstColumn}>
                    <div className={styles.text}>
                        <h1 className={styles.heading}>{homePageInfo.mainHeading}</h1>
                        <h3 className={styles.subheading}>{homePageInfo.tagline}</h3>
                        <Button 
                            onClick={(e) => history.push("/quote")}
                            size="massive"
                            color="yellow"
                        >{homePageInfo.btnText}</Button>
                    </div>
                    <div className={styles.smallCta}>{homePageInfo.smallTagline}</div>
                </div>
    
                <div className={styles.image}>
                    <img src={homePageInfo.imgUrl} alt="naggiar instrument"/>
                </div>
            </div>
        </motion.div>
    )
}

const mapStateToProps = createStructuredSelector({
    staticSiteText: selectStaticSiteText,
    adminLoggedIn: selectAdminLoggedIn
});

export default withRouter(connect(mapStateToProps)(HomePage));

import React, { useState } from 'react'
import { Button, Form } from 'semantic-ui-react';
import ResuableButton from '../../../reusable/Button';
import axios from 'axios';
import { store } from 'react-notifications-component';
import { SUCCESS_CONFIG, FAIL_CONFIG } from '../../../../service/notifications';
import { BACKEND } from '../../../../service/clientConstants'
import s from './ManageAdminUsers.module.scss';
import { createStructuredSelector } from 'reselect';
import { selectActiveBtnName } from '../../../../redux/admin-state/adminSelectors';
import { connect } from 'react-redux';


function ManageAdminUsers({ activeButtonName }) {
    
    // create new user state
    const [createUserLoading, setCreateUserLoading] = useState(false);
    const [username, setUsername]                   = useState("");
    const [password, setPassword]                   = useState("");
    const [adminUsers, setAdminUsers]                = useState([]);
    const [delUserLoading, setDelUserLoading]       = useState(false);
    const [delUsername, setDelUsername]             = useState("");

    if (activeButtonName !== "MANAGE_ADMIN") {
        return null
    }
    
    function handleCreateUserSubmit() {
        axios
        .post((BACKEND + '/admin/users'), { username, password })
        .then(res => {
            if (res.data.success) {
                SUCCESS_CONFIG.message = res.data.msg;
                store.addNotification(SUCCESS_CONFIG);
                setUsername("");
                setPassword("");
            } else {
                FAIL_CONFIG.message = res.data.msg;
                store.addNotification(FAIL_CONFIG);
            }
            setCreateUserLoading(false);
        })
        .catch(e => console.log(e));
    }
    // delete user state
    function handleDelUserSubmit() {
        axios
        .delete((BACKEND + '/admin/users'), { 
            data: { 
                delUsername 
            }
        })
        .then(res => {
            if (res.data.success) {
                SUCCESS_CONFIG.message = res.data.msg;
                store.addNotification(SUCCESS_CONFIG);
                setDelUsername("");
            } else {
                FAIL_CONFIG.message = res.data.msg;
                store.addNotification(FAIL_CONFIG);
            }
            setDelUserLoading(false);
        })
        .catch(e => console.log(e));
    }

    
    function handleDisplayUsers(e) {
        axios
        .get((BACKEND + '/admin/users'), {})
        .then(res => {
            if (res.data.success) {
                setAdminUsers(res.data.users);
            }
        })
        .catch(e => console.log(e.message));
    }
    function handleChange(e) {
        let { name, value } = e.target;
        if (name === 'username') {
            setUsername(value);
        } else if (name === 'password') {
            setPassword(value);
        } else if (name === 'delUsername') {
            setDelUsername(value);
        }
    }

    return (
        <div className={s.wrapper}>
            <div className={s.form}>
                <h1 className={s.title}>Create New User</h1>
                <Form onSubmit={handleCreateUserSubmit}>
                    <Form.Field>
                        <label>Username</label>
                        <input value={username} type='text' name='username' placeholder='username' onChange={handleChange} required/>
                    </Form.Field>
                    <Form.Field>
                        <label>Password</label>
                        <input value={password} type="password" name='password' placeholder='password' onChange={handleChange} required/>
                    </Form.Field>
                    <Button type='submit' loading={createUserLoading} onClick={() => setCreateUserLoading(true)}>Submit</Button>
                </Form>
            </div>

            <div className={s.form}>
                <h1 className={s.title}>Delete User</h1>
                <Form onSubmit={handleDelUserSubmit}>
                    <Form.Field>
                        <label>Username</label>
                        <input value={delUsername} type='text' name='delUsername' placeholder='username to delete' onChange={handleChange} required/>
                    </Form.Field>
                    <Button type='submit' loading={delUserLoading} onClick={() => setDelUserLoading(true)}>Submit</Button>
                </Form>
            </div>

            <ResuableButton 
                styleSize='large' 
                color="grey" 
                text='Display All Admin Users' 
                style={{marginTop: '2rem', width: '369px'}}
                onClick={handleDisplayUsers}
            />

            {
                adminUsers.length > 0 ? (
                    adminUsers.map( (user, idx) => <div key={idx} className={s.user}>{user.username}</div>)
                ) : (null)
            }
            {
                adminUsers.length > 0 ? (
                    <Button onClick={() => setAdminUsers([])}>Remove Display</Button>
                ) : (null)
            }
        </div>
    )
}
const mapStateToProps = createStructuredSelector({
    activeButtonName: selectActiveBtnName
});


export default connect(mapStateToProps)(ManageAdminUsers);
export const QUOTE_WORKFLOW_ACTIONS = {
    SET_GENERAL_WORKFLOW_STARTED: "SET_GENERAL_WORKFLOW_STARTED",
    SET_FILE_READY_TO_SUBMIT: "SET_FILE_READY_TO_SUBMIT",
    SET_API_DATA_LOADING: "SET_API_DATA_LOADING",
    SET_API_DATA: "SET_API_DATA",
    SET_FILE_DATA: "SET_FILE_DATA",
    SET_FILE: "SET_FILE",
    SET_UNITS_INPUT: "SET_UNITS_INPUT",
    SET_METAL_TYPE: "SET_METAL_TYPE",
    SET_THICKNESS: "SET_THICKNESS",
    SET_QUANTITY: "SET_QUANTITY",
    SET_CUSTOM_QUANTITY: "SET_CUSTOM_QUANTITY",
    BACK_TO_METALS: "BACK_TO_METALS",
    RESET_INPUTS: "RESET_INPUTS",
    SET_PERCENT_LOADED: "SET_PERCENT_LOADED",
    SET_LOADING_UPDATE: "SET_LOADING_UPDATE",
    END_WORKFLOW: "END_WORKFLOW",
    SET_FILE_LENGTH: "SET_FILE_LENGTH",
    SET_FILE_WIDTH: "SET_FILE_WIDTH",
    SET_IS_CUSTOM_QUOTE: "SET_IS_CUSTOM_QUOTE",
    DIMS_RESET: "DIMS_RESET",
    SET_VAT: "SET_VAT",
    SET_INPUT_ERR: "SET_INPUT_ERR",
    GET_PRICES: "GET_PRICES",
    RESET_PRICES: "RESET_PRICES",
    SET_PRICES: "SET_PRICES",
    SET_FINAL_PRICE: "SET_FINAL_PRICE",
    SET_CURRENCY: "SET_CURRENCY",
    SET_USD_PRICE_INFO: "SET_USD_PRICE_INFO"
}
import { CUSTOM_QUOTE_MODAL_ACTIONS } from './actionTypes';

// all info for display purposes of the results modal are set here
const INITIAL_STATE = {
    customQuoteModalStarted: false,
    formComplete: false,
} 

function customQuoteReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case CUSTOM_QUOTE_MODAL_ACTIONS.SET_MODAL_STARTED:
            return {
                ...state,
                customQuoteModalStarted: action.payload
            }
        case CUSTOM_QUOTE_MODAL_ACTIONS.SET_FORM_COMPLETE:
            return {
                ...state,
                formComplete: action.payload
            }
        default:
            return {
                ...state
            }
    }
}
export default customQuoteReducer;

import React, { useState, useEffect } from 'react'
import { createStructuredSelector } from 'reselect'
import { selectCartItems } from '../../../redux/order-state/orderSelectors';
import { selectAdminLoggedIn } from '../../../redux/app-state/appSelectors';
import { setEditLocation } from '../../../redux/admin-state/adminActions';
import { setAdminLogin, updateMetalsState, updateSiteText } from '../../../redux/app-state/appActions';
import { connect } from 'react-redux';
import { Icon, Dropdown } from 'semantic-ui-react';
import { withRouter, Link } from 'react-router-dom';
import CurrencySelector from './CurrencySelector';
import s from './Links.module.scss';
import AdminDisplay from '../pages/admin_elements/sub-components/AdminDisplay';



function Links({ history, cartItems, adminLoggedIn, setAdminLogin, setEditLocation, updateSiteText, updateMetalsState }) {
    
    function handleClick(e) {
        let href = e.currentTarget.getAttribute('href');
        if (history.location.pathname === href) {
            e.preventDefault();
        }
    }
    const [width, setWidth]                   = useState(0);
    function updateDims() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        updateDims();
        window.addEventListener('resize', updateDims);
    }, []);

    return (
        <div className={s.links} style={adminLoggedIn && width > 1024 ? {width: '80%'} : null}>
            {
                adminLoggedIn && width > 1024 ? <AdminDisplay/> : null 
            }
            <Link to="/" onClick={handleClick}>Home</Link>
            <Link to="/materials" onClick={handleClick}>Materials</Link>
            <Link to="/quote" onClick={handleClick}>Get Quote</Link>
            <Link to="/guidelines" onClick={handleClick}>Guidelines</Link>
            <CurrencySelector/>
            <span className={s.cartGroup} onClick={() => history.push("/cart")}>
                <span
                    className={s.qty}
                    style={
                        cartItems > 9 && cartItems < 100
                            ? { left: "15.5px" }
                            : cartItems > 100
                            ? { left: "12.5px" }
                            : null
                    }
                >
                    {cartItems ? cartItems : 0}
                </span>
                <Icon
                    name="cart"
                    size="large"
                    style={{ fontSize: "30px" }}
                />
                <div className={s.link}>Cart</div>
            </span>
        </div>
    )
}
const mapStateToProps = createStructuredSelector(
    {
        cartItems: selectCartItems,
        adminLoggedIn: selectAdminLoggedIn,
    }
);

export default withRouter(connect(mapStateToProps)(Links));
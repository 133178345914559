import { QUOTE_WORKFLOW_ACTIONS } from './actionTypes'
import { takeLatest, call, put, all, select, actionChannel } from 'redux-saga/effects';
import { selectPriceParams, selectCurrPrices, selectConvertParams, selectCurrency, selectFinalPrice, selectQty } from './quoteWorkflowSelectors'
import { getPrices, setCustomQuantity, setFinalPrice, setPrices, setQuantity, setUsdPriceInfo } from './quoteWorkflowActions'
import axios from 'axios';
import { BACKEND, USD, LBP } from '../../service/clientConstants'
import { selectCart } from '../order-state/orderSelectors';
import { setOrderCurrency, updateCartPrices } from '../order-state/orderActions';
import { selectUserLoggedIn } from '../app-state/appSelectors';

// qty input changes ------------------------------------------------------------------------
function* handleQuantitySetters({ payload }) {
    let currPrices = yield select(selectCurrPrices);
    let currency   = yield select(selectCurrency);
    let choices    = Object.keys(currPrices);
    if (choices.includes(payload.toString())) {
        yield put(setFinalPrice(currPrices[payload]))
    } else {
        try {
            let params = yield select(selectPriceParams);
            const res = yield call(
                axios.post,
                (BACKEND + '/price'),
                { params, multiple: false, qty: payload, currency }
            );
            let { success, finalPrice } = res.data;
            if (success) {
                yield put(setFinalPrice(finalPrice));
            }
        } catch(e) {
            yield console.log(e);
        }    
    }
}
function* interceptQtySetters() {
    yield takeLatest(
        [QUOTE_WORKFLOW_ACTIONS.SET_QUANTITY, QUOTE_WORKFLOW_ACTIONS.SET_CUSTOM_QUANTITY],
        handleQuantitySetters
    );
}




// get prices ------------------------------------------------------------------------
// there is no way for a user to see an instant change in prices, because the quote 
// modal must be closed in order to change the currency rate. the Redux action 
// getPrices() is fired on mount of prices, so handleGetPrices below will always be 
// fired.
function* handleGetPrices() {
    const params     = yield select(selectPriceParams);
    const currency   = yield select(selectCurrency);
    try {
        const res    = yield call(
            axios.post,
            (BACKEND + '/price'),
            { params, multiple: true, currency }
        );
        let { success, basePrice, pricesObj, usdPricesObj } = res.data;
        if (success) {
            yield put(setPrices({ basePrice, pricesObj }));
            yield put(setUsdPriceInfo({ ...usdPricesObj }));
        }
    } catch (e) {
        yield console.log(e);
    }

    // initial state won't have finalPrice, set prices here
    
}
function* interceptGetPrice() {
    yield takeLatest(QUOTE_WORKFLOW_ACTIONS.GET_PRICES, handleGetPrices)
}




// set currency ------------------------------------------------------------------------
function* handleSetCurrency() {
    // cart, basePrice, finalPrice, currPrice, usdPriceInfo

    let { basePrice, finalPrice, currPrices, usdPriceInfo } = yield select(selectConvertParams)
    let cart                 = yield select(selectCart);
    let currency             = yield select(selectCurrency);
    let { qty, isCustomQty } = yield select(selectQty)
    let userLoggedIn         = yield select(selectUserLoggedIn);

    // before modal starts
    if (finalPrice) {
        // after prices are set
        // usdPriceInfo is neccessarily populated if there is a final price
        // first update other parts of state 
        if (currency === USD && !finalPrice.includes('.')) {
            
            // update basePrice and pricesObj
            yield put(setPrices({
                basePrice: usdPriceInfo.basePrice,
                pricesObj: usdPriceInfo.currPrices
            }));

            // update finalPrice thru handleQuantitySetters saga above
            if (isCustomQty) {
                yield put(setCustomQuantity(qty));
            } else {
                yield put (setQuantity(qty));
            }
        } else if (currency === LBP && finalPrice.includes('.')) {
            // update finalPrice thru handleGetPrice saga above
            yield put(getPrices());
        }

        // update cart state (basePrice, finalPrice)
        let newCart = [];
        for (let i = 0; i < cart.length; i++) {
            let { apiData, finalQuantity, metal, thickness, fileData, unitsInput } = cart[i];
            let params = {
                perimeter: apiData.perimeter, finalQuantity, 
                metal, thickness, fileData, unitsInput
            };
            try {
                const res = yield call(
                    axios.post,
                    (BACKEND + '/price/cartupdate'),
                    { params, currency }
                );
                let { newFinalPrice, basePrice } = res.data;
                let cartItem = {
                    ...cart[i],
                    finalPrice: newFinalPrice,
                    basePrice: basePrice,
                    currency: currency
                }
                newCart[i] = cartItem;
            } catch (e) {
                yield console.log(e);
            }
        }
        yield put(updateCartPrices(newCart))
    }

    //update order state
    yield put(setOrderCurrency(userLoggedIn, currency))
}

function* interceptSetCurrency() {
    yield takeLatest(QUOTE_WORKFLOW_ACTIONS.SET_CURRENCY,
        handleSetCurrency
    );
}


export function* quoteWorkflowSagas() {
    yield all([
        call(interceptQtySetters),
        call(interceptGetPrice),
        call(interceptSetCurrency)
    ]);
}
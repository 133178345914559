import React from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect'
import { selectQuantity } from '../../../../redux/quote-workflow/quoteWorkflowSelectors';
import { setQuantity } from '../../../../redux/quote-workflow/quoteWorkflowActions';

 
function RadioChoice({ quantity, choice, setQuantity }) {

    return (
        <div>
            <input 
                type="radio" 
                value={choice} 
                checked={ quantity === choice ? true : false } 
                onChange={(e) => setQuantity(e.target.value)}
            />
            <span style={{marginLeft: '9px'}}>{choice}</span>
        </div>
    )
}

const mapStateToProps = createStructuredSelector(
    {
        quantity: selectQuantity
    }
);

function mapDispatchToProps(dispatch) {
    return {
        setQuantity: (string) => dispatch(setQuantity(string)),
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(RadioChoice);
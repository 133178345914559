import React from 'react'
import styles from './Elements.module.scss';
import linesEx from '../../../../assets/lines-ex.png';
function Overlap(props) {
    return (
        <div className={styles.wrapper}>
            <h2>Overlapping, intersecting or gaps are forbidden</h2>
            <p>
                Kindly check to make sure no lines in your file are touching each other. 
                Our machine will not interpret overlapping or connected lines.
            </p>
            <p>
                Also check if your design contains any gaps or open contours, the laser will not finish the cut correctly. 
                Make sure all lines are connected:
            </p>
            <img src={linesEx} alt="overlapping lines example"/>
        </div>
    )
}
 
export default Overlap;
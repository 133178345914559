import React, { useState } from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Table, Checkbox, Header, Button } from 'semantic-ui-react';
import { selectMetalsState, selectStaticSiteText } from '../../../../redux/app-state/appSelectors';
import uuid from 'react-uuid'
import axios from 'axios';
import { BACKEND } from '../../../../service/clientConstants';
import { FAIL_CONFIG } from '../../../../service/notifications';
import { store } from 'react-notifications-component';
import { setStaticSiteText } from '../../../../redux/app-state/appActions';
// must be updated when card names are shifted
function CardSelector({ metals, setStaticSiteText,  staticSiteText: { customCards, cardSelections }}) {
    const metalNames = metals.map(m => m.name).sort((a, b) => a.localeCompare(b));
    const cardNames  = customCards.map(c => c.name).sort((a, b) => a.localeCompare(b));
    let INITIAL_STATE = cardSelections;
    if (INITIAL_STATE === undefined) {
        let metalNamesObj = {}, cardNamesObj = {};
        for (let m of metalNames) {
            metalNamesObj[m] = false;
        }
        for (let m of cardNames) {
            cardNamesObj[m] = false;
        }
        INITIAL_STATE = {...metalNamesObj, ...cardNamesObj};
    }

    const [hidden, setHidden] = useState(true);
    const [state, setState]   = useState(INITIAL_STATE)
    const [inputChanged, setInputChanged] = useState(false);
    const [loading, setLoading]           = useState(false);
    
    function handleChange(e, data) {
        const { name, checked } = data;
        setState(prev => {
            let changedObj = { ...prev };
            changedObj[name] = checked;
            setInputChanged(Object.keys(changedObj).some(title => (changedObj[title] !== INITIAL_STATE[title]))); // checks if object is empty
            return changedObj;
        });
    }

    function handleUpdate() {
        let confirmed = window.confirm('Updating card choices will cause a page reload, and all unsaved changes will be lost. Continue?');
        if (confirmed) {
            setLoading(true);
            axios
            .patch((BACKEND + '/admin/text'), { state, purpose: 'cardSelectionsUpdate'})
            .then(res => {
                if (res.data.success) {
                    setStaticSiteText(res.data.siteText);
                } else {
                    FAIL_CONFIG.message = res.data.msg;
                    store.addNotification(FAIL_CONFIG);
                }
            })
        }
    }
    
    const Row = ({ name }) => {
        return (
            <Table.Row>
                <Table.Cell>{name}</Table.Cell>
                <Table.Cell>
                    <Checkbox
                        onChange={handleChange}
                        checked={state[name]}
                        name={name}
                        toggle
                    />
                </Table.Cell>
            </Table.Row>
        )
    }
    return (
        <div style={{width: '50%', margin: '7rem auto'}}>
            <Header as='h5' block>Choose Cards To Display</Header>
            {
                !hidden ? (
                    <>
                        <Table celled columns={2}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Metal Name</Table.HeaderCell>
                                    <Table.HeaderCell>Edit Show/Don't Show</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>  
                            <Table.Body>
                            {
                                metalNames.map((name => <Row key={uuid()} name={name}/>))
                            }
                            </Table.Body>      
                        </Table>

                        <Table celled>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Custom Card Name</Table.HeaderCell>
                                    <Table.HeaderCell>Edit Show/Don't Show</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>  
                            <Table.Body>
                            {
                                cardNames.map((name => <Row key={uuid()} name={name}/>))
                            }
                            </Table.Body>      
                        </Table>
                    </>
                ) : (null)
            }
            <div style={{marginTop: '1rem'}}>
                <Button
                    color="vk"
                    onClick={() => setHidden(!hidden)}>{hidden ? 'Show' : 'Hide'}</Button>
                <Button
                    color="vk"
                    onClick={handleUpdate}
                    loading={loading}
                    disabled={!inputChanged}>Update</Button>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    metals: selectMetalsState,
    staticSiteText: selectStaticSiteText
})

function mapDispatchToProps(dispatch) {
    return {
        setStaticSiteText: (siteText) => dispatch(setStaticSiteText(siteText))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(CardSelector);
import React from 'react'
import styles from './Elements.module.scss';
import negativeExImg from '../../../../assets/negative-ex.png'
import positiveExImg from '../../../../assets/positive-ex.png'

function SignDesign() {
    return (
        <div className={styles.wrapper}>


            <h1>Sign Design</h1>
            <p>
                You can cut your sign letters in 2 methods, Negative or Positive.
            </p>



            <h2>Negative Design</h2>
            <p>
                A “negative” design is when the laser removes the shape of your design from a sheet of metal. When your design is cut and removed from a sheet of metal, 
                the finished result will be easy to install because it is 1 part.            
            </p>
            <img src={negativeExImg} alt="negative design"/>



            <h2>Positive Design</h2>
            <p>
                A “positive” design is the opposite of a negative design; instead of cutting your design out of a sheet and keeping the sheet, the design is kept and the sheet discarded. 
                No bridges are needed to connect the letters but it will be more difficult to install it.
            </p>
            <p>
                The main problem will be to line up letters when you display it. The more you have separated parts to cut, 
                the harder it will be to install them.
            </p>
            <img src={positiveExImg} alt="positive design"/>
        </div>
    )
}
 
export default SignDesign;
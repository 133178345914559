export const APP_ACTIONS = {
    SET_ADMIN_LOGIN: "SET_ADMING_LOGIN",
    SET_IS_ONLINE: "SET_IS_ONLINE",
    SET_METALS_STATE: "SET_METALS_STATE",
    SET_STATIC_SITE_TEXT: "SET_STATIC_SITE_TEXT",
    SET_STATE_LOADED: "SET_STATE_LOADED",
    SET_DROPZONE_TEXT: "SET_DROPZONE_TEXT",
    SET_HOMEPAGE_INFO: "SET_HOMEPAGE_INFO", 
    UPDATE_METALS_STATE: "UPDATE_METALS_STATE",
    ADD_UPDATED_METAL: "ADD_UPDATED_METAL",
    SET_UPDATED_SITE_TEXT: "SET_UPDATED_SITE_TEXT",
    UPDATE_SITE_TEXT: "UPDATE_SITE_TEXT",
    HANDLE_ADD_DEL_CARD: "HANDLE_ADD_DEL_CARD",
    SET_FROM_BTN_CLICK: "SET_FROM_BTN_CLICK",
    SET_SHOW_DOLLARS_ONLY: "SET_SHOW_DOLLARS_ONLY"
}
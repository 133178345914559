import React from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { Dropdown } from 'semantic-ui-react';
import { setEditLocation } from '../../../../../redux/admin-state/adminActions';
import { setAdminLogin, updateMetalsState, updateSiteText } from '../../../../../redux/app-state/appActions';
import { selectAdminLoggedIn } from '../../../../../redux/app-state/appSelectors';
import s from './AdminDisplay.module.scss'

const AdminDisplay = ({ history, updateMetalsState, updateSiteText, setEditLocation, setAdminLogin }) => {
    const opts = [
        { key: 0, value: 'a', text: 'Go to Admin Panel' },
        { key: 1, value: 'b', text: 'Edit Quote Display' },
        { key: 2, value: 'c', text: 'Edit Home Page' },
        { key: 3, value: 'd', text: 'Edit Footer'},
        { key: 4, value: 'e', text: 'Log out'}
    ];
    
    const styles= {
        border: '8px solid #FFBB00'
    }

    const handleDropdownChange = (e, data) => {
        const { textContent } = e.currentTarget;
        if (textContent === opts[0].text) {
            history.push('/admin');
        } else if (textContent === opts[1].text || textContent === opts[2].text || textContent === opts[3].text) {
            setEditLocation(textContent);
            history.push('/admin')
        } else if (textContent === opts[4].text) {
            let confirmed = window.confirm('Are you sure you want to log out?');
            if (confirmed) {
                updateMetalsState();
                updateSiteText();
                setAdminLogin(false);
            }
        }
    }
    return (
        <div className={s.wrap}>
            <span>ADMIN MODE ACTIVE</span>
            <Dropdown placeholder='Admin Options' options={opts} onChange={handleDropdownChange} closeOnBlur selection />
        </div>
    )
}

function mapDispatchToProps(dispatch){
    return {
        updateMetalsState: () => dispatch(updateMetalsState()),
        updateSiteText: () => dispatch(updateSiteText()),
        setEditLocation: (string) => dispatch(setEditLocation(string)),
        setAdminLogin: (bool) => dispatch(setAdminLogin(bool))
    }
}

export default withRouter(connect(null, mapDispatchToProps)(AdminDisplay));
import React from 'react'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux';
import { 
    selectCustomQuantity, 
    selectQuantity, 
    selectUnitsInput, 
    selectApiData, 
    selectCartItem, 
    selectHasDimsInput,
    selectInputErr,
} from '../../../redux/quote-workflow/quoteWorkflowSelectors';
import { handleBackToMetals } from '../../../redux/quote-workflow/quoteWorkflowActions';
import { addToCart } from '../../../redux/order-state/orderActions';
import { selectMetalChoicesVisible, selectThicknessChoicesVisible, selectQuantityChoicesVisible } from '../../../redux/results-modal/resultsModalSelectors';
import {
    setMetalChoicesVisible,
    setThicknessChoicesVisible,
    setQuantityChoicesVisible,
    setResultsModalWorkflowStarted
} from '../../../redux/results-modal/resultsModalActions';
import { setCustomQuoteModalStarted } from '../../../redux/custom-quote-modal/customQuoteActions';
import OverviewSection from './_items/OverviewSection';
import styles from './MainPane.module.scss';
import CustomButton from '../../reusable/Button';
import { Checkmark } from 'react-checkmark'
import { ADD_TO_CART } from '../../../service/notifications';
import { store } from 'react-notifications-component';
import { withRouter } from 'react-router-dom'; 
import MetalChoices from './_items/sub-components/MetalChoices'
import ThicknessChoices from './_items/sub-components/ThicknessChoices'
import QuantityChoices from './_items/sub-components/QuantityChoices'
/* MainPane displays components of the workflow according to results-modal redux state
 * Components in the _items folder are display components */
function MainPane(props) {
    const { 
        handleBackToMetals, metalChoicesVisible, thicknessChoicesVisible, history,
        setMetalChoicesVisible, setThicknessChoicesVisible, setQuantityChoicesVisible, 
        quantityChoicesVisible, customQuantity, quantity, apiData, setCustomQuoteModalStarted,
        addToCart, cartItem, setResultsModalWorkflowStarted, hasDimsInput, inputErr,
        location
    } = props;
    
    // handles back CustomButton at bottom of pane
    // User can only go back to metals selection if thickness choices are visible
    function handleBackToMetalsSelection() {
        handleBackToMetals();
        setThicknessChoicesVisible(false);
        setQuantityChoicesVisible(false);
        setMetalChoicesVisible(true);
    }
    
    // handles close CustomButton
    function handleClose() {
        setResultsModalWorkflowStarted(false);
    }

    function handleAddToCart() {
        addToCart(cartItem);
        ADD_TO_CART.content   = (
            <div className={styles.directional}>
                <div className={styles.top}>
                    <div className={styles.contentWrap}>
                        <Checkmark style={{margin: "2rem auto"}} size='large'/>
                        <div className={styles.success}>Success! You've added this item to your cart.</div>
                        {
                            location.pathname === "/quote" ? (
                                <div className={styles.redirect}>Redirecting</div>
                            ) : (
                                null
                            )
                        }
                    </div>
                </div>
            </div>
        ); 
        ADD_TO_CART.className = styles.directional;
        const redirect = (id, removedBy) => {
            setResultsModalWorkflowStarted(false);
            history.push("/cart");
        }
        if (location.pathname === "/quote") {
            ADD_TO_CART.onRemoval = redirect;
        } else {
            ADD_TO_CART.onRemoval = (id, removedBy) => {
                setResultsModalWorkflowStarted(false);
            }
        }
        
        store.addNotification(ADD_TO_CART);
    }

    return (
        <div className={styles.wrapper}>
            <OverviewSection/>
            {
                metalChoicesVisible ? (
                    <MetalChoices/>
                ) : (null)
            }

            {
                thicknessChoicesVisible ? (
                        <div className={styles.choicesWrapper}>
                            <ThicknessChoices/>
                            {
                                quantityChoicesVisible ? (
                                    <QuantityChoices/>
                                ) : (null)
                            }
                        </div>
                ) : (null)
            }

            <div className={styles.btnSection}>
                <hr style={ hasDimsInput || thicknessChoicesVisible || metalChoicesVisible || quantityChoicesVisible ? {display: 'unset'} : {display: 'none'}}/>
                <div className={styles.navBtns}>
                    <CustomButton text='CLOSE' styleSize='small' onClick={handleClose}/>
                    { 
                        thicknessChoicesVisible ? (
                            <CustomButton 
                                style={{marginLeft: '1rem'}} 
                                text='BACK' 
                                styleSize='small' 
                                onClick={handleBackToMetalsSelection}
                            />
                        ) : (null)
                    }
                </div>
                {   
                    customQuantity || quantity ? (
                        apiData.perimeter > 0 && !inputErr ? (
                            <div className={styles.addToCart}>
                                <CustomButton 
                                    style={{backgroundColor: 'red'}} 
                                    text='ADD TO CART' 
                                    styleSize='small' 
                                    onClick={handleAddToCart}
                                    disabled={!hasDimsInput}
                                />
                            </div>
                        ) : (
                            <div className={styles.addToCart}>
                                <CustomButton 
                                    style={{backgroundColor: 'red'}} 
                                    text='REQUEST CUSTOM QUOTE' 
                                    styleSize='small' 
                                    onClick={() => setCustomQuoteModalStarted(true)}
                                    disabled={!hasDimsInput}
                                />
                            </div>
                        )
                    ) : (null)
                }
            </div>
        </div>
    )
}


const mapStateToProps = createStructuredSelector(
    {
        metalChoicesVisible: selectMetalChoicesVisible,
        thicknessChoicesVisible: selectThicknessChoicesVisible,
        quantityChoicesVisible: selectQuantityChoicesVisible,
        customQuantity: selectCustomQuantity,
        quantity: selectQuantity,
        unitsInput: selectUnitsInput,
        apiData: selectApiData,
        cartItem: selectCartItem,
        hasDimsInput: selectHasDimsInput,
        inputErr: selectInputErr,
    }
)

function mapDispatchToProps(dispatch) {
    return {
        setMetalChoicesVisible: (bool) => dispatch(setMetalChoicesVisible(bool)),
        setThicknessChoicesVisible: (bool) => dispatch(setThicknessChoicesVisible(bool)),
        setQuantityChoicesVisible: (bool) => dispatch(setQuantityChoicesVisible(bool)),
        handleBackToMetals: () => dispatch(handleBackToMetals()),
        setCustomQuoteModalStarted: (bool) => dispatch(setCustomQuoteModalStarted(bool)),
        addToCart: (obj) => dispatch(addToCart(obj)),
        setResultsModalWorkflowStarted: (bool) => dispatch(setResultsModalWorkflowStarted(bool))
    }
}
 
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MainPane));
import React from 'react'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux';
import { selectMetal } from '../../../../redux/quote-workflow/quoteWorkflowSelectors';
import { setThickness } from '../../../../redux/quote-workflow/quoteWorkflowActions';
import { setMetalChoicesVisible, setThicknessChoicesVisible, setQuantityChoicesVisible } from '../../../../redux/results-modal/resultsModalActions'
import styles from './ThicknessSelector.module.scss';

// Rectangular component for selecting metal's thickness. dim is the dimension in mm
// metal is the currently selected metal from rdx store
// all props except thickness are from RDX; entry is [thickness, speed]

function ThicknessSelector({ thickness, dim, metal, setThickness, setQuantityChoicesVisible, imgUrl }) {
    let hexColor = '';
    if (metal.name.toUpperCase().includes('ALUMINUM')) {
        hexColor = '#cccccc';
    } else if (metal.name.toUpperCase().includes('BRASS')) {
        hexColor = '#f5ce62';
    } else if (metal.name.toUpperCase().includes('STAINLESS STEEL')) {
        hexColor = '#adadad';
    } else if (metal.name.toUpperCase().includes('COPPER')) {
        hexColor = '#c56a0d';
    } else if (metal.name.toUpperCase().includes('STEEL')) {
        hexColor = '#8a8580';
    } else if (metal.name.toUpperCase.includes('ZINC')) {
        hexColor = '#7c7d85';
    } else {
        hexColor = '#969696'
    }

    // when rectangle is clicked, display the quantity selector area
    function handleClick() {
        setThickness(dim)
        setQuantityChoicesVisible(true);
    }
    return (
        <div className={ dim === thickness ? styles.selectedWrapper : styles.wrapper} onClick={handleClick}>
            {
                imgUrl ? (
                    <div className={styles.imgWrap}>
                        <img className={styles.img} src={imgUrl} alt="thumbnail"/>
                    </div>
                ) : (
                    <div style={{backgroundColor: hexColor}} className={styles.colorPrev}/>
                )
            }
            <div className={styles.text}>{dim}</div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector(
    {
        metal: selectMetal
    }
)

function mapDispatchToProps(dispatch) {
    return {
        setThickness: (string) => dispatch(setThickness(string)),
        setMetalChoicesVisible: (bool) => dispatch(setMetalChoicesVisible(bool)),
        setThicknessChoicesVisible: (bool) => dispatch(setThicknessChoicesVisible(bool)),
        setQuantityChoicesVisible: (bool) => dispatch(setQuantityChoicesVisible(bool))
    }
}
 
export default connect(mapStateToProps, mapDispatchToProps)(ThicknessSelector);

import React, { useEffect, useState } from 'react'
import ManageAdminUsers from './admin_elements/ManageAdminUsers';
import ManageQuotePage from './admin_elements/ManageQuotePage';
import ManageHomePage from './admin_elements/ManageHomePage';
import ManageTables from './admin_elements/ManageTables';
import ManageChoices from './admin_elements/ManageChoices';
import ManagePricing from './admin_elements/ManagePricing';
import FooterLinksTable from './admin_elements/sub-components/FooterLinksTable'
import { connect } from 'react-redux';
import { selectActiveBtnName } from '../../../redux/admin-state/adminSelectors';
import { motion } from 'framer-motion'
import { createStructuredSelector } from 'reselect'
import s from './Admin.module.scss';
import { ADMIN_PAGE_CONSTANTS, BASIC_TRANSITION } from '../../../service/clientConstants';
import { setPricingVars } from '../../../redux/admin-state/adminActions';
import { BACKEND } from '../../../service/clientConstants'
import axios from 'axios'
import PanelButtons from './admin_elements/sub-components/PanelButtons';
// match and history come from withRouter, setAdminLogin from connect
function Admin({ activeButtonName, setPricingVars }) {
    const [pricingLoaded, setPricingLoaded] = useState(false);
    const [width, setWidth]                   = useState(0);

    useEffect(() => {
        const getState = async () => {
            const [res1] = await Promise.all([
                axios.get(BACKEND + '/admin/pricing'),
            ]);
            await setPricingVars(res1.data.pricingVars);
            setPricingLoaded(true);
        };
        getState();
        return () => {
            setPricingVars({})
        }
    }, [setPricingLoaded]);

    function updateDims() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        updateDims();
        window.addEventListener('resize', updateDims);
    }, []);

    const Bottom = () => {
        if (activeButtonName === ADMIN_PAGE_CONSTANTS.MANAGE_ADMIN) {
            return <ManageAdminUsers/>
        } else if (activeButtonName === ADMIN_PAGE_CONSTANTS.MANAGE_QUOTE_PAGE) {
            return <ManageQuotePage/>
        } else if (activeButtonName === ADMIN_PAGE_CONSTANTS.MANAGE_HOME_PAGE) {
            return <ManageHomePage/>
        } else if (activeButtonName === ADMIN_PAGE_CONSTANTS.MANAGE_TABLES) {
            return <ManageTables/>
        } else if (activeButtonName === ADMIN_PAGE_CONSTANTS.MANAGE_QTY_CHOICES) {
            return <ManageChoices/>
        } else if (activeButtonName === ADMIN_PAGE_CONSTANTS.MANAGE_PRICING) {
            return <ManagePricing/>
        } else if (activeButtonName === ADMIN_PAGE_CONSTANTS.MANAGE_FOOTER) {
            return <FooterLinksTable/>
        } else {
            return null
        }
    }

    const style = {
        height: '1000px',
        display: 'inline-table'
    };
    if (activeButtonName === ADMIN_PAGE_CONSTANTS.MANAGE_TABLES) {
        style.display = 'inline-block';
        style.height  = 'unset';
    }


    return (
        pricingLoaded ? (
            <motion.div
                key="adminPanel"
                exit="exit" 
                animate="visible"
                initial="hidden"
                variants={BASIC_TRANSITION} 
                className={s.wrapper}
                style={style}
            >
                <PanelButtons/>
                <div className={s.mainSection} style={width < 1024 ? {marginRight: '0px'} : {}} >
                    <h1>Admin Page</h1>
                    <Bottom/>
                </div>
            </motion.div>
        ) : (null)
    )
}

const mapStateToProps = createStructuredSelector({
    activeButtonName: selectActiveBtnName,
})

function mapDispatchToProps(dispatch) {
    return {
        setPricingVars: (vars) => dispatch(setPricingVars(vars)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Admin);
import React from 'react'
import styles from './Elements.module.scss';
import FileReqsEx from '../../../../assets/file-requirements-ex.png';
function FileRequirements(props) {
    return (
        <div className={styles.wrapper}>
            <h1>File Requirements</h1>
            <p>
                Here are some requirements that must be built into your design in order for us to cut it:
            </p>
            <h2>On Scale Drawing</h2>

            <p>
            The design must be sent to us the exact size you want it cut (1:1 scale). If any
                <em> dimensions in the drawing are present it is better to delete them.</em>
            </p>

            <p>
                Please make sure your design is in metric scaling (mm or cm only). Kindly verify the dimensions of your file. 
                If you have doubt you can print the design to make sure it is scaled the way you want it.
            </p>

            <img src={FileReqsEx} alt="correct scaling example"/>
        </div>
    )
}
 
export default FileRequirements;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect'
import { withRouter } from 'react-router-dom';
import { Button, Form, FormField, Table, Label, Input, TextArea } from 'semantic-ui-react';
import { selectCart, selectCartSubtotal } from '../../../../../redux/order-state/orderSelectors';
import { selectUserLoggedIn } from '../../../../../redux/app-state/appSelectors';
import { setFromButtonClick } from '../../../../../redux/app-state/appActions';
import { selectCurrency, selectVat } from '../../../../../redux/quote-workflow/quoteWorkflowSelectors';
import { SUCCESS_CONFIG } from '../../../../../service/notifications';
import { setOrder, setPriceData } from '../../../../../redux/order-state/orderActions'
import { round } from '../../../../../service/helpers'
import ItemEntry from './ItemEntry';
import s from './CheckoutForm.module.scss';
import { LBP } from '../../../../../service/clientConstants';


function CheckoutForm({ cart, cartSubtotal, userLoggedIn, setOrder, setPriceData, vat, history, setFromButtonClick, currency }) {
    SUCCESS_CONFIG.message                = "Everything was submitted successfully! Expect an email or call soon."
    // state for form inputs
    const [firstName, setFirstName]       = useState('');
    const [lastName, setLastName]         = useState('');
    const [email, setEmail]               = useState('');
    const [company, setCompany]           = useState('');
    const [phone, setPhone]               = useState("");
    const [note, setNote]                 = useState('');
    const [phoneErr, setPhoneErr]         = useState(false);
    const [hasDiscount, setHasDiscount]   = useState(false);
    const [inputErr, setInputErr]         = useState(false);
    //const re                              = new RegExp('^[0-9]{2}-[0-9]{3}-[0-9]{3}$');
    let cartVat, totalPrice;
    if (currency === LBP) {
        cartVat    = round((cartSubtotal * vat), 0);
        totalPrice = round((cartVat + cartSubtotal), 0);
    } else {
        cartVat    = round((cartSubtotal * vat), 2);
        totalPrice = round((cartVat + cartSubtotal), 2).toFixed(2); 
    }
    const priceData                       = { subTotal: cartSubtotal, vat: cartVat, totalPrice: totalPrice };
    // const inputRe = /^[0-9\b\-]{0,10}$/; // lebanese phone number regex w/ dashes
    const numRegex                        = /^[+0-9]+$/;

    useEffect(() => {
        cart.forEach(item => {
            if (item.discount) {
                setHasDiscount(true);
            }
        })
    }, [])

    useEffect(() => {
        if (firstName && lastName && email) {
            setInputErr(false);
        } else {
            setInputErr(true);
        }
    }, [firstName, lastName, email])


    function handleSubmit(e) {
        setFromButtonClick(true);
        setOrder({ firstName, lastName, email, company, phone, note }, userLoggedIn);
        setPriceData(priceData);
        history.push("/receiptPage");
    }

    function handleOnChange(e) {
        const { value } = e.target;
        if (e.target.name === "phone") {
            if (!value) {
                setPhone(value);
                setPhoneErr(false);
            }
            if (numRegex.test(value)) {
                setPhone(value);
            }
        } 
        switch (e.target.name) {
            case "fName":
                setFirstName(value)
                return
            case "lName":
                setLastName(value);
                return
            case "email":
                setEmail(value)
                return
            case "company":
                setCompany(value)
                return
            case "phone":
                // if (value === '' || inputRe.test(value)) {
                //     if (value[value.length - 1] === "-") { // deleting text
                //         setPhone(value.slice(0, -1))
                //     } else if (value.length === 2 || value.length === 6) { // adding text
                //         setPhone(value + '-')
                //     } else {
                //         setPhone(value);
                //     }
                // }
                return
                
            case "note":
                setNote(value);
                return
            default:
                return
        }
    }

    return (
        <div>
            <h1 className={s.title}>Checkout</h1>
            <Form className={s.wrapper} name="submissionForm" id="submissionForm" onSubmit={handleSubmit}>
                <Form.Group>
                    <FormField style={{width: '50%'}}>
                        <label>First Name</label>
                        <input type="text" name="fName" placeholder='name' onChange={handleOnChange} required/>
                    </FormField>
                    <FormField style={{width: '50%'}}>
                        <label>Last Name</label>
                        <input type="text" name="lName" placeholder='name' onChange={handleOnChange} required/>
                    </FormField>
                </Form.Group>
                
                <FormField>
                    <label>Email</label>
                    <input type="email" name="email" placeholder='user@example.com' onChange={handleOnChange} required/>
                </FormField>
                <FormField>
                    <label>Phone</label>
                    <Input 
                        type="text" 
                        name="phone"
                        value={phone}
                        placeholder='e.g., +96199999999'        
                        onChange={handleOnChange}
                        error={phoneErr}
                        required
                    />
                    {
                        phoneErr ? <Label color="red" pointing='above' className={s.labelMsg}>Please enter a valid phone.</Label> : null
                    }
                </FormField>
                <FormField>
                    <label>Company</label>
                    <input type="text" name="company" placeholder='company name' onChange={handleOnChange}/>
                </FormField>
                <FormField>
                    <label>Notes</label>
                    <TextArea name="note" value={note} onChange={handleOnChange}/>
                </FormField>
            </Form>

            {
                !inputErr ? (
                    <div className={s.thankyou}>Thank you {firstName}! We look forward to working with you.</div>
                ) : (
                    <div className={s.thankyou}></div>
                )
            }
            <div className={s.tableContainer}>
                <Table striped unstackable className={s.tbl}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell textAlign='center'>File</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Dimensions</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Metal</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Thickness</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Quantity</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Unit Price</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Discount</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Price</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
    
                    <Table.Body>
                    {
                        cart.map((item, idx) => <ItemEntry key={idx} idx={idx} {...item}/>)
                    }
                    </Table.Body>
                </Table>
            </div>
            {
                hasDiscount ? (
                    <>
                        <div className={s.finalPrice}>
                            Subtotal: <span>{currency === LBP ? `LBP ${cartSubtotal.toLocaleString()}` : `$${parseFloat(cartSubtotal).toFixed(2)}`}</span>
                        </div>

                        <div className={s.finalPrice}>
                            VAT: <span>{currency === LBP ? `LBP ${cartVat.toLocaleString()}` : `$${parseFloat(cartVat).toFixed(2)}`}</span>
                        </div>
                        <div className={s.finalPrice}>
                            Total Price with discount: <span style={{color: 'green'}}>{currency === LBP ? `LBP ${totalPrice.toLocaleString()}` : `$${parseFloat(totalPrice).toFixed(2)}`}</span>
                        </div>
                    </>
                ) : (
                    <>
                        <div className={s.finalPrice}>
                            Subtotal: <span>{currency === LBP ? `LBP ${cartSubtotal.toLocaleString()}` : `$${parseFloat(cartSubtotal).toFixed(2)}`}</span>
                        </div>

                        <div className={s.finalPrice}>
                            VAT: <span>${currency === LBP ? `LBP ${cartVat.toLocaleString()}` : `$${parseFloat(cartVat).toFixed(2)}`}</span>
                        </div>
                        <div className={s.finalPrice}>
                            Total Price: <span>{currency === LBP ? `LBP ${totalPrice.toLocaleString()}` : `$${parseFloat(totalPrice).toFixed(2)}`}</span>
                        </div>
                    </>                
                )
            }
            <div className={s.btnWrapper}>
                <Button 
                    form='submissionForm' 
                    type='submit'
                    color={'yellow'} 
                    disabled={!!inputErr}
                    style={{float: "right"}}
                >Submit</Button>
            </div>  
        </div>
    )
}


function mapDispatchToProps (dispatch) {
    return {
        setOrder: (orderObj, bool) => dispatch(setOrder(orderObj, bool)),
        setPriceData: (obj) => dispatch(setPriceData(obj)),
        setFromButtonClick: (bool) => dispatch(setFromButtonClick(bool))
    }
}

const mapStateToProps = createStructuredSelector(
    {
        cart: selectCart,
        cartSubtotal: selectCartSubtotal,
        userLoggedIn: selectUserLoggedIn,
        vat: selectVat,
        currency: selectCurrency
    }
);


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CheckoutForm));
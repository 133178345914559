import { QUOTE_WORKFLOW_ACTIONS } from './actionTypes';

export function setUsdPriceInfo(obj) {
    return {
        type: QUOTE_WORKFLOW_ACTIONS.SET_USD_PRICE_INFO,
        payload: obj
    }
}




export function setCurrency(str) {
    return {
        type: QUOTE_WORKFLOW_ACTIONS.SET_CURRENCY,
        payload: str
    }
}



export function setPrices(prices) {
    return {
        type: QUOTE_WORKFLOW_ACTIONS.SET_PRICES,
        payload: prices
    }
}


export function resetPrices() {
    return {
        type: QUOTE_WORKFLOW_ACTIONS.RESET_PRICES
    }
}



export function getPrices() {
    return {
        type: QUOTE_WORKFLOW_ACTIONS.GET_PRICES
    }
}


export function setFinalPrice(price) {
    return {
        type: QUOTE_WORKFLOW_ACTIONS.SET_FINAL_PRICE,
        payload: price
    }
}



export function setInputErr(bool) {
    return {
        type: QUOTE_WORKFLOW_ACTIONS.SET_INPUT_ERR,
        payload: bool
    }
}



export function setVat(vat) {
    return {
        type: QUOTE_WORKFLOW_ACTIONS.SET_VAT,
        payload: vat
    }
}



export function handleDimsChange() {
    return {
        type: QUOTE_WORKFLOW_ACTIONS.DIMS_RESET,
    }
}


export function setIsCustomQuote(bool) {
    return {
        type: QUOTE_WORKFLOW_ACTIONS.SET_IS_CUSTOM_QUOTE,
        payload: bool
    }
}



export function setFileLength(height) {
    return {
        type: QUOTE_WORKFLOW_ACTIONS.SET_FILE_LENGTH,
        payload: height
    }
}


export function setFileWidth(width) {
    return {
        type: QUOTE_WORKFLOW_ACTIONS.SET_FILE_WIDTH,
        payload: width
    }
}



export function setUnitsInput(units) {
    return {
        type: QUOTE_WORKFLOW_ACTIONS.SET_UNITS_INPUT,
        payload: units
    }
}

export function setFileReadyToSubmit(bool) {
    return {
        type: QUOTE_WORKFLOW_ACTIONS.SET_FILE_READY_TO_SUBMIT,
        payload: bool
    }
}

export function setApiDataLoading(bool) {
    return {
        type: QUOTE_WORKFLOW_ACTIONS.SET_API_DATA_LOADING,
        payload: bool
    }
}

export function setApiData(obj) {
    return {
        type: QUOTE_WORKFLOW_ACTIONS.SET_API_DATA,
        payload: obj
    }
}

export function setFileData(dataObj) {
    return {
        type: QUOTE_WORKFLOW_ACTIONS.SET_FILE_DATA,
        payload: dataObj
    }
}

export function setFile(file) {
    return {
        type: QUOTE_WORKFLOW_ACTIONS.SET_FILE,
        payload: file
    }
}

export function setMetalType(string) {
    return {
        type: QUOTE_WORKFLOW_ACTIONS.SET_METAL_TYPE,
        payload: string
    }
}

export function setThickness(string) {
    return {
        type: QUOTE_WORKFLOW_ACTIONS.SET_THICKNESS,
        payload: string
    }
}


export function setQuantity(string) {
    return {
        type: QUOTE_WORKFLOW_ACTIONS.SET_QUANTITY,
        payload: string
    }
}

export function resetInputs() {
    return {
        type: QUOTE_WORKFLOW_ACTIONS.RESET_INPUTS,
    }
}


export function setCustomQuantity(quantity) {
    return {
        type: QUOTE_WORKFLOW_ACTIONS.SET_CUSTOM_QUANTITY,
        payload: quantity
    }
}


export function handleBackToMetals() {
    return {
        type: QUOTE_WORKFLOW_ACTIONS.BACK_TO_METALS,
    }
}



export function setPercentLoaded(num) {
    return {
        type: QUOTE_WORKFLOW_ACTIONS.SET_PERCENT_LOADED,
        payload: num
    }
}

export function setLoadingUpdate(update) {
    return {
        type: QUOTE_WORKFLOW_ACTIONS.SET_LOADING_UPDATE,
        payload: update
    }
}

export function endWorkflow() {
    return {
        type: QUOTE_WORKFLOW_ACTIONS.END_WORKFLOW,
    }
}


import React, { useState } from 'react'
import styles from './MaterialCard.module.scss';
import { Button } from 'semantic-ui-react'
import { useLocation, withRouter } from 'react-router-dom'
import RichTextEditor from '../admin_elements/RichTextEditor'
import { IMG_CARD_TOOLBAR, BTM_CARD_TOOLBAR, SANITIZE_OPTS } from '../../../../service/clientConstants'
import { createStructuredSelector } from 'reselect';
import { selectAdminLoggedIn } from '../../../../redux/app-state/appSelectors';
import { connect } from 'react-redux';
import parse from 'html-react-parser'
import { formatHtml } from '../../../../service/helpers';
import sanitizeHtml from 'sanitize-html'

function MaterialCard({ cardImgHtml, name, cardBottomHtml, history, adminLoggedIn }) {
    const title                       = formatTitle(name);
    const cleanCardImgHtml            = sanitizeHtml(cardImgHtml, SANITIZE_OPTS);
    const cleanCardBottomHtml         = sanitizeHtml(cardBottomHtml, SANITIZE_OPTS);
    const [imgHtml, setImgHtml]       = useState(cleanCardImgHtml);
    const [bottomHtml, setBottomHtml] = useState(cleanCardBottomHtml);


    function updateCardState(html, destination) {
        if (destination === 'metalCardImg') {
            setImgHtml(sanitizeHtml(html, SANITIZE_OPTS));
        } else if (destination === 'metalCardBottom') {
            setBottomHtml(sanitizeHtml(html, SANITIZE_OPTS));
        }
    }

    function formatTitle(str) {
        return str.replace(/(^|\s)\S/g, function(t) { return t.toUpperCase() });
    }
    const location = useLocation();
    return (
        <div className={adminLoggedIn ? styles.adminWrap : styles.wrapper}>
            {
                imgHtml ? parse(imgHtml, {
                    replace: formatHtml
                }) : null
            }
            {
                adminLoggedIn ? (
                    <div>
                        <RichTextEditor
                            currHtml={imgHtml ? imgHtml : null}
                            destination='metalCardImg'
                            height='250'
                            toolbar={IMG_CARD_TOOLBAR}
                            metalName={name}
                            setParentHtml={updateCardState}
                        />
                    </div>
                ) : (null)
            }
            <h1>
            {
                title ? title : null
            }
            </h1>
            {
                bottomHtml ? parse(bottomHtml, {
                    replace: formatHtml
                }) : null
            }
            {
                adminLoggedIn ? (
                    <div>
                        <RichTextEditor
                            currHtml={bottomHtml ? bottomHtml : null}
                            destination='metalCardBottom'
                            height='300'
                            toolbar={BTM_CARD_TOOLBAR}
                            metalName={name}
                            setParentHtml={updateCardState}
                        />
                    </div>
                ) : (null)
            }
            <Button 
                className={adminLoggedIn ? styles.adminBtn : styles.button}
                size="large"
                onClick={() => history.push(`${location.pathname}/${name}`)}
            >Learn More</Button>
        </div>
    )
}

const mapStateToProps = createStructuredSelector(
    {
        adminLoggedIn: selectAdminLoggedIn
    }
)
 
export default withRouter(connect(mapStateToProps)(MaterialCard));
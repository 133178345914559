import React, { useState } from 'react'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux';
import { selectCart } from '../../../redux/order-state/orderSelectors';
import { setCheckoutModalStarted } from '../../../redux/order-state/orderActions';
import { setResultsModalWorkflowStarted } from '../../../redux/results-modal/resultsModalActions';
import { selectApiData, selectPercentLoaded } from '../../../redux/quote-workflow/quoteWorkflowSelectors'
import { resetInputs } from '../../../redux/quote-workflow/quoteWorkflowActions';
import { resetResultsModal } from '../../../redux/results-modal/resultsModalActions';
import { Link } from 'react-router-dom';
import CartItem from './cart_elements/CartItem';
import { Table } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom';
import { BASIC_TRANSITION } from '../../../service/clientConstants'
import { Button } from 'semantic-ui-react';
import s from './Cart.module.scss';
import CheckoutModal from './cart_elements/CheckoutModal';
import ResultsModal from '../../workflow/_results-modal/ResultsModal';
import useBodyClassChange from '../../../service/helpers'
import CustomQuoteModal from '../../workflow/_custom-quote-modal/CustomQuoteModal'
import { motion } from 'framer-motion'
import { selectAdminLoggedIn } from '../../../redux/app-state/appSelectors';

function Cart({ cart,adminLoggedIn, setCheckoutModalStarted, setResultsModalWorkflowStarted, apiData, percentLoaded, history, resetInputs, resetResultsModal }) {
    useBodyClassChange(adminLoggedIn);

    const [width, setWidth]   = useState(window.innerWidth);
    const IPHONE_12_MAX_WIDTH     = 428;
    const mobileStyles = {
        height: '4rem',
        textAlign: 'left',
        paddingLeft: '1rem'
    }
    const normalStyles = {
        height: '4rem'
    }
    function resetSession() {
        resetResultsModal();
        resetInputs();
        history.push("/quote");
    }
    return (
        <motion.div variants={BASIC_TRANSITION} animate="visible" exit="exit" initial="hidden">
            <div className={s.wrapper}>
                <Table striped unstackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell textAlign='center'>File</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Dimensions</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Metal</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Thickness</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Quantity</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Unit Price</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Discount</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Total Price</Table.HeaderCell>
                            <Table.HeaderCell textAlign='center'>Remove</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                    {
                        cart.length > 0 ? (
                            cart.map((item, idx) => <CartItem key={idx} idx={idx} {...item}/>)
                        ) : (
                            <Table.Row>
                                <Table.HeaderCell 
                                    textAlign="center" 
                                    colSpan={10} 
                                    style={width <= IPHONE_12_MAX_WIDTH ? mobileStyles : normalStyles}
                                >
                                    There are no items in your cart.
                                    Click 
                                    <Link to="/quote"> here</Link> to quote a file.
                                </Table.HeaderCell>
                            </Table.Row>
                        )
                    }
                    </Table.Body>
                </Table>

            </div>
            <div className={s.btnWrapper}>
                {
                    cart.length > 0 ? (
                        <>
                            <Button className={s.checkoutBtn} color="yellow" onClick={() => setCheckoutModalStarted(true)}>Proceed to checkout</Button>
                            <Button className={s.checkoutBtn} color="vk" onClick={resetSession}>Quote another file</Button>
                        </>
                    ) : (null)
                }
                {
                    apiData.perimeter > 0 || apiData.error || percentLoaded === 100 ? (
                        <Button className={s.resultsBtn} onClick={() => setResultsModalWorkflowStarted(true)}>View quote results</Button>
                    ) : (
                        null
                    )
                }       
                <CheckoutModal/>
                <ResultsModal/>
                <CustomQuoteModal/>
            </div>

        </motion.div>
    )
}
 
const mapStateToProps = createStructuredSelector(
    {
        cart: selectCart,
        apiData: selectApiData,
        percentLoaded: selectPercentLoaded,
        adminLoggedIn: selectAdminLoggedIn
    }
);

function mapDispatchToProps(dispatch) {
    return {
        setCheckoutModalStarted: (bool) => dispatch(setCheckoutModalStarted(bool)),
        setResultsModalWorkflowStarted: (bool) => dispatch(setResultsModalWorkflowStarted(bool)),
        resetInputs: () => dispatch(resetInputs()),
        resetResultsModal: () => dispatch(resetResultsModal())
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cart));
import React, { useState } from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Table, Input, Button, Checkbox } from 'semantic-ui-react'
import { selectStaticSiteText } from '../../../../../redux/app-state/appSelectors';
import { setStaticSiteText } from '../../../../../redux/app-state/appActions';
import axios from 'axios'
import { BACKEND, FOOTER_LINKS_INITIAL, FOOTER_SHOW_INITIAL } from '../../../../../service/clientConstants'
import { FAIL_CONFIG, SUCCESS_CONFIG } from '../../../../../service/notifications';
import { store } from 'react-notifications-component';
function FooterLinksTable({ staticSiteText, setStaticSiteText }) {

    let footerLinks, footerShow, whatsappNum;
    if (staticSiteText.footerInfo) {
        if (staticSiteText.footerInfo.footerLinks) {
            footerLinks = staticSiteText.footerInfo.footerLinks;
        }
        if (staticSiteText.footerInfo.footerShow) {
            footerShow = staticSiteText.footerInfo.footerShow;
        }
        if (staticSiteText.footerInfo.whatsappNum) {
            whatsappNum = staticSiteText.footerInfo.whatsappNum;
        }
    }
    const [newFooterShow, setNewFooterShow]   = useState(footerShow  ? footerShow  : FOOTER_SHOW_INITIAL);
    const [newFooterLinks, setNewFooterLinks] = useState(footerLinks ? footerLinks : FOOTER_LINKS_INITIAL );
    const [inputEmpty, setInputEmpty]         = useState(true);
    const [loading, setLoading]               = useState(false);
    const [newWhatsappNum, setNewWhatsappNum] = useState("");

    function handleSubmitSelections() {
        let purpose = 'footerEdit';
        setLoading(true);
        axios
        .patch(BACKEND + '/admin/text', { purpose, newFooterLinks, newFooterShow })
        .then(res => {
            if (res.data.success) {
                setStaticSiteText(res.data.siteText);
                SUCCESS_CONFIG.message = res.data.msg;
                store.addNotification(SUCCESS_CONFIG);
            } else {
                FAIL_CONFIG.message = res.data.msg;
                store.addNotification(FAIL_CONFIG);
            }
            setLoading(false);
        })
        .catch(e => console.log(e))
    }

    function handleEditLinks(e) {
        let { name, value } = e.target;
        setNewFooterLinks(prevLinks => {
            let obj = { ...prevLinks };
            obj[name] = value;
            setInputEmpty(!Object.values(obj).some(x => (x !== null && x !== ''))); // checks if object is empty
            return obj;
        });
    }

    function handleShowChange(e, data) {
        const { name, checked } = data;
        setNewFooterShow(prevOpts => {
            let obj = { ...prevOpts };
            obj[name] = checked;
            let currShowVals;
            if (!footerShow) {
                currShowVals = Object.values(FOOTER_SHOW_INITIAL);
            } else {
                currShowVals = Object.values(footerShow);
            }
            setInputEmpty(!Object.values(obj).some((x, idx) => (x !== currShowVals[idx]))); // checks if object is empty
            return obj;
        });
    }


    function handleNumberUpdate() {
        let purpose = 'whatsappUpdate'
        axios
        .patch(BACKEND + '/admin/text', { purpose, newWhatsappNum })
        .then(res => {
            if (res.data.success) {
                setStaticSiteText(res.data.siteText);
                setNewWhatsappNum("");
                SUCCESS_CONFIG.message = res.data.msg;
                store.addNotification(SUCCESS_CONFIG);
            } else {
                FAIL_CONFIG.message = res.data.msg;
                store.addNotification(FAIL_CONFIG);
            }
        })
    }



    return (
        footerLinks ? (
            <>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={7}>Edit Links</Table.HeaderCell>
                            <Table.HeaderCell width={7}>Current Value</Table.HeaderCell>
                            <Table.HeaderCell>Edit Show/Don't Show</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>
                                <Input 
                                    name="facebook" 
                                    value={newFooterLinks.facebook} 
                                    type="text" 
                                    onChange={handleEditLinks}
                                    placeholder="enter updated link"
                                    fluid
                                    label='Facebook'
                                />
                            </Table.Cell>
                            <Table.Cell>{footerLinks.facebook}</Table.Cell>
                            <Table.Cell>
                                <Checkbox
                                    onChange={handleShowChange}
                                    checked={newFooterShow.facebook}
                                    name="facebook"
                                    toggle
                                />
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>
                                <Input 
                                    name="instagram" 
                                    value={newFooterLinks.instagram} 
                                    type="text" 
                                    onChange={handleEditLinks}
                                    placeholder="enter updated link" 
                                    fluid
                                    label='Instagram'

                                />
                            </Table.Cell>
                            <Table.Cell>{footerLinks.instagram}</Table.Cell>
                            <Table.Cell>
                                <Checkbox
                                    onChange={handleShowChange}
                                    checked={newFooterShow.instagram}
                                    name="instagram"
                                    toggle
                                />
                            </Table.Cell>
                        </Table.Row>
                
                        <Table.Row>
                            <Table.Cell>
                                <Input 
                                    name="twitter" 
                                    value={newFooterLinks.twitter} 
                                    type="text" 
                                    onChange={handleEditLinks}
                                    placeholder="enter updated link" 
                                    fluid
                                    label="Twitter" 
                                />
                            </Table.Cell>
                            <Table.Cell>{footerLinks.twitter}</Table.Cell>
                            <Table.Cell>
                                <Checkbox
                                    onChange={handleShowChange}
                                    checked={newFooterShow.twitter}
                                    name="twitter"
                                    toggle
                                />
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>
                                <Input 
                                    name="youtube" 
                                    value={newFooterLinks.youtube} 
                                    type="text" 
                                    onChange={handleEditLinks}
                                    placeholder="enter updated link" 
                                    fluid
                                    label="YouTube" 
                                />
                            </Table.Cell>
                            <Table.Cell>{footerLinks.youtube}</Table.Cell>
                            <Table.Cell>
                                <Checkbox
                                    onChange={handleShowChange}
                                    checked={newFooterShow.youtube}
                                    name="youtube"
                                    toggle
                                />
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>
                                <Input 
                                    name="pinterest" 
                                    value={newFooterLinks.pinterest} 
                                    type="text" 
                                    onChange={handleEditLinks}
                                    placeholder="enter updated link" 
                                    fluid
                                    label="Pinterest" 
                                />
                            </Table.Cell>
                            <Table.Cell>{footerLinks.pinterest}</Table.Cell>
                            <Table.Cell>
                                <Checkbox
                                    onChange={handleShowChange}
                                    checked={newFooterShow.pinterest}
                                    name="pinterest"
                                    toggle
                                />
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>
                                <Input 
                                    name="linkedin" 
                                    value={newFooterLinks.linkedin} 
                                    type="text" 
                                    onChange={handleEditLinks}
                                    placeholder="enter updated link" 
                                    fluid
                                    label="LinkedIn" 
                                />
                            </Table.Cell>
                            <Table.Cell>{footerLinks.linkedin}</Table.Cell>
                            <Table.Cell>
                                <Checkbox
                                    onChange={handleShowChange}
                                    checked={newFooterShow.linkedin}
                                    name="linkedin"
                                    toggle
                                />
                            </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>
                                <Input 
                                    name="tiktok" 
                                    value={newFooterLinks.tiktok} 
                                    type="text" 
                                    onChange={handleEditLinks}
                                    placeholder="enter updated link" 
                                    fluid
                                    label='TikTok'
                                />
                            </Table.Cell>
                            <Table.Cell>{footerLinks.tiktok}</Table.Cell>
                            <Table.Cell>
                                <Checkbox
                                    onChange={handleShowChange}
                                    checked={newFooterShow.tiktok}
                                    name="tiktok"
                                    toggle
                                />
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>

                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='3'>
                                <Button 
                                    fluid 
                                    loading={loading} 
                                    onClick={handleSubmitSelections}
                                    disabled={inputEmpty}
                                    color="vk"
                                >Save</Button>
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Footer>
                </Table>


                <Table celled style={{marginTop: '4rem'}}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={7}>Current Whatsapp Number</Table.HeaderCell>
                            <Table.HeaderCell width={10}>Edit</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        <Table.Row>
                                <Table.Cell>{whatsappNum}</Table.Cell>
                                <Table.Cell>
                                    <Input
                                        onChange={(e) => setNewWhatsappNum(e.target.value)}
                                        value={newWhatsappNum}
                                        name="whatsapp"
                                        type="text" 
                                    />
                                    <Button 
                                        color="vk" 
                                        onClick={handleNumberUpdate}
                                        disabled={!newWhatsappNum}
                                    >Update</Button>
                                </Table.Cell>
                            </Table.Row>
                    </Table.Body>
                </Table>
            </>
        ) : (null)
    )
}
const mapStateToProps = createStructuredSelector({
    staticSiteText: selectStaticSiteText
})

function mapDispatchToProps(dispatch) {
    return {
        setStaticSiteText: (siteText) => dispatch(setStaticSiteText(siteText))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(FooterLinksTable);
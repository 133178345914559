import React, { useState, useRef } from 'react'
import { Button, Form, Input } from 'semantic-ui-react'
import { useLocation, withRouter } from 'react-router-dom'
import { Modal } from 'react-responsive-modal';
import RichTextEditor from '../admin_elements/RichTextEditor'
import { IMG_CARD_TOOLBAR, BTM_CARD_TOOLBAR, SANITIZE_OPTS, BACKEND, CUSTOM_CARD_TITLE } from '../../../../service/clientConstants'
import { createStructuredSelector } from 'reselect';
import { selectAdminLoggedIn, selectUpdatedSiteText, selectMetalsState, selectStaticSiteText } from '../../../../redux/app-state/appSelectors';
import { connect } from 'react-redux';
import parse from 'html-react-parser'
import { formatHtml } from '../../../../service/helpers';
import sanitizeHtml from 'sanitize-html'
import styles from './CustomCard.module.scss';
import axios from 'axios';
import { setStaticSiteText, updateSiteText } from '../../../../redux/app-state/appActions';
import { FAIL_CONFIG, ADD_CARD_RETRY } from '../../../../service/notifications';
import { store } from 'react-notifications-component';

function CustomCard({ name, cardImgHtml, cardTitleHtml, metals,
    cardBottomHtml, history, adminLoggedIn, setStaticSiteText, 
    updateSiteText, updatedSiteText, staticSiteText,
    creator=false }) 
{
    let cleanCardImgHtml = '', cleanCardBottomHtml = '', cleanTitleHtml = '';
    if (cardImgHtml)    cleanCardImgHtml    = sanitizeHtml(cardImgHtml, SANITIZE_OPTS);
    if (cardTitleHtml)  cleanTitleHtml      = sanitizeHtml(cardTitleHtml, SANITIZE_OPTS)
    if (cardBottomHtml) cleanCardBottomHtml = sanitizeHtml(cardBottomHtml, SANITIZE_OPTS)
    const [imgHtml, setImgHtml]             = useState(cardImgHtml ? cleanCardImgHtml : '');
    const [bottomHtml, setBottomHtml]       = useState(cardBottomHtml ? cleanCardBottomHtml : '');
    const [titleHtml, setTitleHtml]         = useState(cardTitleHtml ? cleanTitleHtml : '');
    const [newCardTitle, setNewCardTitle]   = useState('');
    const [modalOpen, setModalOpen]         = useState(false);
    const [delLoad, setDelLoad]             = useState(false);
    const [addLoad, setAddLoad]             = useState(false);
    const containerRef                      = useRef()
    const location                          = useLocation();

    function updateCardState(card, destination) {
        if (destination === 'customCardImg') {
            setImgHtml(sanitizeHtml(card.cardImgHtml, SANITIZE_OPTS));
        } else if (destination === 'customCardBtm') {
            setBottomHtml(sanitizeHtml(card.cardBottomHtml, SANITIZE_OPTS));
        } else {
            setTitleHtml(sanitizeHtml(card.cardTitleHtml, SANITIZE_OPTS));
        }
    }


    function alreadyMade(metals, siteText, newTitle) {
        let { customCards } = siteText;
        if (metals.some(m => m.name === newTitle) || customCards.some(m => m.name === newTitle)) {
            store.addNotification(ADD_CARD_RETRY);
            return true;
        } else {
            return false
        }
    }

    function handleAddCard() {

        let confirmed   = window.confirm('Adding a metal will cause the page to reload, and you will lose any existing changes. Continue?')
        let purpose     = 'addNewCard'
        if (confirmed && !alreadyMade(metals, staticSiteText, newCardTitle)) {
            setAddLoad(true);
            axios
            .patch((BACKEND + '/admin/text'), { purpose, newCardTitle })
            .then(res => {
                if (res.data.success) {
                    setStaticSiteText(res.data.siteText)
                } else {
                    FAIL_CONFIG.message = res.data.msg;
                    store.addNotification(FAIL_CONFIG)
                }
            })
            .catch(e => console.log(e))
        }
    }

    function handleDeleteCard() {
        let confirmed = window.confirm('Adding a metal will cause the page to reload, and you will lose any existing changes. Continue?')
        let purpose   = 'deleteCard'
        if (confirmed) {
            setDelLoad(true);
            axios
            .patch((BACKEND + '/admin/text'), { purpose, name })
            .then(res => {
                if (res.data.success) {
                    setStaticSiteText(res.data.siteText);
                } else {
                    FAIL_CONFIG.message = res.data.msg;
                    store.addNotification(FAIL_CONFIG)
                }
            })
            .catch(e => console.log(e))
        }
    }


    if (creator) {
        return (
            <div ref={containerRef} className={styles.adminWrap}>
                {
                    !modalOpen ? (
                        <div style={{margin: '4rem auto'}}>
                            <Button
                                circular
                                icon={'add'}
                                onClick={() => setModalOpen(!modalOpen)}
                                color="yellow"
                                content='Create Card'
                            />
                        </div>
                    ) : (null)
                }
                <Modal 
                    container={containerRef.current} 
                    open={modalOpen} 
                    onClose={() => setModalOpen(false)}
                    blockScroll={false}
                    classNames={{ 
                        overlay: styles.customOverlay, 
                        closeButton: styles.customCloseBtn,
                        modal: styles.customModal
                    }}
                >
                    <Form className={styles.form}>
                        <Form.Field>
                            <label>New Card Title</label>
                            <Input
                                value={newCardTitle}
                                placeholder='E.g., Steel'
                                onChange={(e) => setNewCardTitle(e.target.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Button fluid onClick={handleAddCard} disabled={!newCardTitle} loading={addLoad}>
                                Add Card
                            </Button>
                        </Form.Field>
                    </Form>
                </Modal>
            </div>
        )
    } else {
        return (
            <div className={adminLoggedIn ? styles.adminWrap : styles.wrapper}>
                {
                    imgHtml ? parse(imgHtml, {
                        replace: formatHtml
                    }) : null
                }
                {
                    adminLoggedIn ? (
                        <div>
                            <RichTextEditor
                                currHtml={imgHtml ? imgHtml : null}
                                destination='customCardImg'
                                height='250'
                                toolbar={IMG_CARD_TOOLBAR}
                                setParentHtml={updateCardState}
                                customCard={true}
                                metalName={name}
                            />
                        </div>
                    ) : (null)
                }
                {
                    titleHtml ? parse(titleHtml, {
                        replace: formatHtml
                    }) : null
                }
                {
                    adminLoggedIn ? (
                        <div>
                            <RichTextEditor
                                currHtml={titleHtml ? titleHtml : null}
                                destination='customCardTitle'
                                height='150'
                                toolbar={CUSTOM_CARD_TITLE}
                                setParentHtml={updateCardState}
                                customCard={true}
                                metalName={name}
                            />
                        </div>
                    ) : (null)
                }
                {
                    bottomHtml ? parse(bottomHtml, {
                        replace: formatHtml
                    }) : null
                }
                {
                    adminLoggedIn ? (
                        <div>
                            <RichTextEditor
                                currHtml={bottomHtml ? bottomHtml : null}
                                destination='customCardBtm'
                                height='300'
                                toolbar={BTM_CARD_TOOLBAR}
                                setParentHtml={updateCardState}
                                customCard={true}
                                metalName={name}
                            />
                        </div>
                    ) : (null)
                }
                {
                    adminLoggedIn ? (
                        <Button
                            color="red"
                            onClick={handleDeleteCard}
                            loading={delLoad}
                        >Delete Card</Button>
                    ) : (null)
                }
                <Button 
                    className={adminLoggedIn ? styles.adminBtn : styles.button}
                    size="large"
                    onClick={() => history.push(`${location.pathname}/${name ? name : ''}`)}
                >Learn More</Button>
            </div>
        )
    
    }
}

const mapStateToProps = createStructuredSelector(
    {
        adminLoggedIn: selectAdminLoggedIn,
        updatedSiteText: selectUpdatedSiteText,
        metals: selectMetalsState, 
        staticSiteText: selectStaticSiteText
    }
)

function mapDispatchToProps(dispatch) {
    return {
        setStaticSiteText: (siteText) => dispatch(setStaticSiteText(siteText)),
        updateSiteText: () => dispatch(updateSiteText())
    }
}
 
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomCard));
import React from 'react'
import BasicMotionDiv from '../../reusable/BasicMotionDiv'
import RichTextEditor from './admin_elements/RichTextEditor';
import FilesDisplay from '../../reusable/FilesDisplay';
import { BASIC_TRANSITION, GUIDELINES_TOOLBAR } from '../../../service/clientConstants';
import { formatHtml } from '../../../service/helpers';
import parse from 'html-react-parser';
import useBodyClassChange from '../../../service/helpers'
import s from './Guidelines.module.scss';
import { createStructuredSelector } from 'reselect';
import { selectAdminLoggedIn, selectStaticSiteText } from '../../../redux/app-state/appSelectors';
import { connect } from 'react-redux';
import MetalsLinks from '../../reusable/MetalsLinks'
import { SANITIZE_OPTS } from '../../../service/clientConstants'
import sanitizeHtml from 'sanitize-html'
import { motion } from 'framer-motion';
function Guidelines({ adminLoggedIn, staticSiteText }) {

    useBodyClassChange(adminLoggedIn);
    // make sure to add admin logged in setup
    let guidelinesHtml, cleanedHtml;
    if (staticSiteText.guidelinesHtml) {
        guidelinesHtml = staticSiteText.guidelinesHtml;
        cleanedHtml    = sanitizeHtml(guidelinesHtml, SANITIZE_OPTS);
    }

    return (
        <>
            {
                adminLoggedIn ? <FilesDisplay/> : null
            }
            {
                adminLoggedIn ? (
                    <motion.div key="guidelinesEditor" exit="exit" animate="visible" initial="hidden" variants={BASIC_TRANSITION} className={s.wrapper}>
                        <div className={s.innerWrapper}>
                            <RichTextEditor 
                                currHtml={guidelinesHtml} 
                                destination="guidelines"
                                toolbar={GUIDELINES_TOOLBAR}
                            />
                        </div>
                    </motion.div>
                ) : (null)
            }
            <BasicMotionDiv className={s.wrapper}>
                <div className={s.innerWrapper}>
                {
                    cleanedHtml ? parse(cleanedHtml, {
                        replace: formatHtml
                    }) : (null)
                }
                </div>
            </BasicMotionDiv>
            <MetalsLinks/>
        </>
    )
}

const mapStateToProps = createStructuredSelector(
    {
        adminLoggedIn: selectAdminLoggedIn,
        staticSiteText: selectStaticSiteText
    }
);

export default connect(mapStateToProps)(Guidelines);
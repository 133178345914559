import { APP_ACTIONS } from './actionTypes';
import fbIcon from '../../assets/footer/iconfinder_Rounded_Facebook_svg_5282541.png';
import igIcon from '../../assets/footer/iconfinder_Rounded_Instagram_svg_5282544.png';
import liIcon from '../../assets/footer/iconfinder_Rounded_Linkedin2_svg_5282542.png'
import pinIcon from '../../assets/footer/iconfinder_Rounded_Pinterest2_svg_5282545.png'
import twIcon from '../../assets/footer/iconfinder_Rounded_Twitter5_svg_5282551.png'
import ytIcon from '../../assets/footer/iconfinder_Rounded_Youtube3_svg_5282548.png'
import ttIcon from '../../assets/footer/iconfinder_Social-media_Tiktok_4362958.png'
// all info for display purposes of the results modal are set here
const INITIAL_STATE = {
    adminLoggedIn: false,
    userLoggedIn: false,
    isOnline: true,
    metals: [],
    updatedMetals: [], //  used for updating metal cards and pages
    staticSiteText: {},
    updatedSiteText: {},
    stateLoaded: false,
    smIcons: {
        facebook: fbIcon, 
        instagram: igIcon, 
        twitter: twIcon, 
        youtube: ytIcon, 
        pinterest: pinIcon, 
        linkedin: liIcon, 
        tiktok: ttIcon
    },
    fromButtonClick: false,
    showDollarsOnly: false
} 

function appReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case APP_ACTIONS.SET_FROM_BTN_CLICK:
            return {
                ...state,
                fromButtonClick: action.payload
            }
        case APP_ACTIONS.UPDATE_SITE_TEXT:
            return {
                ...state,
                staticSiteText: Object.keys(state.updatedSiteText).length > 0 ? (
                    { ...state.updatedSiteText}
                ) : (
                    { ...state.staticSiteText }
                ),
                updatedSiteText: {}
            }
        case APP_ACTIONS.SET_UPDATED_SITE_TEXT:
            return {
                ...state,
                updatedSiteText: action.payload
            }
        case APP_ACTIONS.ADD_UPDATED_METAL:
            // test if payload is already in the updatedMetals array
            let present = state.updatedMetals.some(m => m.name === action.payload.name);
            return {
                ...state,
                updatedMetals: [
                    ...(present 
                            ? state.updatedMetals.map(m => m.name === action.payload.name ? action.payload : m)
                            : [...state.updatedMetals, action.payload]
                    )
                ]
            }
        case APP_ACTIONS.UPDATE_METALS_STATE:
            return {
                ...state,
                metals: state.metals.map(m => state.updatedMetals.find(obj => obj.name === m.name) || m),
                updatedMetals: []
            }
        case APP_ACTIONS.SET_ADMIN_LOGIN:
            return {
                ...state,
                adminLoggedIn: action.payload
            }
        case APP_ACTIONS.SET_IS_ONLINE:
            return {
                ...state,
                isOnline: action.payload
            }
        case APP_ACTIONS.SET_METALS_STATE:
            return {
                ...state,
                metals: action.payload
            }
        case APP_ACTIONS.SET_STATIC_SITE_TEXT:
            return {
                ...state,
                staticSiteText: action.payload,
                updatedSiteText: {}
            }
        case APP_ACTIONS.SET_STATE_LOADED: 
            return {
                ...state,
                stateLoaded: action.payload
            }
        case APP_ACTIONS.SET_DROPZONE_TEXT:
            return {
                ...state,
                staticSiteText: {
                    ...state.staticSiteText,
                    dropzoneText: action.payload
                }
            }
        case APP_ACTIONS.SET_HOMEPAGE_INFO:
            return {
                ...state,
                staticSiteText: {
                    ...state.staticSiteText,
                    homePageInfo: action.payload
                }
            }
        case APP_ACTIONS.SET_SHOW_DOLLARS_ONLY:
            return {
                ...state,
                showDollarsOnly: action.payload
            }
        default:
            return {
                ...state
            }
    }
}
export default appReducer;

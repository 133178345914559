import { createSelector } from 'reselect';
const adminState = rootState => rootState.adminState;
// const INITIAL_STATE = {
//     editLocation: '',
//     activeButtonName: '',
//     pricingVars: {}
// }

// _____________________pricingVars_____________________//
export const selectPricingVars = createSelector(
    [adminState],
    adminState => adminState.pricingVars
);



// _____________________isOnline_____________________//
export const selectActiveBtnName = createSelector(
    [adminState],
    adminState => adminState.activeButtonName
);


// _____________________isOnline_____________________//
export const selectEditLocation = createSelector(
    [adminState],
    adminState => adminState.editLocation
);




// _____________________showDollarsOnly_____________________//
export const selectAdminStateShowDollarsOnly = createSelector(
    [adminState],
    adminState => adminState.pricingVars.showDollarsOnly
);
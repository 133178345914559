import React from 'react'
import { motion } from 'framer-motion';
import { WORKFLOW_TRANSITION } from '../../../../../service/clientConstants';
import QuantitySetter from '../QuantitySetter'
import Prices from '../Prices'
import s from './QuantityChoices.module.scss';
function QuantityChoices() {

    return (
        <motion.div variants={WORKFLOW_TRANSITION} initial="hidden" exit="exit" animate="visible" className={s.quantityChoicesWrapper}>
            <div className={s.choices}>
                <QuantitySetter/>
                <Prices/>
            </div>
        </motion.div>
    )
}
export default QuantityChoices;
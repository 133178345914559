import React from 'react'
import s from './CustomQuotePrices.module.scss'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectMetal } from '../../../../../redux/quote-workflow/quoteWorkflowSelectors';
import { returnSaveRate } from '../../../../../service/saveRate'

function CustomQuotePrices({ metal }) {
    let choices = [];
    if (metal.quantityChoices) {
        choices = Object.keys(metal.quantityChoices);
    } 
    return (
        <ul className={s.ulWrapper}>
            <div className={s.title}>Total Cost</div>
            <div className={s.first}>Custom Quote</div>
            {
                choices.map((choice, idx) => (
                    <div className={s.other} key={idx}>
                        Custom Quote
                        <span className={s.save}>{returnSaveRate(parseFloat(choice), metal.quantityChoices)}</span>
                    </div>
                ))
            }
        </ul>
    )
}

const mapStateToProps = createStructuredSelector(
    {
        metal: selectMetal,
    }
);
 
export default connect(mapStateToProps)(CustomQuotePrices);
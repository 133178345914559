import React from 'react'
import styles from './Elements.module.scss';
import cutPathsImg from '../../../../assets/cut-paths-ex.png';
 
function CutPaths(props) {
    return (
        <div className={styles.wrapper}>
            <h2>File should only contain cut paths</h2>
            <p>
                To save you time and money, kindly remove any instructions, dimensions, notes, borders, and 
                unused objects from your file. <em>Only send us the actual cut-path. </em> 
                 You can add notes to your order form, and we will contact you if we have questions.
            </p>
            <img src={cutPathsImg} alt="correct cut paths example"/>
        </div>
    )
}
 
export default CutPaths;
import React from "react";
import { motion } from "framer-motion";
import { BASIC_TRANSITION } from "../../service/clientConstants";
import uuid from "react-uuid";
function BasicMotionDiv({ children, className, style, id=null, innerHtml = null }) {
    return innerHtml ? (
        <motion.div
            id={id ? id : null}
            dangerouslySetInnerHTML={innerHtml}
            className={className}
            style={style}
            key={uuid()}
            variants={BASIC_TRANSITION}
            initial="hidden"
            animate="visible"
            exit="exit"
        ></motion.div>
    ) : (
        <motion.div
            id={id ? id : null}
            className={className}
            style={style}
            key={uuid()}
            variants={BASIC_TRANSITION}
            initial="hidden"
            animate="visible"
            exit="exit"
        >
            {children}
        </motion.div>
    );
}

export default BasicMotionDiv;

import { RESULTS_MODAL_ACTION_TYPES } from './actionTypes';


export function setResultsModalWorkflowStarted(bool) {
    return {
        type: RESULTS_MODAL_ACTION_TYPES.SET_RESULTS_MODAL_WORKFLOW_STARTED,
        payload: bool
    }
}


export function setMetalChoicesVisible(bool) {
    return {
        type: RESULTS_MODAL_ACTION_TYPES.SET_METAL_CHOICES_VISIBLE,
        payload: bool
    }
}


export function setThicknessChoicesVisible(bool) {
    return {
        type: RESULTS_MODAL_ACTION_TYPES.SET_THICKNESS_CHOICES_VISIBLE,
        payload: bool
    }
}


export function setQuantityChoicesVisible(bool) {
    return {
        type: RESULTS_MODAL_ACTION_TYPES.SET_QUANTITY_CHOICES_VISIBLE,
        payload: bool
    }
}


export function resetResultsModal() {
    return {
        type: RESULTS_MODAL_ACTION_TYPES.RESET_RESULTS_MODAL,
    }
}

import React from 'react'
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import uuid from 'react-uuid';
import { motion } from 'framer-motion';
import { WORKFLOW_TRANSITION } from '../../../../../service/clientConstants';
import { selectInputErr, selectIsCustomQuote, selectMetal, selectThickness } from '../../../../../redux/quote-workflow/quoteWorkflowSelectors';
import { sortThicknessTable } from '../../../../../service/helpers';
import ThicknessSelector from '../ThicknessSelector'
import s from './ThicknessChoices.module.scss';

function ThicknessChoices({ isCustomQuote, inputErr, metal, thickness }) {

    return (
        <motion.div variants={WORKFLOW_TRANSITION} initial="hidden" exit="exit" animate="visible" className={s.thicknessChoices}>
        {
            isCustomQuote || inputErr ? (
                sortThicknessTable(metal.table)
                .map(entry => <ThicknessSelector imgUrl={metal.thumbnailUrl} dim={entry[0]} key={uuid()} thickness={thickness}/>)
            ) : (
                sortThicknessTable(metal.table)
                .filter(entry => parseFloat(entry[0]) <= 6)
                .map(entry => <ThicknessSelector imgUrl={metal.thumbnailUrl} dim={entry[0]} key={uuid()} thickness={thickness}/>)
            )
        }
        </motion.div>
    )
}

const mapStateToProps = createStructuredSelector(
    {
        isCustomQuote: selectIsCustomQuote,
        inputErr: selectInputErr,
        metal: selectMetal,
        thickness: selectThickness
    }
)
export default connect(mapStateToProps)(ThicknessChoices);